import React, { useState, useEffect, useMemo } from 'react'
import {
  Button,
  Card,
  Collection,
  Flex,
  Grid,
  TextField,
  useTheme,
} from '@aws-amplify/ui-react'
import { useWidth } from '../../../hooks/useWidth'
import './VideoMapScreen.css'
import { useUserData } from '../../../hooks/useUserData'
import { Video } from '../../../models'
import { VideoMap } from '../../shared/UserVideoMap/WalletMap/VideoMap'

function VideoMapScreen() {
  const [videos, setVideos] = useState([])
  const { user, fetchUserData } = useUserData()
  const { tokens } = useTheme()
  const screenWidth = useWidth()
  const isSmallScreen = screenWidth < 1024

  useEffect(() => {
    fetchUserData()
  }, [])
  useEffect(() => {
    const getData = async () => {
      console.log('VideoMap:: userData', user.data)
      let videoConverted = await user.data?.videosConverted.toArray()
      if (videoConverted) {
        const videos = await Promise.all(videoConverted.map((vc) => vc.video))
        //filter undefined
        let filtered = videos.filter((v) => v)
        setVideos(filtered)
      }
    }

    getData()
  }, [user.data])

  return (
    <Grid
      autoFlow="row"
      templateColumns={{ base: '1fr', large: '1fr 1fr 1fr' }}
      templateRows="1fr 1fr"
      columnGap="0.5rem"
      rowGap="0.5rem"
      height={'90%'}
      padding={tokens.space.medium}
      gap={tokens.space.small}
    >
      <VideoMap videos={videos} />
    </Grid>
  )
}

export default VideoMapScreen
