import { useState } from 'react'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import Synopsis from './components/screens/Synopsis/Synopsis'
import '@aws-amplify/ui-react/styles.css'
import './App.css'
// import { PayPalScriptProvider } from '@paypal/react-paypal-js'
// import { PAYPAL_API_CREDENTIALS } from './config'
// import { SubscriptionModal } from './components/shared/SubscriptionModal/SubscriptionModal'
// import { usePayPalConfig } from './hooks/usePayPalCredentials'
import { ShareableVideoViewer } from './components/screens/ShareableVideoViewer/ShareableVideoViewer'
import Navbar from './components/shared/Navbar/Navbar'
import { Authenticator } from '@aws-amplify/ui-react'
import YoutubeSearch from './components/screens/YoutubeSearch/YoutubeSearch'
import SubscriptionModalStripe from './components/shared/SubscriptionModal/SubscriptionModalStripe/SubscriptionModal'
import VideoMapScreen from './components/screens/VideoMap/VideoMapScreen'
import { useRecoilState } from 'recoil'
import { showSubscriptionModalAtom } from './recoil/atoms'
import ProtectedRoute from './components/shared/ProtectedRoute/ProtectedRoute'

function App() {
  // let config = usePayPalConfig()
  const [showSubscriptionModal, setShowSubscriptionModal] = useRecoilState(
    showSubscriptionModalAtom,
  )

  return (
    // <PayPalScriptProvider
    //   options={{
    //     'client-id': config.clientId,
    //     currency: 'USD',
    //     intent: 'subscription',
    //     vault: true,
    //   }}
    // >
      <Authenticator.Provider>
        <BrowserRouter>
            <Routes>
              <Route
                path="/synopsis"
                element={
                  <ProtectedRoute>
                    <Synopsis />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/video/:id"
                element={
                  <div className="content">
                    <Navbar />
                    <ShareableVideoViewer />
                  </div>
                }
              />
              <Route
                path="search"
                element={
                  <ProtectedRoute>
                    <YoutubeSearch />
                  </ProtectedRoute>
                }
              />
              <Route
                path="videoMap"
                element={
                  <ProtectedRoute>
                    <VideoMapScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path="*"
                element={
                  <ProtectedRoute>
                    <Synopsis />
                  </ProtectedRoute>
                }
              />
            </Routes>
        </BrowserRouter>
        <SubscriptionModalStripe
          show={showSubscriptionModal}
          close={() => setShowSubscriptionModal(false)}
        />
      </Authenticator.Provider>
    // </PayPalScriptProvider>
  )
}

// const AuthenticatedAppWithAuth = withAuthenticator(AuthenticatedApp)

// function UnAuthenticatedApp() {
//   return (
//     <>
//       <Navbar signOut={null} user={{}} />
//       <div className="content">
//         <Routes>
//         </Routes>
//       </div>
//     </>
//   )
// }

// function App() {
//   const currentPath = window.location.pathname
//   console.log('currentPath', currentPath)
//   return (
//     <BrowserRouter>
//       {currentPath.includes('/video/') ? (
//         <UnAuthenticatedApp />
//       ) : (
//         <AuthenticatedAppWithAuth />
//       )}
//     </BrowserRouter>
//   )
//   return
// }

export default App
