import React, { useEffect, useMemo, useState } from 'react'
import {
  TextAreaField,
  Text,
  Flex,
  TextField,
  Button,
  Tabs,
  TabItem,
  Alert,
  ScrollView,
} from '@aws-amplify/ui-react'
import ReactPlayer from 'react-player'

import { SpeechSynthesis } from '../SpeechSynthesis/SpeechSynthesis'
import { SUMMARY_BOT_API } from '../../../../../config'
import { useWidth } from '../../../../../hooks/useWidth'
import './VideoSummaryContent.css'
import { useKeyPointGroups } from '../../../../../hooks/useKeyPointGroups'

/**
 * Video Display with video, summary, key points and speech synthesis
 **/
const CheckboxListItem = ({ item, handleCheckboxChange, onTextClicked }) => (
  <div className="checkboxDiv">
    <input
      className="checkbox"
      type="checkbox"
      checked={item.isCompleted}
      onChange={() => handleCheckboxChange(item.id)}
    />
    <span
      key={item.id}
      className="clickableText text"
      onClick={() => onTextClicked(item.startTime)}
    >
      {item.text}
    </span>
  </div>
)

export const VideoSummaryContent = ({
  keyPoints,
  summaryText,
  youtubeLinkId,
  youtubeLink,
  user,
  keyWords,
}) => {
  const mainVideoPlayer = React.createRef()
  const readingCardRef = React.createRef()
  const [shareLinkText, setShareLinkText] = useState('Shareable Link')
  const [summaryPoints, setSummaryPoints] = useState([])
  const [videoPlaying, setVideoPlaying] = useState(false)
  const [tweetText, setTweetText] = useState('')
  const [oauthPin, setOauthPin] = useState('')
  const [twitterUserData, setTwitterUserData] = useState(null)
  const [readingCardHeight, setReadingCardHeight] = useState(200)
  const { keyPointGroups, createNewKeyPointGroup } = useKeyPointGroups()
  const width = useWidth()

  const videoPlayerHeight = useMemo(() => {
    return width > 1024 ? 400 : 200
  }, [width])

  const seekTimeTimeInVideo = (time) => {
    let t = Math.max(time - 2, 0)
    mainVideoPlayer.current.seekTo(t, 'seconds')
    mainVideoPlayer.current.muted = true
    setVideoPlaying(true)
  }

  const sendTwitterPin = async () => {
    console.log(user)
    const apiURL =
      SUMMARY_BOT_API +
      `/twitterOAuthPin?userId=${user.username}&pin=${oauthPin}`
    const response = await fetch(apiURL)
    const data = await response.json()
    console.log('sendTwitterPin res', data)
    setTwitterUserData(data)
  }

  const getTwitterAuthLink = async () => {
    console.log(user)
    const apiURL = SUMMARY_BOT_API + `/twitterAuthLink?userId=${user.username}`
    const response = await fetch(apiURL)
    const data = await response.json()
    console.log('getTwitterAuthLink res', data)

    const authLink = data.link

    if (authLink) {
      window.open(authLink, '_blank').focus()
      // Get the access token from the URL
      const url = new URL(window.location.href)
      const oauthToken = url.searchParams.get('oauth_token')
      const oauthVerifier = url.searchParams.get('oauth_verifier')
      console.log(oauthToken, oauthVerifier)
    }
  }
  const handleCheckboxChange = (id) => {
    setSummaryPoints(
      summaryPoints.map((item) => {
        if (item.id === id) {
          console.log('handleCheckboxChange', id)
          console.log('handleCheckboxChange', item)

          return { ...item, isSelected: !item.isSelected }
        }
        return item
      }),
    )
  }
  function keyPointsToCheckBoxPoints(keyPointsArray) {
    //replace all \n with empty string
    if (!keyPointsArray) return []
    // let dotPoints = synopsisObj.map((point) => <li>{point.key_point}</li>)

    // synopsysText = synopsysText.replace(/\n/g, '')
    // let dotPoints = synopsysText.split('-')
    // dotPoints = dotPoints.filter((point) => point.length > 0)

    let keyPointsData = keyPointsArray.map((point, ind) => {
        return {
          id: youtubeLinkId + ind,
          text: point.keyPoint,
          startTime: point.videoTimeSeconds,
          isSelected: false,
        }
      }),
      //sort keyPointsData by startTime
      keyPointsDataSorted = keyPointsData.sort((a, b) => {
        return a.startTime - b.startTime
      })

    //filter out duplicate key points based on text
    keyPointsDataSorted = keyPointsDataSorted.filter(
      (point, index, self) =>
        index ===
        self.findIndex(
          (t) =>
            t.text.toLowerCase() === point.text.toLowerCase() ||
            t.startTime === point.startTime,
        ),
    )
    console.log(
      'keyPointsArray',
      keyPointsArray.length,
      keyPointsDataSorted.length,
    )
    setSummaryPoints(keyPointsDataSorted)
  }

  //a function that takes a string with multiple sentences and add a line break after each 3rd sentence
  function paragraphedText(text) {
    if (!text) return ''
    let sentences = text.split('.')
    let paragraphs = []
    let paragraph = []
    for (let i = 0; i < sentences.length; i++) {
      if (i % 3 == 0 && i != 0) {
        let joinedText = paragraph.join('.')
        let words = joinedText.split(' ')
        let wordObjects = words.map((word, ind) => (
          <span className="clickableWord">{word} </span>
        ))
        paragraphs.push(<Text marginBottom={10}>{wordObjects}.</Text>)
        paragraph = []
      }
      paragraph.push(sentences[i])
    }
    return paragraphs
  }

  useEffect(() => {
    if (keyPoints?.length != 0) keyPointsToCheckBoxPoints(keyPoints)
  }, [keyPoints])

  useEffect(() => {
    if (readingCardRef?.current && mainVideoPlayer?.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        let videoHeight = videoPlayerHeight
        // console.log('resizeObserver videoHeight', videoHeight, readingCardRef)
        let readingCardHeight = readingCardRef.current?.clientHeight || 200
        let newHeight = readingCardHeight - videoPlayerHeight - 75
        setReadingCardHeight(newHeight)
      })
      resizeObserver.observe(readingCardRef.current)
    }
  }, [readingCardRef, mainVideoPlayer, width])

  const selectedPoints = useMemo(() => {
    if (summaryPoints.length > 0) {
      let filtered = summaryPoints.filter((point) => point.isSelected)
      console.log('selectedPoints', filtered)
      return filtered
    }
    return []
  }, [summaryPoints])

  return (
    <Flex
      ref={readingCardRef}
      direction="Column"
      alignItems="left"
      width={'100%'}
      height={'100%'}
    >
      <ReactPlayer
        playing={videoPlaying}
        ref={mainVideoPlayer}
        url={youtubeLink}
        width="100%"
        height={`${videoPlayerHeight}px`}
        controls={true}
        config={{
          youtube: {
            playerVars: { showinfo: 1 },
          },
        }}
      />
      <Flex>
        <Tabs>
          {summaryText.length > 0 && (
            <TabItem title="Summary">
              <ScrollView
                className="scrollView text"
                width="100%"
                height={`${readingCardHeight}px`}
              >
                <Flex
                  justifyContent={'space-between'}
                  alignContent={'flex-end'}
                >
                  <SpeechSynthesis text={summaryText} />
                  <Button
                    variation="info"
                    height={'40px'}
                    marginTop={'auto'}
                    marginBottom={'25px'}
                    marginRight={'25px'}
                    onClick={() => {
                      //copy to clipboard "www.socialsummaries.com/video/<videoId>"
                      navigator.clipboard.writeText(
                        `www.socialsummaries.com/video/${youtubeLinkId}`,
                      )
                      setShareLinkText('Copied!')
                      setTimeout(() => {
                        setShareLinkText('Shareable Link')
                      }, 2000)
                    }}
                  >
                    {shareLinkText}
                  </Button>
                </Flex>
                {paragraphedText(summaryText)}
                <Text color={'var(--danger-color)'}>-- End --</Text>
              </ScrollView>
            </TabItem>
          )}
          <TabItem title="Key Points" className="synopsisTab">
            <ScrollView
              className="scrollView text"
              width="100%"
              height={`${readingCardHeight}px`}
            >
              <Text variation="info" style={{ marginBottom: '15px' }}>
                {keyWords && keyWords.length > 0 && (
                  <Text>
                    Key words: {keyWords.map((word) => `${word.id}, `)}
                  </Text>
                )}
              </Text>

              <Text variation="info" style={{ marginBottom: '15px' }}>
                Select points to generate content ({summaryPoints.length}{' '}
                points)...
              </Text>
              <ul className="summaryPointlist">
                {summaryPoints.map((point) => (
                  <CheckboxListItem
                    key={point.id}
                    item={point}
                    startTime={point.startTime}
                    handleCheckboxChange={handleCheckboxChange}
                    onTextClicked={seekTimeTimeInVideo}
                  />
                ))}
              </ul>
              <Text>-- End --</Text>
              {selectedPoints.length > 0 && (
                <Flex>
                  <Button
                    onClick={() => {
                      createNewKeyPointGroup(
                        selectedPoints.map((point) => {
                          return {
                            id: point.id,
                            text: point.text,
                            startTime: point.startTime,
                            user: user.username,
                          }
                        }),
                      )
                    }}
                  >
                    New Group
                  </Button>
                  <Button
                    onClick={() => {
                      createNewKeyPointGroup(keyPoints)
                    }}
                  >
                    Add to Group
                  </Button>
                </Flex>
              )}
            </ScrollView>
          </TabItem>
          <TabItem title="Tweets" display={'none'}>
            <ScrollView width="100%" height={`${readingCardHeight}px`}>
              <Alert
                variation="warning"
                isDismissible={false}
                hasIcon={true}
                heading="Authorise Twitter"
                width="100%"
              >
                <Text>
                  1. Click authorise and login to your twitter and copy the
                  6-digit code.
                </Text>
                <Button onClick={getTwitterAuthLink}>Authorise</Button>
                <Text>2. Paste the 6-digit code and click verify.</Text>
                <Flex>
                  <TextField
                    labelHidden={true}
                    descriptiveText="Enter your twitter OAuth pin"
                    placeholder="eg. 123456"
                    label="Twitter OAuth pin"
                    errorMessage="Invalid pin"
                    width="200px"
                    color="white"
                    onChange={(e) => {
                      setOauthPin(e.target.value)
                    }}
                    value={oauthPin}
                  />
                  <Button onClick={sendTwitterPin}>Verify</Button>
                </Flex>
              </Alert>

              <div className="tweet">
                <Flex>
                  <TextAreaField
                    label="Comments"
                    labelHidden={true}
                    defaultValue={tweetText.replace(/\\n/g, '')}
                    value={tweetText.replace(/\\n/g, '')}
                    width="450px"
                  />
                  <Button>Post</Button>
                </Flex>
              </div>
            </ScrollView>
          </TabItem>
        </Tabs>
      </Flex>
    </Flex>
  )
}
