import axios from 'axios'

const GRAPHQL_ENDPOINT = "https://uuz34rgdhzbf7otcqkgr2xakbm.appsync-api.ap-southeast-2.amazonaws.com/graphql"
const GRAPHQL_API_KEY = "da2-2flbkzmasneb3kwq4a2fkjy7rm"

class GraphQLDatabase {
  constructor() {
    this.query = this.query.bind(this)
  }

  //function to query the database using axios
  query = async (query, variables) => {
    const result = await axios({
      url: GRAPHQL_ENDPOINT,
      method: 'post',
      headers: {
        'x-api-key': GRAPHQL_API_KEY,
      },
      data: {
        query,
        variables,
      },
    }).catch((error) => {
      console.log(error)
    })
    return result.data.data
  }

  //function to create a user in the database based on schema.graphql
  createUser = async (userId) => {
    const query = `
        mutation CreateUser($userId: ID!) {
            createUser(input: {id: $userId}) {
                id
            }
        }
        `
    const variables = {
      userId,
    }

    const data = await this.query(query, variables)
    console.log(data)
    return data.createUser
  }

  //function to get a user from the database based on schema.graphql
  getUser = async (userId) => {
    const query = `
        query GetUser($userId: ID!) {
            getUser(id: $userId) {
                id
                createdAt
                updatedAt
                videosConverted {
                    items {
                        id
                        video{
                          id
                          title
                          description
                          thumbnailURL
                          status
                          createdAt
                        }
                    }
                }
                videosViewed {
                    items { 
                        id
                    }
                }
                videosLiked {
                    items {
                        id
                    }
                }
                keyPointsLiked {
                    items {
                        id
                    }
                }
                summariesLiked {
                    items {
                        id
                    }
                }
                channelSubscriptions {
                    items {
                        id
                        channel {
                            id
                            name
                            description
                            thumbnailURL
                        }
                    }
                }
                tweetsGenerated {
                    items {
                        id
                        twitterAccount
                        tweet
                        timestamp
                        tweetType
                    }
                }
                tweetsPosted {
                    items {
                        id
                        twitterAccount
                        tweet
                        timestamp
                        tweetType
                    }
                }
            }
        }
        `
    const variables = {
      userId,
    }

    const data = await this.query(query, variables)
    console.log(data)
    return data.getUser
  }

  //function to delete a user from the database based on schema.graphql
  deleteUser = async (userId) => {
    const query = `
        mutation DeleteUser($userId: ID!) {
            deleteUser(input: {id: $userId}) {
                id
            }
        }
        `
    const variables = {
      userId,
    }

    const data = await this.query(query, variables)
    console.log(data)
    return data.deleteUser
  }

  //function to create a VideoConverted in the database based on schema.graphql
  createVideoConverted = async (userId, videoId) => {
    const query = `
        mutation CreateVideoConverted($videoId: ID!, $userId: ID) {
            createVideoConverted(input: {videoConvertedVideoId: $videoId, userVideosConvertedId: $userId}) {
                id
                video {
                    id
                }
                user {
                    id
                }
            }
        }
        `
    const variables = {
      userId,
      videoId,
    }

    const data = await this.query(query, variables)
    console.log(data)
    return data.createVideoConverted
  }

  // function to delete a VideoConverted from the database based on schema.graphql
  deleteVideoConverted = async (videoConvertedId) => {
    const query = `
            mutation DeleteVideoConverted($videoConvertedId: ID!) {
                deleteVideoConverted(input: {id: $videoConvertedId}) {
                    id
                }
            }
            `
    const variables = {
      videoConvertedId,
    }

    const data = await this.query(query, variables)
    console.log(data)
    return data.deleteVideoConverted
  }

  //function to create a VideoViewed in the database based on schema.graphql
  createVideoViewed = async (userId, videoId) => {
    const query = `
            mutation CreateVideoViewed($userId: ID!, $videoId: ID!) {
                createVideoViewed(input: {videoViewedUserId: $userId, videoViewedVideoId: $videoId}) {
                    id
                }
            }
            `
    const variables = {
      userId,
      videoId,
    }

    const data = await this.query(query, variables)
    console.log(data)
    return data.createVideoViewed
  }

  // function to delete a VideoViewed from the database based on schema.graphql
  deleteVideoViewed = async (userId, videoId) => {
    const query = `
            mutation DeleteVideoViewed($userId: ID!, $videoId: ID!) {
                deleteVideoViewed(input: {videoViewedUserId: $userId, videoViewedVideoId: $videoId}) {
                    id
                }
            }
            `
    const variables = {
      userId,
      videoId,
    }

    const data = await this.query(query, variables)
    console.log(data)
    return data.deleteVideoViewed
  }

  //function to create a VideoLiked in the database based on schema.graphql
  createVideoLiked = async (userId, videoId) => {
    const query = `
            mutation CreateVideoLiked($userId: ID!, $videoId: ID!) {
                createVideoLiked(input: {videoLikedUserId: $userId, videoLikedVideoId: $videoId}) {
                    id
                }
            }
            `
    const variables = {
      userId,
      videoId,
    }

    const data = await this.query(query, variables)
    console.log(data)
    return data.createVideoLiked
  }

  // function to delete a VideoLiked from the database based on schema.graphql
  deleteVideoLiked = async (userId, videoId) => {
    const query = `
            mutation DeleteVideoLiked($userId: ID!, $videoId: ID!) {
                deleteVideoLiked(input: {videoLikedUserId: $userId, videoLikedVideoId: $videoId}) {
                    id
                }
            }
            `
    const variables = {
      userId,
      videoId,
    }

    const data = await this.query(query, variables)
    console.log(data)
    return data.deleteVideoLiked
  }

  //function to create a KeyPointLiked in the database based on schema.graphql
  createKeyPointLiked = async (userId, keyPointId) => {
    const query = `
            mutation CreateKeyPointLiked($userId: ID!, $keyPointId: ID!) {
                createKeyPointLiked(input: {keyPointLikedUserId: $userId, keyPointLikedKeyPointId: $keyPointId}) {
                    id
                }
            }
            `
    const variables = {
      userId,
      keyPointId,
    }

    const data = await this.query(query, variables)
    console.log(data)
    return data.createKeyPointLiked
  }

  // function to delete a KeyPointLiked from the database based on schema.graphql
  deleteKeyPointLiked = async (userId, keyPointId) => {
    const query = `
            mutation DeleteKeyPointLiked($userId: ID!, $keyPointId: ID!) {
                deleteKeyPointLiked(input: {keyPointLikedUserId: $userId, keyPointLikedKeyPointId: $keyPointId}) {
                    id
                }
            }
            `
    const variables = {
      userId,
      keyPointId,
    }

    const data = await this.query(query, variables)
    console.log(data)
    return data.deleteKeyPointLiked
  }

  //function to get user video ids from the database based on schema.graphql
  getUserVideos = async (userId) => {
    const query = `
      query GetUserVideos($userId: ID!) {
        getUser(id: $userId) {
          videosConverted {
            items {
              id
            }
          }
        }
      }
    `
    const variables = {
      userId,
    }

    const data = await this.query(query, variables)
    console.log(data)
    return data.getUser?.videosConverted?.items || []
  }

  //function to check if a video is already in the database based on schema.graphql
  checkVideoExists = async (videoId) => {
    const query = `
      query GetVideo($videoId: ID!) {
        getVideo(id: $videoId) {
          id
        }
      }
    `
    const variables = {
      videoId,
    }

    const data = await this.query(query, variables)
    console.log(data)
    return data.getVideo
  }

  //function to add a video to the database based on schema.graphql
  createVideo = async (videoId, title, description, thumbnailURL, status) => {
    const query = `
      mutation CreateVideo($videoId: ID!, $title: String!, $description: String!, $thumbnailURL: String!, $status: String!) {
        createVideo(input: {
          id: $videoId
          title: $title
          description: $description
          thumbnailURL: $thumbnailURL
          status: $status
        }) {
          id
        }
      }
    `
    const variables = {
      videoId,
      title,
      description,
      thumbnailURL,
      status,
    }

    const data = await this.query(query, variables)
    console.log(data)
    return data.createVideo
  }

  //function to get a video from the database based on schema.graphql
  getVideo = async (videoId) => {
    const query = `
        query GetVideo($videoId: ID!) {
            getVideo(id: $videoId) {
                id
                createdAt
                updatedAt
                title
                description
                thumbnailURL
                status
                transcriptionJobName
                summaries {
                    items {
                        id
                        summary
                    }
                }
                keyPoints {
                    items {
                        id
                        keyPoint
                        videoTimeSeconds
                    }
                }
            }
        }
    `
    const variables = {
      videoId,
    }

    const data = await this.query(query, variables)
    console.log('Get video', data)
    return data.getVideo
  }

  //function to delete a video from the database based on schema.graphql
  deleteVideo = async (videoId) => {
    const query = `
        mutation DeleteVideo($videoId: ID!) {
            deleteVideo(input: {
                id: $videoId
            }) {
                id
            }
        }
    `
    const variables = {
      videoId,
    }
    const data = await this.query(query, variables)
    console.log('Delete video', data)
    return data.deleteVideo
  }

  //function to update a video in the database based on schema.graphql
  updateVideo = async (
    videoId,
    title,
    description,
    thumbnailURL,
    status,
    transcriptionJobName,
  ) => {
    const query = `
        mutation UpdateVideo($videoId: ID!, $title: String!, $description: String!, $thumbnailURL: String!, $status: String!, $transcriptionJobName: String!) {
            updateVideo(input: {
                id: $videoId
                title: $title
                description: $description
                thumbnailURL: $thumbnailURL
                status: $status
                transcriptionJobName: $transcriptionJobName
            }) {
                id
            }
        }
    `
    const variables = {
      videoId,
      title,
      description,
      thumbnailURL,
      status,
      transcriptionJobName,
    }
    const data = await this.query(query, variables)
    console.log('Update video', data)
    return data.updateVideo
  }

  //update video status
  updateVideoStatus = async (videoId, status) => {
    const query = `
            mutation UpdateVideoStatus($videoId: ID!, $status: String!) {
                updateVideo(input: {
                    id: $videoId
                    status: $status
                }) {
                    id
                    status
                }
            }
        `
    const variables = {
      videoId,
      status,
    }
    const data = await this.query(query, variables)
    console.log('Update video status', data)
    return data.updateVideo
  }

  //function to update video transcription job name in the database based on schema.graphql
  updateVideoTranscriptionJobName = async (videoId, transcriptionJobName) => {
    const query = `
            mutation UpdateVideoTranscriptionJobName($videoId: ID!, $transcriptionJobName: String!) {
                updateVideo(input: {
                    id: $videoId
                    transcriptionJobName: $transcriptionJobName
                }) {
                    id
                    transcriptionJobName
                }
            }
        `
    const variables = {
      videoId,
      transcriptionJobName,
    }
    const data = await this.query(query, variables)
    console.log('Update video transcription job name', data)
    return data.updateVideo
  }

  //function to update user video ids in the database based on schema.graphql
  updateUserVideos = async (userId, videoId) => {
    const query = `
      mutation UpdateUserVideos($userId: ID!, $videoId: ID!) {
        updateUser(input: {
          id: $userId
          videosConverted: {
            items: [
              $videoId
            ]
          }
        }) {
          id
        }
        }
    `
    const variables = {
      userId,
      videoId,
    }

    const data = await this.query(query, variables)
    console.log(data)
    return data.updateUser
  }

  //function to create a summary in the database based on schema.graphql
  createSummary = async (videoId, summary) => {
    const query = `
            mutation CreateSummary($videoId: ID!, $summary: String!) {
                createSummary(input: {
                    videoSummariesId: $videoId
                    summary: $summary
                }) {
                    id
                    summary
                }
            }
        `
    const variables = {
      videoId,
      summary,
    }
    const data = await this.query(query, variables)
    console.log('Create summary', data)
    return data.createSummary
  }

  //function to get a summary from the database based on schema.graphql
  getSummary = async (summaryId) => {
    const query = `
            query GetSummary($summaryId: ID!) {
                getSummary(id: $summaryId) {
                    id
                    summary
                }
            }
        `
    const variables = {
      summaryId,
    }
    const data = await this.query(query, variables)
    console.log('Get summary', data)
    return data.getSummary
  }

  //function to delete a summary from the database based on schema.graphql
  deleteSummary = async (summaryId) => {
    const query = `
            mutation DeleteSummary($summaryId: ID!) {
                deleteSummary(input: {
                    id: $summaryId
                }) {
                    id
                }
            }
        `
    const variables = {
      summaryId,
    }
    const data = await this.query(query, variables)
    console.log('Delete summary', data)
    return data.deleteSummary
  }

  //function to update a summary in the database based on schema.graphql
  updateSummary = async (summaryId, summary) => {
    const query = `
            mutation UpdateSummary($summaryId: ID!, $summary: String!) {
                updateSummary(input: {
                    id: $summaryId
                    summary: $summary
                }) {
                    id
                    summary
                }
            }
        `
    const variables = {
      summaryId,
      summary,
    }
    const data = await this.query(query, variables)
    console.log('Update summary', data)
    return data.updateSummary
  }

  //function to create a key point in the database based on schema.graphql

  createKeyPoint = async (videoId, keyPoint, videoTimeSeconds) => {
    const query = `
            mutation CreateKeyPoint($videoId: ID!, $keyPoint: String!, $videoTimeSeconds: Int!) {
                createKeyPoint(input: {
                    videoKeyPointsId: $videoId
                    keyPoint: $keyPoint
                    videoTimeSeconds: $videoTimeSeconds
                }) {
                    id
                    keyPoint
                    videoTimeSeconds
                }
            }
        `
    const variables = {
      videoId,
      keyPoint,
      videoTimeSeconds,
    }
    const data = await this.query(query, variables)
    console.log('Create key point', data)
    return data.createKeyPoint
  }

  //function to get a key point from the database based on schema.graphql
  getKeyPoint = async (keyPointId) => {
    const query = `
            query GetKeyPoint($keyPointId: ID!) {
                getKeyPoint(id: $keyPointId) {
                    id
                    keyPoint
                    videoTimeSeconds
                }
            }
        `
    const variables = {
      keyPointId,
    }
    const data = await this.query(query, variables)
    console.log('Get key point', data)
    return data.getKeyPoint
  }

  //function to delete a key point from the database based on schema.graphql
  deleteKeyPoint = async (keyPointId) => {
    const query = `
            mutation DeleteKeyPoint($keyPointId: ID!) {
                deleteKeyPoint(input: {
                    id: $keyPointId
                }) {
                    id
                }
            }
        `
    const variables = {
      keyPointId,
    }
    const data = await this.query(query, variables)
    console.log('Delete key point', data)
    return data.deleteKeyPoint
  }

  //function to update a key point in the database based on schema.graphql
  updateKeyPoint = async (keyPointId, keyPoint, videoTimeSeconds) => {
    const query = `
            mutation UpdateKeyPoint($keyPointId: ID!, $keyPoint: String!, $videoTimeSeconds: Int!) {
                updateKeyPoint(input: {
                    id: $keyPointId
                    keyPoint: $keyPoint
                    videoTimeSeconds: $videoTimeSeconds
                }) {
                    id
                    keyPoint
                    videoTimeSeconds
                }
            }
        `
    const variables = {
      keyPointId,
      keyPoint,
      videoTimeSeconds,
    }
    const data = await this.query(query, variables)
    console.log('Update key point', data)
    return data.updateKeyPoint
  }
}

export const graphqlDatabase = new GraphQLDatabase()
