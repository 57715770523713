import React, { useMemo } from 'react'
import { Card, Heading, Collection, Flex, Badge } from '@aws-amplify/ui-react'
import { atom, useRecoilState } from 'recoil'
import styles from './HistoryCollection.module.css'
import { selectedVideoAtom, userAtom } from '../../../../../recoil/atoms'
import { useVideoHistory } from '../../../../../hooks/useVideoHistory'
import { graphqlDatabase } from '../../../../../database/graphql-database'
import { VideoConverted, Video, Keyword } from '../../../../../models'

import { DataStore } from '@aws-amplify/datastore'

/**
 * @author
 * @function HistoryCollection
 **/

export const HistoryCollection = (props) => {
  const [userData, setUserData] = useRecoilState(userAtom)

  const { videoHistoryData, getVideoHistory } = useVideoHistory()
  const [selectedVideo, setSelectedVideo] = useRecoilState(selectedVideoAtom)

  const videoSelected = async (item) => {
    const videosConverted = await userData.data.videosConverted.toArray()
    let videoConverted = videosConverted.filter(
      (video) => video.videoConvertedVideoId === item.id,
    )[0]
    if (!videoConverted) {
      videoConverted = await DataStore.query(VideoConverted, item.id)
    }
    const video = await DataStore.query(Video, item.id)
    // const video = item
    console.log('videoSelected', video, typeof video)

    const keypoints = await video.keyPoints.toArray()
    const summaries = await video.summaries.toArray()
    const videoKeywords = await video.videoKeywords.toArray()
    console.log('videoKeywords', videoKeywords)

    const keyWordsIds = videoKeywords.map((videoKeyword) => videoKeyword.keywordID)
    console.log('keyWords', keyWordsIds)

    const keyWords = await Promise.all(keyWordsIds.map((id) => DataStore.query(Keyword, id)))

    console.log('keyWords', keyWords)
    let state = {
      videoDetails: {
        id: video.id || '',
        title: video?.title,
        description: video?.description,
        thumbnailURL: video?.thumbnailURL,
        videoURL: 'www.youtube.com/embed/' + video?.id,
        status: '',
      },
      keyPoints: keypoints || [],
      keyWords: keyWords || [],
      summaryText: summaries[0] || '',
      tweets: [],
    }
    setSelectedVideo(state)
  }

  const removeHistoryItem = async (item) => {
    console.log('removeHistoryItem', item)

    //Delete videoConverted item
    const videoConverted = await DataStore.query(VideoConverted, item.id)

    // await graphqlDatabase.deleteVideo(item.id)
    // await graphqlDatabase.deleteVideoConverted(item.id)
    // await getVideoHistory()
  }
  let sortedVideoHistory = useMemo(() => {
    if (videoHistoryData) {
      let sortedVideoHistory = [...videoHistoryData]
      sortedVideoHistory = sortedVideoHistory.sort((a, b) => {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      })
      return sortedVideoHistory
    } else {
      return []
    }
  }, [videoHistoryData])

  return (
    <Collection type="list" items={sortedVideoHistory} gap="1rem">
      {(item, index) => (
        <div className={styles.historyCollectionCard}>
          <div className={styles.historyCardContent}>
            <div className={styles.historyStatusBadge}>
              <Badge
                size="small"
                variation={item?.status == 'complete' ? 'success' : 'info'}
              >
                {item?.status} - ({new Date(item?.createdAt).toLocaleString()})
              </Badge>
            </div>

            <Heading className={styles.historyCardContentTitleHeading}>
              {item?.title}
            </Heading>
            <Flex direction="Row" width={'90%'}>
              <img src={item?.thumbnailURL} width="100px" height="100px" />
              <div className={styles.historyDescription}>
                {item?.description}
              </div>
            </Flex>
          </div>
          <div className={styles.historReadItemContainer}>
            <div
              className={styles.historyReadItemButton}
              onClick={() => videoSelected(item)}
            >
              {'Read'}
            </div>
            <div
              className={styles.historyDeleteItemButton}
              onClick={() => removeHistoryItem(item)}
            >
              Delete
            </div>
          </div>
        </div>
      )}
    </Collection>
  )
}
