import React, { useEffect, useState } from 'react'

import * as go from 'gojs'
import { ReactDiagram } from 'gojs-react'
import './VideoMap.css' // contains .diagram-component CSS
import { useUserData } from '../../../../hooks/useUserData'
import ReactPlayer from 'react-player'
import { Button, Card } from '@aws-amplify/ui-react'

const colors = [
  'rgb(117, 86, 162)',
  'rgb(162, 101, 86)',
  'rgb(86, 97, 162)',
  'rgb(65, 132, 170)',
  'rgb(65, 170, 91)',
  'rgb(170, 65, 163)',
  'rgb(137, 65, 170)',
]

var colorMap = {}
// render function...
export function VideoMap({ videos = [] }) {
  const [nicknames, setNickNames] = React.useState({})
  const [nodeDataArray, setNodeDataArray] = React.useState([])
  const [linkDataArray, setLinkDataArray] = React.useState([])
  const [selectedVideo, setSelectedVideo] = React.useState(null)
  const [selectedVideoTime, setSelectedVideoTime] = React.useState(null)
  const mainVideoPlayer = React.createRef(null)
  const [videoPlaying, setVideoPlaying] = useState(true)

  function textStyle() {
    return {
      font: '18pt Lato, Helvetica, Arial, sans-serif',
      stroke: '#F8F8F8',
    }
  }

  function initDiagram() {
    const $ = go.GraphObject.make
    // set your license key here before creating the diagram: go.Diagram.licenseKey = "...";
    const diagram = $(go.Diagram, {
      // 'undoManager.isEnabled': true, // must be set to allow for model change listening
      // 'undoManager.maxHistoryLength': 0,  // uncomment disable undo/redo functionality
      // 'clickCreatingTool.archetypeNodeData': {
      //   text: 'new node',
      //   color: 'lightblue',
      // },
      model: new go.GraphLinksModel({
        linkKeyProperty: 'key', // IMPORTANT! must be defined for merges and data sync when using GraphLinksModel
      }),
    })

    diagram.initialAutoScale = go.Diagram.Uniform
    diagram.layout = new go.TreeLayout()

    diagram.linkTemplate = $(
      go.Link,
      {
        routing: go.Link.AvoidsNodes,
        corner: 10,
      }, // link route should avoid nodes
      $(
        go.Shape,
        {
          // define a tooltip for each node that displays the color as text
          toolTip: $(
            'ToolTip',
            $(go.TextBlock, { margin: 4 }, new go.Binding('text', 'value')),
          ), // end of Adornment
        },
        new go.Binding('strokeWidth', 'width'),
        new go.Binding('stroke', 'stroke'),
      ),
      $(
        go.Shape,
        {
          toArrow: 'Standard',
        },
        new go.Binding('strokeWidth', 'width'),
        new go.Binding('stroke', 'stroke'),
      ),
    )

    //   $(
    //     go.Link,
    //     $(go.Shape, {
    //       stroke: 'rgb(65, 132, 170)',
    //       strokeWidth: 3,
    //     }), // the link shape
    //     // $(
    //     //   go.Shape, // the arrowhead
    //     //   { toArrow: 'OpenTriangle', stroke: 'rgb(65, 132, 170)', fill: null, strokeWidth: 3 },
    //     // ),
    //   )

    // define a simple Node template
    diagram.nodeTemplate = $(
      go.Node,
      'Auto',
      { fromSpot: go.Spot.AllSides, toSpot: go.Spot.AllSides },
      $(
        go.Shape,
        'RoundedRectangle',
        { name: 'SHAPE', fill: 'rgb(117, 86, 162)', strokeWidth: 1 },
        new go.Binding('fill', 'color'),
      ),
      $(
        go.TextBlock,
        textStyle(),
        { margin: 20, editable: false }, // some room around the text
        new go.Binding('text').makeTwoWay(),
      ),
    )
    diagram.addDiagramListener('ObjectSingleClicked', (e) => {
      const part = e.subject.part
      if (!(part instanceof go.Link)) {
        if (part.data.type === 'video') {
          setSelectedVideo(
            'https://www.youtube.com/watch?v=' + part.data.videoID,
          )
          setSelectedVideoTime(null)
        }
        if (part.data.type === 'keyword') {
          setSelectedVideo(
            'https://www.youtube.com/watch?v=' + part.data.videoID,
          )
          setSelectedVideoTime(null)
        }
        if (part.data.type === 'keypoint') {
          setSelectedVideo(
            'https://www.youtube.com/watch?v=' + part.data.videoID,
          )
          setSelectedVideoTime(part.data.videoTimeSeconds)
        }
      }
    })
    return diagram
  }

  function handleModelChange(changes) {
    //console.log('GoJS model changed!')
  }

  React.useEffect(() => {
    // keyworkd => video => keypoints
    const generateData = async () => {
      let nodes = []
      let linkData = []
      let keyPoints = []
      let videoKeyWords = []
      for (const vid of videos) {
        console.log('videos', videos)
        nodes.push({
          key: vid.id,
          text: vid.title,
          color: 'orange',
          type: 'video',
          videoID: vid.id,
        })

        //create objects for each keypoint

        let videoKeyWord = await vid.videoKeywords.toArray()
        videoKeyWord.forEach((keyword) => {
          nodes.push({
            key: keyword.keywordID,
            text: keyword.keywordID,
            color: 'green',
            type: 'keyword',
            videoID: keyword.videoID,
          })
          linkData.push({
            key: 'link' + keyword.keywordID + keyword.videoID,
            from: keyword.keywordID,
            to: keyword.videoID,
            width: 5,
            stroke: 'green',
          })
        })
        videoKeyWords.push(...videoKeyWord)

        if (videoKeyWord?.length > 0) {
          let keyPoint = await vid.keyPoints.toArray()
          keyPoint.forEach((point) => {
            nodes.push({
              key: point.id,
              text: point.keyPoint,
              color: 'blue',
              type: 'keypoint',
              videoTimeSeconds: point.videoTimeSeconds,
              videoID: point.videoKeyPointsId,
            })
            linkData.push({
              key: 'link' + vid.id + point.id,
              from: vid.id,
              to: point.id,
              width: 5,
              stroke: 'blue',
            })
          })
          keyPoints.push(...keyPoint)
        }
      }
      setNodeDataArray(nodes)
      setLinkDataArray(linkData)

      console.log('keyPoints', keyPoints)
      console.log('videoKeyWords', videoKeyWords)
      console.log('nodeData', nodes)
      console.log('linkData', linkData)
    }
    generateData()
  }, [videos])

  useEffect(() => {
    const seekToTimeInVideo = (time) => {
      console.log('seekToTimeInVideo', time)
      let t = Math.max(time - 2, 0)
      mainVideoPlayer.current.seekTo(t, 'seconds')
      mainVideoPlayer.current.muted = true
      setVideoPlaying(true)
    }

    if (selectedVideoTime) {
      seekToTimeInVideo(selectedVideoTime)
    }
  }, [selectedVideoTime, mainVideoPlayer])

  return (
    <div>
      <ReactDiagram
        initDiagram={initDiagram}
        divClassName="diagram-component"
        nodeDataArray={nodeDataArray}
        linkDataArray={linkDataArray}
        onModelChange={handleModelChange}
      />
      {selectedVideo && (
        <Card
          className="videoMapPlayer"
          borderRadius={20}
          overflow={'hidden'}
          padding={0}
        >
          <div>
            <Button
              className="videoMapPlayerCloseBtn"
              variation="danger"
              onClick={() => setSelectedVideo(null)}
            >
              Close
            </Button>
            <ReactPlayer
              playing={videoPlaying}
              ref={mainVideoPlayer}
              url={selectedVideo}
              width="100%"
              height={`600px`}
              controls={true}
              config={{
                youtube: {
                  playerVars: { showinfo: 1 },
                },
              }}
            />
          </div>
        </Card>
      )}
    </div>
  )
}
