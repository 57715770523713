import * as React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Flex, Button, Text, Link, View } from '@aws-amplify/ui-react'
import { showSubscriptionModalAtom, userAtom } from '../../../recoil/atoms'
import { useRecoilState } from 'recoil'
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react'

import './Navbar.css'
import { useUserData } from '../../../hooks/useUserData'

const Navbar = () => {
  const [showSubscriptionModal, setShowSubscriptionModal] = useRecoilState(
    showSubscriptionModalAtom,
  )
  const { user, signOut } = useAuthenticator((context) => [context.user])
  const userdata = useUserData()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  console.log(user)
  const wordBalance = userdata?.user?.data?.purchasedConversionWords
    ? Number(userdata?.user?.data?.purchasedConversionWords)
    : 0
  return (
    <nav className="navbar fixed-top">
      <Flex
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        alignContent="flex-start"
        wrap="nowrap"
        gap="1rem"
        width="100%"
      >
        <Flex
          height="3rem"
          width="5rem"
          alignItems="center"
          justifyContent="center"
        >
          <img src="/logo.png" alt="logo" height={'100%'} />{' '}
        </Flex>
        {user ? (
          <Flex height="2rem" width="5rem">
            <div className="link" onClick={() => navigate('/synopsis')}>
              Summarise
            </div>
            <div className="link" onClick={() => navigate('/search')}>
              Explore
            </div>
            <div className="link" onClick={() => navigate('/videoMap')}>
              Map
            </div>
          </Flex>
        ) : (
          <Flex height="2rem" width="20rem">
            <div className="link" onClick={() => navigate('/synopsis')}>
              Social Summaries
            </div>
          </Flex>
        )}
      </Flex>
      <View height="2rem" width="fit-content">
        {user ? (
          <Flex>
            <Button
              variation="warning"
              fontSize={'0.8rem'}
              whiteSpace={'nowrap'}
              height={'2rem'}
              width={'fit-content'}
              onClick={() => {
                setShowSubscriptionModal(true)
              }}
            >
              Balance {wordBalance.toLocaleString('en-US')} words
            </Button>
            <Button
              onClick={() => {
                signOut()
                navigate('/signin')
              }}
              fontSize={'0.8rem'}
              whiteSpace={'nowrap'}
              height={'2rem'}
              marginRight={'1rem'}
            >
              Sign out
            </Button>
          </Flex>
        ) : (
          <Button onClick={() => navigate('/signin')}>Sign in</Button>
        )}
      </View>
    </nav>
  )
}

export default Navbar
