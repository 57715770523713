/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser($filter: ModelSubscriptionUserFilterInput) {
    onCreateUser(filter: $filter) {
      id
      videosConverted {
        items {
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userVideosConvertedId
          videoConvertedVideoId
        }
        nextToken
        startedAt
      }
      videosViewed {
        items {
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userVideosViewedId
          videoViewedVideoId
        }
        nextToken
        startedAt
      }
      videosLiked {
        items {
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userVideosLikedId
          videoLikedVideoId
        }
        nextToken
        startedAt
      }
      keyPointGroups {
        items {
          id
          name
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userKeyPointGroupsId
        }
        nextToken
        startedAt
      }
      keyPointsLiked {
        items {
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userKeyPointsLikedId
          keyPointLikedKeyPointId
        }
        nextToken
        startedAt
      }
      summariesLiked {
        items {
          id
          summary
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSummariesLikedId
          videoSummariesId
        }
        nextToken
        startedAt
      }
      channelSubscriptions {
        items {
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userChannelSubscriptionsId
          channelSubscriptionChannelId
        }
        nextToken
        startedAt
      }
      tweetsGenerated {
        items {
          id
          twitterAccount
          tweet
          timestamp
          tweetType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userTweetsGeneratedId
          userTweetsPostedId
        }
        nextToken
        startedAt
      }
      tweetsPosted {
        items {
          id
          twitterAccount
          tweet
          timestamp
          tweetType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userTweetsGeneratedId
          userTweetsPostedId
        }
        nextToken
        startedAt
      }
      subscription {
        id
        dateStart
        dateFinish
        conversionSecondsUsed
        tweetsUsed
        conversionSecondsLimit
        tweetLimit
        customPromptsUsed
        customPromptsLimit
        user {
          id
          purchasedConversionWords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelSubscribersId
          userSubscriptionId
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userSubscriptionUserId
      }
      purchasedConversionWords
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      channelSubscribersId
      userSubscriptionId
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($filter: ModelSubscriptionUserFilterInput) {
    onUpdateUser(filter: $filter) {
      id
      videosConverted {
        items {
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userVideosConvertedId
          videoConvertedVideoId
        }
        nextToken
        startedAt
      }
      videosViewed {
        items {
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userVideosViewedId
          videoViewedVideoId
        }
        nextToken
        startedAt
      }
      videosLiked {
        items {
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userVideosLikedId
          videoLikedVideoId
        }
        nextToken
        startedAt
      }
      keyPointGroups {
        items {
          id
          name
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userKeyPointGroupsId
        }
        nextToken
        startedAt
      }
      keyPointsLiked {
        items {
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userKeyPointsLikedId
          keyPointLikedKeyPointId
        }
        nextToken
        startedAt
      }
      summariesLiked {
        items {
          id
          summary
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSummariesLikedId
          videoSummariesId
        }
        nextToken
        startedAt
      }
      channelSubscriptions {
        items {
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userChannelSubscriptionsId
          channelSubscriptionChannelId
        }
        nextToken
        startedAt
      }
      tweetsGenerated {
        items {
          id
          twitterAccount
          tweet
          timestamp
          tweetType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userTweetsGeneratedId
          userTweetsPostedId
        }
        nextToken
        startedAt
      }
      tweetsPosted {
        items {
          id
          twitterAccount
          tweet
          timestamp
          tweetType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userTweetsGeneratedId
          userTweetsPostedId
        }
        nextToken
        startedAt
      }
      subscription {
        id
        dateStart
        dateFinish
        conversionSecondsUsed
        tweetsUsed
        conversionSecondsLimit
        tweetLimit
        customPromptsUsed
        customPromptsLimit
        user {
          id
          purchasedConversionWords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelSubscribersId
          userSubscriptionId
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userSubscriptionUserId
      }
      purchasedConversionWords
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      channelSubscribersId
      userSubscriptionId
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser($filter: ModelSubscriptionUserFilterInput) {
    onDeleteUser(filter: $filter) {
      id
      videosConverted {
        items {
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userVideosConvertedId
          videoConvertedVideoId
        }
        nextToken
        startedAt
      }
      videosViewed {
        items {
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userVideosViewedId
          videoViewedVideoId
        }
        nextToken
        startedAt
      }
      videosLiked {
        items {
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userVideosLikedId
          videoLikedVideoId
        }
        nextToken
        startedAt
      }
      keyPointGroups {
        items {
          id
          name
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userKeyPointGroupsId
        }
        nextToken
        startedAt
      }
      keyPointsLiked {
        items {
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userKeyPointsLikedId
          keyPointLikedKeyPointId
        }
        nextToken
        startedAt
      }
      summariesLiked {
        items {
          id
          summary
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSummariesLikedId
          videoSummariesId
        }
        nextToken
        startedAt
      }
      channelSubscriptions {
        items {
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userChannelSubscriptionsId
          channelSubscriptionChannelId
        }
        nextToken
        startedAt
      }
      tweetsGenerated {
        items {
          id
          twitterAccount
          tweet
          timestamp
          tweetType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userTweetsGeneratedId
          userTweetsPostedId
        }
        nextToken
        startedAt
      }
      tweetsPosted {
        items {
          id
          twitterAccount
          tweet
          timestamp
          tweetType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userTweetsGeneratedId
          userTweetsPostedId
        }
        nextToken
        startedAt
      }
      subscription {
        id
        dateStart
        dateFinish
        conversionSecondsUsed
        tweetsUsed
        conversionSecondsLimit
        tweetLimit
        customPromptsUsed
        customPromptsLimit
        user {
          id
          purchasedConversionWords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelSubscribersId
          userSubscriptionId
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userSubscriptionUserId
      }
      purchasedConversionWords
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      channelSubscribersId
      userSubscriptionId
    }
  }
`;
export const onCreateUserSubscription = /* GraphQL */ `
  subscription OnCreateUserSubscription(
    $filter: ModelSubscriptionUserSubscriptionFilterInput
  ) {
    onCreateUserSubscription(filter: $filter) {
      id
      dateStart
      dateFinish
      conversionSecondsUsed
      tweetsUsed
      conversionSecondsLimit
      tweetLimit
      customPromptsUsed
      customPromptsLimit
      user {
        id
        videosConverted {
          nextToken
          startedAt
        }
        videosViewed {
          nextToken
          startedAt
        }
        videosLiked {
          nextToken
          startedAt
        }
        keyPointGroups {
          nextToken
          startedAt
        }
        keyPointsLiked {
          nextToken
          startedAt
        }
        summariesLiked {
          nextToken
          startedAt
        }
        channelSubscriptions {
          nextToken
          startedAt
        }
        tweetsGenerated {
          nextToken
          startedAt
        }
        tweetsPosted {
          nextToken
          startedAt
        }
        subscription {
          id
          dateStart
          dateFinish
          conversionSecondsUsed
          tweetsUsed
          conversionSecondsLimit
          tweetLimit
          customPromptsUsed
          customPromptsLimit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSubscriptionUserId
        }
        purchasedConversionWords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelSubscribersId
        userSubscriptionId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userSubscriptionUserId
    }
  }
`;
export const onUpdateUserSubscription = /* GraphQL */ `
  subscription OnUpdateUserSubscription(
    $filter: ModelSubscriptionUserSubscriptionFilterInput
  ) {
    onUpdateUserSubscription(filter: $filter) {
      id
      dateStart
      dateFinish
      conversionSecondsUsed
      tweetsUsed
      conversionSecondsLimit
      tweetLimit
      customPromptsUsed
      customPromptsLimit
      user {
        id
        videosConverted {
          nextToken
          startedAt
        }
        videosViewed {
          nextToken
          startedAt
        }
        videosLiked {
          nextToken
          startedAt
        }
        keyPointGroups {
          nextToken
          startedAt
        }
        keyPointsLiked {
          nextToken
          startedAt
        }
        summariesLiked {
          nextToken
          startedAt
        }
        channelSubscriptions {
          nextToken
          startedAt
        }
        tweetsGenerated {
          nextToken
          startedAt
        }
        tweetsPosted {
          nextToken
          startedAt
        }
        subscription {
          id
          dateStart
          dateFinish
          conversionSecondsUsed
          tweetsUsed
          conversionSecondsLimit
          tweetLimit
          customPromptsUsed
          customPromptsLimit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSubscriptionUserId
        }
        purchasedConversionWords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelSubscribersId
        userSubscriptionId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userSubscriptionUserId
    }
  }
`;
export const onDeleteUserSubscription = /* GraphQL */ `
  subscription OnDeleteUserSubscription(
    $filter: ModelSubscriptionUserSubscriptionFilterInput
  ) {
    onDeleteUserSubscription(filter: $filter) {
      id
      dateStart
      dateFinish
      conversionSecondsUsed
      tweetsUsed
      conversionSecondsLimit
      tweetLimit
      customPromptsUsed
      customPromptsLimit
      user {
        id
        videosConverted {
          nextToken
          startedAt
        }
        videosViewed {
          nextToken
          startedAt
        }
        videosLiked {
          nextToken
          startedAt
        }
        keyPointGroups {
          nextToken
          startedAt
        }
        keyPointsLiked {
          nextToken
          startedAt
        }
        summariesLiked {
          nextToken
          startedAt
        }
        channelSubscriptions {
          nextToken
          startedAt
        }
        tweetsGenerated {
          nextToken
          startedAt
        }
        tweetsPosted {
          nextToken
          startedAt
        }
        subscription {
          id
          dateStart
          dateFinish
          conversionSecondsUsed
          tweetsUsed
          conversionSecondsLimit
          tweetLimit
          customPromptsUsed
          customPromptsLimit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSubscriptionUserId
        }
        purchasedConversionWords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelSubscribersId
        userSubscriptionId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userSubscriptionUserId
    }
  }
`;
export const onCreateVideoConverted = /* GraphQL */ `
  subscription OnCreateVideoConverted(
    $filter: ModelSubscriptionVideoConvertedFilterInput
  ) {
    onCreateVideoConverted(filter: $filter) {
      id
      video {
        id
        title
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        description
        thumbnailURL
        status
        transcriptionJobName
        summaries {
          nextToken
          startedAt
        }
        keyPoints {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelVideosId
      }
      user {
        id
        videosConverted {
          nextToken
          startedAt
        }
        videosViewed {
          nextToken
          startedAt
        }
        videosLiked {
          nextToken
          startedAt
        }
        keyPointGroups {
          nextToken
          startedAt
        }
        keyPointsLiked {
          nextToken
          startedAt
        }
        summariesLiked {
          nextToken
          startedAt
        }
        channelSubscriptions {
          nextToken
          startedAt
        }
        tweetsGenerated {
          nextToken
          startedAt
        }
        tweetsPosted {
          nextToken
          startedAt
        }
        subscription {
          id
          dateStart
          dateFinish
          conversionSecondsUsed
          tweetsUsed
          conversionSecondsLimit
          tweetLimit
          customPromptsUsed
          customPromptsLimit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSubscriptionUserId
        }
        purchasedConversionWords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelSubscribersId
        userSubscriptionId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userVideosConvertedId
      videoConvertedVideoId
    }
  }
`;
export const onUpdateVideoConverted = /* GraphQL */ `
  subscription OnUpdateVideoConverted(
    $filter: ModelSubscriptionVideoConvertedFilterInput
  ) {
    onUpdateVideoConverted(filter: $filter) {
      id
      video {
        id
        title
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        description
        thumbnailURL
        status
        transcriptionJobName
        summaries {
          nextToken
          startedAt
        }
        keyPoints {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelVideosId
      }
      user {
        id
        videosConverted {
          nextToken
          startedAt
        }
        videosViewed {
          nextToken
          startedAt
        }
        videosLiked {
          nextToken
          startedAt
        }
        keyPointGroups {
          nextToken
          startedAt
        }
        keyPointsLiked {
          nextToken
          startedAt
        }
        summariesLiked {
          nextToken
          startedAt
        }
        channelSubscriptions {
          nextToken
          startedAt
        }
        tweetsGenerated {
          nextToken
          startedAt
        }
        tweetsPosted {
          nextToken
          startedAt
        }
        subscription {
          id
          dateStart
          dateFinish
          conversionSecondsUsed
          tweetsUsed
          conversionSecondsLimit
          tweetLimit
          customPromptsUsed
          customPromptsLimit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSubscriptionUserId
        }
        purchasedConversionWords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelSubscribersId
        userSubscriptionId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userVideosConvertedId
      videoConvertedVideoId
    }
  }
`;
export const onDeleteVideoConverted = /* GraphQL */ `
  subscription OnDeleteVideoConverted(
    $filter: ModelSubscriptionVideoConvertedFilterInput
  ) {
    onDeleteVideoConverted(filter: $filter) {
      id
      video {
        id
        title
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        description
        thumbnailURL
        status
        transcriptionJobName
        summaries {
          nextToken
          startedAt
        }
        keyPoints {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelVideosId
      }
      user {
        id
        videosConverted {
          nextToken
          startedAt
        }
        videosViewed {
          nextToken
          startedAt
        }
        videosLiked {
          nextToken
          startedAt
        }
        keyPointGroups {
          nextToken
          startedAt
        }
        keyPointsLiked {
          nextToken
          startedAt
        }
        summariesLiked {
          nextToken
          startedAt
        }
        channelSubscriptions {
          nextToken
          startedAt
        }
        tweetsGenerated {
          nextToken
          startedAt
        }
        tweetsPosted {
          nextToken
          startedAt
        }
        subscription {
          id
          dateStart
          dateFinish
          conversionSecondsUsed
          tweetsUsed
          conversionSecondsLimit
          tweetLimit
          customPromptsUsed
          customPromptsLimit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSubscriptionUserId
        }
        purchasedConversionWords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelSubscribersId
        userSubscriptionId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userVideosConvertedId
      videoConvertedVideoId
    }
  }
`;
export const onCreateVideoViewed = /* GraphQL */ `
  subscription OnCreateVideoViewed(
    $filter: ModelSubscriptionVideoViewedFilterInput
  ) {
    onCreateVideoViewed(filter: $filter) {
      id
      video {
        id
        title
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        description
        thumbnailURL
        status
        transcriptionJobName
        summaries {
          nextToken
          startedAt
        }
        keyPoints {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelVideosId
      }
      user {
        id
        videosConverted {
          nextToken
          startedAt
        }
        videosViewed {
          nextToken
          startedAt
        }
        videosLiked {
          nextToken
          startedAt
        }
        keyPointGroups {
          nextToken
          startedAt
        }
        keyPointsLiked {
          nextToken
          startedAt
        }
        summariesLiked {
          nextToken
          startedAt
        }
        channelSubscriptions {
          nextToken
          startedAt
        }
        tweetsGenerated {
          nextToken
          startedAt
        }
        tweetsPosted {
          nextToken
          startedAt
        }
        subscription {
          id
          dateStart
          dateFinish
          conversionSecondsUsed
          tweetsUsed
          conversionSecondsLimit
          tweetLimit
          customPromptsUsed
          customPromptsLimit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSubscriptionUserId
        }
        purchasedConversionWords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelSubscribersId
        userSubscriptionId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userVideosViewedId
      videoViewedVideoId
    }
  }
`;
export const onUpdateVideoViewed = /* GraphQL */ `
  subscription OnUpdateVideoViewed(
    $filter: ModelSubscriptionVideoViewedFilterInput
  ) {
    onUpdateVideoViewed(filter: $filter) {
      id
      video {
        id
        title
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        description
        thumbnailURL
        status
        transcriptionJobName
        summaries {
          nextToken
          startedAt
        }
        keyPoints {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelVideosId
      }
      user {
        id
        videosConverted {
          nextToken
          startedAt
        }
        videosViewed {
          nextToken
          startedAt
        }
        videosLiked {
          nextToken
          startedAt
        }
        keyPointGroups {
          nextToken
          startedAt
        }
        keyPointsLiked {
          nextToken
          startedAt
        }
        summariesLiked {
          nextToken
          startedAt
        }
        channelSubscriptions {
          nextToken
          startedAt
        }
        tweetsGenerated {
          nextToken
          startedAt
        }
        tweetsPosted {
          nextToken
          startedAt
        }
        subscription {
          id
          dateStart
          dateFinish
          conversionSecondsUsed
          tweetsUsed
          conversionSecondsLimit
          tweetLimit
          customPromptsUsed
          customPromptsLimit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSubscriptionUserId
        }
        purchasedConversionWords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelSubscribersId
        userSubscriptionId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userVideosViewedId
      videoViewedVideoId
    }
  }
`;
export const onDeleteVideoViewed = /* GraphQL */ `
  subscription OnDeleteVideoViewed(
    $filter: ModelSubscriptionVideoViewedFilterInput
  ) {
    onDeleteVideoViewed(filter: $filter) {
      id
      video {
        id
        title
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        description
        thumbnailURL
        status
        transcriptionJobName
        summaries {
          nextToken
          startedAt
        }
        keyPoints {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelVideosId
      }
      user {
        id
        videosConverted {
          nextToken
          startedAt
        }
        videosViewed {
          nextToken
          startedAt
        }
        videosLiked {
          nextToken
          startedAt
        }
        keyPointGroups {
          nextToken
          startedAt
        }
        keyPointsLiked {
          nextToken
          startedAt
        }
        summariesLiked {
          nextToken
          startedAt
        }
        channelSubscriptions {
          nextToken
          startedAt
        }
        tweetsGenerated {
          nextToken
          startedAt
        }
        tweetsPosted {
          nextToken
          startedAt
        }
        subscription {
          id
          dateStart
          dateFinish
          conversionSecondsUsed
          tweetsUsed
          conversionSecondsLimit
          tweetLimit
          customPromptsUsed
          customPromptsLimit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSubscriptionUserId
        }
        purchasedConversionWords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelSubscribersId
        userSubscriptionId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userVideosViewedId
      videoViewedVideoId
    }
  }
`;
export const onCreateVideoLiked = /* GraphQL */ `
  subscription OnCreateVideoLiked(
    $filter: ModelSubscriptionVideoLikedFilterInput
  ) {
    onCreateVideoLiked(filter: $filter) {
      id
      video {
        id
        title
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        description
        thumbnailURL
        status
        transcriptionJobName
        summaries {
          nextToken
          startedAt
        }
        keyPoints {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelVideosId
      }
      user {
        id
        videosConverted {
          nextToken
          startedAt
        }
        videosViewed {
          nextToken
          startedAt
        }
        videosLiked {
          nextToken
          startedAt
        }
        keyPointGroups {
          nextToken
          startedAt
        }
        keyPointsLiked {
          nextToken
          startedAt
        }
        summariesLiked {
          nextToken
          startedAt
        }
        channelSubscriptions {
          nextToken
          startedAt
        }
        tweetsGenerated {
          nextToken
          startedAt
        }
        tweetsPosted {
          nextToken
          startedAt
        }
        subscription {
          id
          dateStart
          dateFinish
          conversionSecondsUsed
          tweetsUsed
          conversionSecondsLimit
          tweetLimit
          customPromptsUsed
          customPromptsLimit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSubscriptionUserId
        }
        purchasedConversionWords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelSubscribersId
        userSubscriptionId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userVideosLikedId
      videoLikedVideoId
    }
  }
`;
export const onUpdateVideoLiked = /* GraphQL */ `
  subscription OnUpdateVideoLiked(
    $filter: ModelSubscriptionVideoLikedFilterInput
  ) {
    onUpdateVideoLiked(filter: $filter) {
      id
      video {
        id
        title
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        description
        thumbnailURL
        status
        transcriptionJobName
        summaries {
          nextToken
          startedAt
        }
        keyPoints {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelVideosId
      }
      user {
        id
        videosConverted {
          nextToken
          startedAt
        }
        videosViewed {
          nextToken
          startedAt
        }
        videosLiked {
          nextToken
          startedAt
        }
        keyPointGroups {
          nextToken
          startedAt
        }
        keyPointsLiked {
          nextToken
          startedAt
        }
        summariesLiked {
          nextToken
          startedAt
        }
        channelSubscriptions {
          nextToken
          startedAt
        }
        tweetsGenerated {
          nextToken
          startedAt
        }
        tweetsPosted {
          nextToken
          startedAt
        }
        subscription {
          id
          dateStart
          dateFinish
          conversionSecondsUsed
          tweetsUsed
          conversionSecondsLimit
          tweetLimit
          customPromptsUsed
          customPromptsLimit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSubscriptionUserId
        }
        purchasedConversionWords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelSubscribersId
        userSubscriptionId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userVideosLikedId
      videoLikedVideoId
    }
  }
`;
export const onDeleteVideoLiked = /* GraphQL */ `
  subscription OnDeleteVideoLiked(
    $filter: ModelSubscriptionVideoLikedFilterInput
  ) {
    onDeleteVideoLiked(filter: $filter) {
      id
      video {
        id
        title
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        description
        thumbnailURL
        status
        transcriptionJobName
        summaries {
          nextToken
          startedAt
        }
        keyPoints {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelVideosId
      }
      user {
        id
        videosConverted {
          nextToken
          startedAt
        }
        videosViewed {
          nextToken
          startedAt
        }
        videosLiked {
          nextToken
          startedAt
        }
        keyPointGroups {
          nextToken
          startedAt
        }
        keyPointsLiked {
          nextToken
          startedAt
        }
        summariesLiked {
          nextToken
          startedAt
        }
        channelSubscriptions {
          nextToken
          startedAt
        }
        tweetsGenerated {
          nextToken
          startedAt
        }
        tweetsPosted {
          nextToken
          startedAt
        }
        subscription {
          id
          dateStart
          dateFinish
          conversionSecondsUsed
          tweetsUsed
          conversionSecondsLimit
          tweetLimit
          customPromptsUsed
          customPromptsLimit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSubscriptionUserId
        }
        purchasedConversionWords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelSubscribersId
        userSubscriptionId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userVideosLikedId
      videoLikedVideoId
    }
  }
`;
export const onCreateKeyPointLiked = /* GraphQL */ `
  subscription OnCreateKeyPointLiked(
    $filter: ModelSubscriptionKeyPointLikedFilterInput
  ) {
    onCreateKeyPointLiked(filter: $filter) {
      id
      keyPoint {
        id
        video {
          id
          title
          description
          thumbnailURL
          status
          transcriptionJobName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelVideosId
        }
        keyPoint
        videoTimeSeconds
        keyPointLinks {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        videoKeyPointsId
      }
      user {
        id
        videosConverted {
          nextToken
          startedAt
        }
        videosViewed {
          nextToken
          startedAt
        }
        videosLiked {
          nextToken
          startedAt
        }
        keyPointGroups {
          nextToken
          startedAt
        }
        keyPointsLiked {
          nextToken
          startedAt
        }
        summariesLiked {
          nextToken
          startedAt
        }
        channelSubscriptions {
          nextToken
          startedAt
        }
        tweetsGenerated {
          nextToken
          startedAt
        }
        tweetsPosted {
          nextToken
          startedAt
        }
        subscription {
          id
          dateStart
          dateFinish
          conversionSecondsUsed
          tweetsUsed
          conversionSecondsLimit
          tweetLimit
          customPromptsUsed
          customPromptsLimit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSubscriptionUserId
        }
        purchasedConversionWords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelSubscribersId
        userSubscriptionId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userKeyPointsLikedId
      keyPointLikedKeyPointId
    }
  }
`;
export const onUpdateKeyPointLiked = /* GraphQL */ `
  subscription OnUpdateKeyPointLiked(
    $filter: ModelSubscriptionKeyPointLikedFilterInput
  ) {
    onUpdateKeyPointLiked(filter: $filter) {
      id
      keyPoint {
        id
        video {
          id
          title
          description
          thumbnailURL
          status
          transcriptionJobName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelVideosId
        }
        keyPoint
        videoTimeSeconds
        keyPointLinks {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        videoKeyPointsId
      }
      user {
        id
        videosConverted {
          nextToken
          startedAt
        }
        videosViewed {
          nextToken
          startedAt
        }
        videosLiked {
          nextToken
          startedAt
        }
        keyPointGroups {
          nextToken
          startedAt
        }
        keyPointsLiked {
          nextToken
          startedAt
        }
        summariesLiked {
          nextToken
          startedAt
        }
        channelSubscriptions {
          nextToken
          startedAt
        }
        tweetsGenerated {
          nextToken
          startedAt
        }
        tweetsPosted {
          nextToken
          startedAt
        }
        subscription {
          id
          dateStart
          dateFinish
          conversionSecondsUsed
          tweetsUsed
          conversionSecondsLimit
          tweetLimit
          customPromptsUsed
          customPromptsLimit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSubscriptionUserId
        }
        purchasedConversionWords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelSubscribersId
        userSubscriptionId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userKeyPointsLikedId
      keyPointLikedKeyPointId
    }
  }
`;
export const onDeleteKeyPointLiked = /* GraphQL */ `
  subscription OnDeleteKeyPointLiked(
    $filter: ModelSubscriptionKeyPointLikedFilterInput
  ) {
    onDeleteKeyPointLiked(filter: $filter) {
      id
      keyPoint {
        id
        video {
          id
          title
          description
          thumbnailURL
          status
          transcriptionJobName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelVideosId
        }
        keyPoint
        videoTimeSeconds
        keyPointLinks {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        videoKeyPointsId
      }
      user {
        id
        videosConverted {
          nextToken
          startedAt
        }
        videosViewed {
          nextToken
          startedAt
        }
        videosLiked {
          nextToken
          startedAt
        }
        keyPointGroups {
          nextToken
          startedAt
        }
        keyPointsLiked {
          nextToken
          startedAt
        }
        summariesLiked {
          nextToken
          startedAt
        }
        channelSubscriptions {
          nextToken
          startedAt
        }
        tweetsGenerated {
          nextToken
          startedAt
        }
        tweetsPosted {
          nextToken
          startedAt
        }
        subscription {
          id
          dateStart
          dateFinish
          conversionSecondsUsed
          tweetsUsed
          conversionSecondsLimit
          tweetLimit
          customPromptsUsed
          customPromptsLimit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSubscriptionUserId
        }
        purchasedConversionWords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelSubscribersId
        userSubscriptionId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userKeyPointsLikedId
      keyPointLikedKeyPointId
    }
  }
`;
export const onCreateKeyPointGroup = /* GraphQL */ `
  subscription OnCreateKeyPointGroup(
    $filter: ModelSubscriptionKeyPointGroupFilterInput
  ) {
    onCreateKeyPointGroup(filter: $filter) {
      id
      name
      description
      owner {
        id
        videosConverted {
          nextToken
          startedAt
        }
        videosViewed {
          nextToken
          startedAt
        }
        videosLiked {
          nextToken
          startedAt
        }
        keyPointGroups {
          nextToken
          startedAt
        }
        keyPointsLiked {
          nextToken
          startedAt
        }
        summariesLiked {
          nextToken
          startedAt
        }
        channelSubscriptions {
          nextToken
          startedAt
        }
        tweetsGenerated {
          nextToken
          startedAt
        }
        tweetsPosted {
          nextToken
          startedAt
        }
        subscription {
          id
          dateStart
          dateFinish
          conversionSecondsUsed
          tweetsUsed
          conversionSecondsLimit
          tweetLimit
          customPromptsUsed
          customPromptsLimit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSubscriptionUserId
        }
        purchasedConversionWords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelSubscribersId
        userSubscriptionId
      }
      keyPointLinks {
        items {
          id
          keyPointID
          keyPointGroupID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userKeyPointGroupsId
    }
  }
`;
export const onUpdateKeyPointGroup = /* GraphQL */ `
  subscription OnUpdateKeyPointGroup(
    $filter: ModelSubscriptionKeyPointGroupFilterInput
  ) {
    onUpdateKeyPointGroup(filter: $filter) {
      id
      name
      description
      owner {
        id
        videosConverted {
          nextToken
          startedAt
        }
        videosViewed {
          nextToken
          startedAt
        }
        videosLiked {
          nextToken
          startedAt
        }
        keyPointGroups {
          nextToken
          startedAt
        }
        keyPointsLiked {
          nextToken
          startedAt
        }
        summariesLiked {
          nextToken
          startedAt
        }
        channelSubscriptions {
          nextToken
          startedAt
        }
        tweetsGenerated {
          nextToken
          startedAt
        }
        tweetsPosted {
          nextToken
          startedAt
        }
        subscription {
          id
          dateStart
          dateFinish
          conversionSecondsUsed
          tweetsUsed
          conversionSecondsLimit
          tweetLimit
          customPromptsUsed
          customPromptsLimit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSubscriptionUserId
        }
        purchasedConversionWords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelSubscribersId
        userSubscriptionId
      }
      keyPointLinks {
        items {
          id
          keyPointID
          keyPointGroupID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userKeyPointGroupsId
    }
  }
`;
export const onDeleteKeyPointGroup = /* GraphQL */ `
  subscription OnDeleteKeyPointGroup(
    $filter: ModelSubscriptionKeyPointGroupFilterInput
  ) {
    onDeleteKeyPointGroup(filter: $filter) {
      id
      name
      description
      owner {
        id
        videosConverted {
          nextToken
          startedAt
        }
        videosViewed {
          nextToken
          startedAt
        }
        videosLiked {
          nextToken
          startedAt
        }
        keyPointGroups {
          nextToken
          startedAt
        }
        keyPointsLiked {
          nextToken
          startedAt
        }
        summariesLiked {
          nextToken
          startedAt
        }
        channelSubscriptions {
          nextToken
          startedAt
        }
        tweetsGenerated {
          nextToken
          startedAt
        }
        tweetsPosted {
          nextToken
          startedAt
        }
        subscription {
          id
          dateStart
          dateFinish
          conversionSecondsUsed
          tweetsUsed
          conversionSecondsLimit
          tweetLimit
          customPromptsUsed
          customPromptsLimit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSubscriptionUserId
        }
        purchasedConversionWords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelSubscribersId
        userSubscriptionId
      }
      keyPointLinks {
        items {
          id
          keyPointID
          keyPointGroupID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userKeyPointGroupsId
    }
  }
`;
export const onCreateChannelSubscription = /* GraphQL */ `
  subscription OnCreateChannelSubscription(
    $filter: ModelSubscriptionChannelSubscriptionFilterInput
  ) {
    onCreateChannelSubscription(filter: $filter) {
      channel {
        id
        name
        description
        thumbnailURL
        subscribers {
          nextToken
          startedAt
        }
        videos {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      user {
        id
        videosConverted {
          nextToken
          startedAt
        }
        videosViewed {
          nextToken
          startedAt
        }
        videosLiked {
          nextToken
          startedAt
        }
        keyPointGroups {
          nextToken
          startedAt
        }
        keyPointsLiked {
          nextToken
          startedAt
        }
        summariesLiked {
          nextToken
          startedAt
        }
        channelSubscriptions {
          nextToken
          startedAt
        }
        tweetsGenerated {
          nextToken
          startedAt
        }
        tweetsPosted {
          nextToken
          startedAt
        }
        subscription {
          id
          dateStart
          dateFinish
          conversionSecondsUsed
          tweetsUsed
          conversionSecondsLimit
          tweetLimit
          customPromptsUsed
          customPromptsLimit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSubscriptionUserId
        }
        purchasedConversionWords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelSubscribersId
        userSubscriptionId
      }
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userChannelSubscriptionsId
      channelSubscriptionChannelId
    }
  }
`;
export const onUpdateChannelSubscription = /* GraphQL */ `
  subscription OnUpdateChannelSubscription(
    $filter: ModelSubscriptionChannelSubscriptionFilterInput
  ) {
    onUpdateChannelSubscription(filter: $filter) {
      channel {
        id
        name
        description
        thumbnailURL
        subscribers {
          nextToken
          startedAt
        }
        videos {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      user {
        id
        videosConverted {
          nextToken
          startedAt
        }
        videosViewed {
          nextToken
          startedAt
        }
        videosLiked {
          nextToken
          startedAt
        }
        keyPointGroups {
          nextToken
          startedAt
        }
        keyPointsLiked {
          nextToken
          startedAt
        }
        summariesLiked {
          nextToken
          startedAt
        }
        channelSubscriptions {
          nextToken
          startedAt
        }
        tweetsGenerated {
          nextToken
          startedAt
        }
        tweetsPosted {
          nextToken
          startedAt
        }
        subscription {
          id
          dateStart
          dateFinish
          conversionSecondsUsed
          tweetsUsed
          conversionSecondsLimit
          tweetLimit
          customPromptsUsed
          customPromptsLimit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSubscriptionUserId
        }
        purchasedConversionWords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelSubscribersId
        userSubscriptionId
      }
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userChannelSubscriptionsId
      channelSubscriptionChannelId
    }
  }
`;
export const onDeleteChannelSubscription = /* GraphQL */ `
  subscription OnDeleteChannelSubscription(
    $filter: ModelSubscriptionChannelSubscriptionFilterInput
  ) {
    onDeleteChannelSubscription(filter: $filter) {
      channel {
        id
        name
        description
        thumbnailURL
        subscribers {
          nextToken
          startedAt
        }
        videos {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      user {
        id
        videosConverted {
          nextToken
          startedAt
        }
        videosViewed {
          nextToken
          startedAt
        }
        videosLiked {
          nextToken
          startedAt
        }
        keyPointGroups {
          nextToken
          startedAt
        }
        keyPointsLiked {
          nextToken
          startedAt
        }
        summariesLiked {
          nextToken
          startedAt
        }
        channelSubscriptions {
          nextToken
          startedAt
        }
        tweetsGenerated {
          nextToken
          startedAt
        }
        tweetsPosted {
          nextToken
          startedAt
        }
        subscription {
          id
          dateStart
          dateFinish
          conversionSecondsUsed
          tweetsUsed
          conversionSecondsLimit
          tweetLimit
          customPromptsUsed
          customPromptsLimit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSubscriptionUserId
        }
        purchasedConversionWords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelSubscribersId
        userSubscriptionId
      }
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userChannelSubscriptionsId
      channelSubscriptionChannelId
    }
  }
`;
export const onCreateChannel = /* GraphQL */ `
  subscription OnCreateChannel($filter: ModelSubscriptionChannelFilterInput) {
    onCreateChannel(filter: $filter) {
      id
      name
      description
      thumbnailURL
      subscribers {
        items {
          id
          purchasedConversionWords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelSubscribersId
          userSubscriptionId
        }
        nextToken
        startedAt
      }
      videos {
        items {
          id
          title
          description
          thumbnailURL
          status
          transcriptionJobName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelVideosId
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateChannel = /* GraphQL */ `
  subscription OnUpdateChannel($filter: ModelSubscriptionChannelFilterInput) {
    onUpdateChannel(filter: $filter) {
      id
      name
      description
      thumbnailURL
      subscribers {
        items {
          id
          purchasedConversionWords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelSubscribersId
          userSubscriptionId
        }
        nextToken
        startedAt
      }
      videos {
        items {
          id
          title
          description
          thumbnailURL
          status
          transcriptionJobName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelVideosId
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteChannel = /* GraphQL */ `
  subscription OnDeleteChannel($filter: ModelSubscriptionChannelFilterInput) {
    onDeleteChannel(filter: $filter) {
      id
      name
      description
      thumbnailURL
      subscribers {
        items {
          id
          purchasedConversionWords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelSubscribersId
          userSubscriptionId
        }
        nextToken
        startedAt
      }
      videos {
        items {
          id
          title
          description
          thumbnailURL
          status
          transcriptionJobName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelVideosId
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateVideo = /* GraphQL */ `
  subscription OnCreateVideo($filter: ModelSubscriptionVideoFilterInput) {
    onCreateVideo(filter: $filter) {
      id
      title
      channel {
        id
        name
        description
        thumbnailURL
        subscribers {
          nextToken
          startedAt
        }
        videos {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      description
      thumbnailURL
      status
      transcriptionJobName
      summaries {
        items {
          id
          summary
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSummariesLikedId
          videoSummariesId
        }
        nextToken
        startedAt
      }
      keyPoints {
        items {
          id
          keyPoint
          videoTimeSeconds
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          videoKeyPointsId
        }
        nextToken
        startedAt
      }
      tags {
        items {
          id
          videoId
          tagId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      categories {
        items {
          id
          videoId
          categoryId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      videoKeywords {
        items {
          id
          videoID
          keywordID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      channelVideosId
    }
  }
`;
export const onUpdateVideo = /* GraphQL */ `
  subscription OnUpdateVideo($filter: ModelSubscriptionVideoFilterInput) {
    onUpdateVideo(filter: $filter) {
      id
      title
      channel {
        id
        name
        description
        thumbnailURL
        subscribers {
          nextToken
          startedAt
        }
        videos {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      description
      thumbnailURL
      status
      transcriptionJobName
      summaries {
        items {
          id
          summary
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSummariesLikedId
          videoSummariesId
        }
        nextToken
        startedAt
      }
      keyPoints {
        items {
          id
          keyPoint
          videoTimeSeconds
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          videoKeyPointsId
        }
        nextToken
        startedAt
      }
      tags {
        items {
          id
          videoId
          tagId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      categories {
        items {
          id
          videoId
          categoryId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      videoKeywords {
        items {
          id
          videoID
          keywordID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      channelVideosId
    }
  }
`;
export const onDeleteVideo = /* GraphQL */ `
  subscription OnDeleteVideo($filter: ModelSubscriptionVideoFilterInput) {
    onDeleteVideo(filter: $filter) {
      id
      title
      channel {
        id
        name
        description
        thumbnailURL
        subscribers {
          nextToken
          startedAt
        }
        videos {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      description
      thumbnailURL
      status
      transcriptionJobName
      summaries {
        items {
          id
          summary
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSummariesLikedId
          videoSummariesId
        }
        nextToken
        startedAt
      }
      keyPoints {
        items {
          id
          keyPoint
          videoTimeSeconds
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          videoKeyPointsId
        }
        nextToken
        startedAt
      }
      tags {
        items {
          id
          videoId
          tagId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      categories {
        items {
          id
          videoId
          categoryId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      videoKeywords {
        items {
          id
          videoID
          keywordID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      channelVideosId
    }
  }
`;
export const onCreateKeyword = /* GraphQL */ `
  subscription OnCreateKeyword($filter: ModelSubscriptionKeywordFilterInput) {
    onCreateKeyword(filter: $filter) {
      id
      keyword
      videoKeywords {
        items {
          id
          videoID
          keywordID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateKeyword = /* GraphQL */ `
  subscription OnUpdateKeyword($filter: ModelSubscriptionKeywordFilterInput) {
    onUpdateKeyword(filter: $filter) {
      id
      keyword
      videoKeywords {
        items {
          id
          videoID
          keywordID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteKeyword = /* GraphQL */ `
  subscription OnDeleteKeyword($filter: ModelSubscriptionKeywordFilterInput) {
    onDeleteKeyword(filter: $filter) {
      id
      keyword
      videoKeywords {
        items {
          id
          videoID
          keywordID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateVideoKeyword = /* GraphQL */ `
  subscription OnCreateVideoKeyword(
    $filter: ModelSubscriptionVideoKeywordFilterInput
  ) {
    onCreateVideoKeyword(filter: $filter) {
      id
      videoID
      keywordID
      video {
        id
        title
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        description
        thumbnailURL
        status
        transcriptionJobName
        summaries {
          nextToken
          startedAt
        }
        keyPoints {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelVideosId
      }
      keyword {
        id
        keyword
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateVideoKeyword = /* GraphQL */ `
  subscription OnUpdateVideoKeyword(
    $filter: ModelSubscriptionVideoKeywordFilterInput
  ) {
    onUpdateVideoKeyword(filter: $filter) {
      id
      videoID
      keywordID
      video {
        id
        title
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        description
        thumbnailURL
        status
        transcriptionJobName
        summaries {
          nextToken
          startedAt
        }
        keyPoints {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelVideosId
      }
      keyword {
        id
        keyword
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteVideoKeyword = /* GraphQL */ `
  subscription OnDeleteVideoKeyword(
    $filter: ModelSubscriptionVideoKeywordFilterInput
  ) {
    onDeleteVideoKeyword(filter: $filter) {
      id
      videoID
      keywordID
      video {
        id
        title
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        description
        thumbnailURL
        status
        transcriptionJobName
        summaries {
          nextToken
          startedAt
        }
        keyPoints {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelVideosId
      }
      keyword {
        id
        keyword
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateKeyPoint = /* GraphQL */ `
  subscription OnCreateKeyPoint($filter: ModelSubscriptionKeyPointFilterInput) {
    onCreateKeyPoint(filter: $filter) {
      id
      video {
        id
        title
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        description
        thumbnailURL
        status
        transcriptionJobName
        summaries {
          nextToken
          startedAt
        }
        keyPoints {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelVideosId
      }
      keyPoint
      videoTimeSeconds
      keyPointLinks {
        items {
          id
          keyPointID
          keyPointGroupID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      videoKeyPointsId
    }
  }
`;
export const onUpdateKeyPoint = /* GraphQL */ `
  subscription OnUpdateKeyPoint($filter: ModelSubscriptionKeyPointFilterInput) {
    onUpdateKeyPoint(filter: $filter) {
      id
      video {
        id
        title
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        description
        thumbnailURL
        status
        transcriptionJobName
        summaries {
          nextToken
          startedAt
        }
        keyPoints {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelVideosId
      }
      keyPoint
      videoTimeSeconds
      keyPointLinks {
        items {
          id
          keyPointID
          keyPointGroupID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      videoKeyPointsId
    }
  }
`;
export const onDeleteKeyPoint = /* GraphQL */ `
  subscription OnDeleteKeyPoint($filter: ModelSubscriptionKeyPointFilterInput) {
    onDeleteKeyPoint(filter: $filter) {
      id
      video {
        id
        title
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        description
        thumbnailURL
        status
        transcriptionJobName
        summaries {
          nextToken
          startedAt
        }
        keyPoints {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelVideosId
      }
      keyPoint
      videoTimeSeconds
      keyPointLinks {
        items {
          id
          keyPointID
          keyPointGroupID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      videoKeyPointsId
    }
  }
`;
export const onCreateKeyPointGroupLink = /* GraphQL */ `
  subscription OnCreateKeyPointGroupLink(
    $filter: ModelSubscriptionKeyPointGroupLinkFilterInput
  ) {
    onCreateKeyPointGroupLink(filter: $filter) {
      id
      keyPointID
      keyPointGroupID
      keyPoint {
        id
        video {
          id
          title
          description
          thumbnailURL
          status
          transcriptionJobName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelVideosId
        }
        keyPoint
        videoTimeSeconds
        keyPointLinks {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        videoKeyPointsId
      }
      keyPointGroup {
        id
        name
        description
        owner {
          id
          purchasedConversionWords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelSubscribersId
          userSubscriptionId
        }
        keyPointLinks {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userKeyPointGroupsId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateKeyPointGroupLink = /* GraphQL */ `
  subscription OnUpdateKeyPointGroupLink(
    $filter: ModelSubscriptionKeyPointGroupLinkFilterInput
  ) {
    onUpdateKeyPointGroupLink(filter: $filter) {
      id
      keyPointID
      keyPointGroupID
      keyPoint {
        id
        video {
          id
          title
          description
          thumbnailURL
          status
          transcriptionJobName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelVideosId
        }
        keyPoint
        videoTimeSeconds
        keyPointLinks {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        videoKeyPointsId
      }
      keyPointGroup {
        id
        name
        description
        owner {
          id
          purchasedConversionWords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelSubscribersId
          userSubscriptionId
        }
        keyPointLinks {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userKeyPointGroupsId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteKeyPointGroupLink = /* GraphQL */ `
  subscription OnDeleteKeyPointGroupLink(
    $filter: ModelSubscriptionKeyPointGroupLinkFilterInput
  ) {
    onDeleteKeyPointGroupLink(filter: $filter) {
      id
      keyPointID
      keyPointGroupID
      keyPoint {
        id
        video {
          id
          title
          description
          thumbnailURL
          status
          transcriptionJobName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelVideosId
        }
        keyPoint
        videoTimeSeconds
        keyPointLinks {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        videoKeyPointsId
      }
      keyPointGroup {
        id
        name
        description
        owner {
          id
          purchasedConversionWords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelSubscribersId
          userSubscriptionId
        }
        keyPointLinks {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userKeyPointGroupsId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateSummary = /* GraphQL */ `
  subscription OnCreateSummary($filter: ModelSubscriptionSummaryFilterInput) {
    onCreateSummary(filter: $filter) {
      id
      video {
        id
        title
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        description
        thumbnailURL
        status
        transcriptionJobName
        summaries {
          nextToken
          startedAt
        }
        keyPoints {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelVideosId
      }
      summary
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userSummariesLikedId
      videoSummariesId
    }
  }
`;
export const onUpdateSummary = /* GraphQL */ `
  subscription OnUpdateSummary($filter: ModelSubscriptionSummaryFilterInput) {
    onUpdateSummary(filter: $filter) {
      id
      video {
        id
        title
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        description
        thumbnailURL
        status
        transcriptionJobName
        summaries {
          nextToken
          startedAt
        }
        keyPoints {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelVideosId
      }
      summary
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userSummariesLikedId
      videoSummariesId
    }
  }
`;
export const onDeleteSummary = /* GraphQL */ `
  subscription OnDeleteSummary($filter: ModelSubscriptionSummaryFilterInput) {
    onDeleteSummary(filter: $filter) {
      id
      video {
        id
        title
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        description
        thumbnailURL
        status
        transcriptionJobName
        summaries {
          nextToken
          startedAt
        }
        keyPoints {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelVideosId
      }
      summary
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userSummariesLikedId
      videoSummariesId
    }
  }
`;
export const onCreateTag = /* GraphQL */ `
  subscription OnCreateTag($filter: ModelSubscriptionTagFilterInput) {
    onCreateTag(filter: $filter) {
      id
      video {
        items {
          id
          videoId
          tagId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      label
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateTag = /* GraphQL */ `
  subscription OnUpdateTag($filter: ModelSubscriptionTagFilterInput) {
    onUpdateTag(filter: $filter) {
      id
      video {
        items {
          id
          videoId
          tagId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      label
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteTag = /* GraphQL */ `
  subscription OnDeleteTag($filter: ModelSubscriptionTagFilterInput) {
    onDeleteTag(filter: $filter) {
      id
      video {
        items {
          id
          videoId
          tagId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      label
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateCategory = /* GraphQL */ `
  subscription OnCreateCategory($filter: ModelSubscriptionCategoryFilterInput) {
    onCreateCategory(filter: $filter) {
      id
      video {
        items {
          id
          videoId
          categoryId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      label
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateCategory = /* GraphQL */ `
  subscription OnUpdateCategory($filter: ModelSubscriptionCategoryFilterInput) {
    onUpdateCategory(filter: $filter) {
      id
      video {
        items {
          id
          videoId
          categoryId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      label
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteCategory = /* GraphQL */ `
  subscription OnDeleteCategory($filter: ModelSubscriptionCategoryFilterInput) {
    onDeleteCategory(filter: $filter) {
      id
      video {
        items {
          id
          videoId
          categoryId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      label
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateTweet = /* GraphQL */ `
  subscription OnCreateTweet($filter: ModelSubscriptionTweetFilterInput) {
    onCreateTweet(filter: $filter) {
      id
      twitterAccount
      tweet
      timestamp
      tweetType
      user {
        id
        videosConverted {
          nextToken
          startedAt
        }
        videosViewed {
          nextToken
          startedAt
        }
        videosLiked {
          nextToken
          startedAt
        }
        keyPointGroups {
          nextToken
          startedAt
        }
        keyPointsLiked {
          nextToken
          startedAt
        }
        summariesLiked {
          nextToken
          startedAt
        }
        channelSubscriptions {
          nextToken
          startedAt
        }
        tweetsGenerated {
          nextToken
          startedAt
        }
        tweetsPosted {
          nextToken
          startedAt
        }
        subscription {
          id
          dateStart
          dateFinish
          conversionSecondsUsed
          tweetsUsed
          conversionSecondsLimit
          tweetLimit
          customPromptsUsed
          customPromptsLimit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSubscriptionUserId
        }
        purchasedConversionWords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelSubscribersId
        userSubscriptionId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userTweetsGeneratedId
      userTweetsPostedId
    }
  }
`;
export const onUpdateTweet = /* GraphQL */ `
  subscription OnUpdateTweet($filter: ModelSubscriptionTweetFilterInput) {
    onUpdateTweet(filter: $filter) {
      id
      twitterAccount
      tweet
      timestamp
      tweetType
      user {
        id
        videosConverted {
          nextToken
          startedAt
        }
        videosViewed {
          nextToken
          startedAt
        }
        videosLiked {
          nextToken
          startedAt
        }
        keyPointGroups {
          nextToken
          startedAt
        }
        keyPointsLiked {
          nextToken
          startedAt
        }
        summariesLiked {
          nextToken
          startedAt
        }
        channelSubscriptions {
          nextToken
          startedAt
        }
        tweetsGenerated {
          nextToken
          startedAt
        }
        tweetsPosted {
          nextToken
          startedAt
        }
        subscription {
          id
          dateStart
          dateFinish
          conversionSecondsUsed
          tweetsUsed
          conversionSecondsLimit
          tweetLimit
          customPromptsUsed
          customPromptsLimit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSubscriptionUserId
        }
        purchasedConversionWords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelSubscribersId
        userSubscriptionId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userTweetsGeneratedId
      userTweetsPostedId
    }
  }
`;
export const onDeleteTweet = /* GraphQL */ `
  subscription OnDeleteTweet($filter: ModelSubscriptionTweetFilterInput) {
    onDeleteTweet(filter: $filter) {
      id
      twitterAccount
      tweet
      timestamp
      tweetType
      user {
        id
        videosConverted {
          nextToken
          startedAt
        }
        videosViewed {
          nextToken
          startedAt
        }
        videosLiked {
          nextToken
          startedAt
        }
        keyPointGroups {
          nextToken
          startedAt
        }
        keyPointsLiked {
          nextToken
          startedAt
        }
        summariesLiked {
          nextToken
          startedAt
        }
        channelSubscriptions {
          nextToken
          startedAt
        }
        tweetsGenerated {
          nextToken
          startedAt
        }
        tweetsPosted {
          nextToken
          startedAt
        }
        subscription {
          id
          dateStart
          dateFinish
          conversionSecondsUsed
          tweetsUsed
          conversionSecondsLimit
          tweetLimit
          customPromptsUsed
          customPromptsLimit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSubscriptionUserId
        }
        purchasedConversionWords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelSubscribersId
        userSubscriptionId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userTweetsGeneratedId
      userTweetsPostedId
    }
  }
`;
export const onCreateVideoTags = /* GraphQL */ `
  subscription OnCreateVideoTags(
    $filter: ModelSubscriptionVideoTagsFilterInput
  ) {
    onCreateVideoTags(filter: $filter) {
      id
      videoId
      tagId
      video {
        id
        title
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        description
        thumbnailURL
        status
        transcriptionJobName
        summaries {
          nextToken
          startedAt
        }
        keyPoints {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelVideosId
      }
      tag {
        id
        video {
          nextToken
          startedAt
        }
        label
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateVideoTags = /* GraphQL */ `
  subscription OnUpdateVideoTags(
    $filter: ModelSubscriptionVideoTagsFilterInput
  ) {
    onUpdateVideoTags(filter: $filter) {
      id
      videoId
      tagId
      video {
        id
        title
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        description
        thumbnailURL
        status
        transcriptionJobName
        summaries {
          nextToken
          startedAt
        }
        keyPoints {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelVideosId
      }
      tag {
        id
        video {
          nextToken
          startedAt
        }
        label
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteVideoTags = /* GraphQL */ `
  subscription OnDeleteVideoTags(
    $filter: ModelSubscriptionVideoTagsFilterInput
  ) {
    onDeleteVideoTags(filter: $filter) {
      id
      videoId
      tagId
      video {
        id
        title
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        description
        thumbnailURL
        status
        transcriptionJobName
        summaries {
          nextToken
          startedAt
        }
        keyPoints {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelVideosId
      }
      tag {
        id
        video {
          nextToken
          startedAt
        }
        label
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateVideoCategories = /* GraphQL */ `
  subscription OnCreateVideoCategories(
    $filter: ModelSubscriptionVideoCategoriesFilterInput
  ) {
    onCreateVideoCategories(filter: $filter) {
      id
      videoId
      categoryId
      video {
        id
        title
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        description
        thumbnailURL
        status
        transcriptionJobName
        summaries {
          nextToken
          startedAt
        }
        keyPoints {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelVideosId
      }
      category {
        id
        video {
          nextToken
          startedAt
        }
        label
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateVideoCategories = /* GraphQL */ `
  subscription OnUpdateVideoCategories(
    $filter: ModelSubscriptionVideoCategoriesFilterInput
  ) {
    onUpdateVideoCategories(filter: $filter) {
      id
      videoId
      categoryId
      video {
        id
        title
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        description
        thumbnailURL
        status
        transcriptionJobName
        summaries {
          nextToken
          startedAt
        }
        keyPoints {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelVideosId
      }
      category {
        id
        video {
          nextToken
          startedAt
        }
        label
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteVideoCategories = /* GraphQL */ `
  subscription OnDeleteVideoCategories(
    $filter: ModelSubscriptionVideoCategoriesFilterInput
  ) {
    onDeleteVideoCategories(filter: $filter) {
      id
      videoId
      categoryId
      video {
        id
        title
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        description
        thumbnailURL
        status
        transcriptionJobName
        summaries {
          nextToken
          startedAt
        }
        keyPoints {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelVideosId
      }
      category {
        id
        video {
          nextToken
          startedAt
        }
        label
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
