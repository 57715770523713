import React, { useState, useEffect } from 'react'
import { useRecoilState } from 'recoil'

import { videoConversionHistoryAtom } from '../recoil/atoms'
import { API, graphqlOperation } from 'aws-amplify'
import { listVideoConverteds } from '../graphql/queries'
import { useUserData } from './useUserData'

import { Video, VideoConverted } from '../models'
import { DataStore } from '@aws-amplify/datastore'

//react hook to get video history
export const useVideoHistory = () => {
  const { user, userVideoConversionHistory } = useUserData()
  const [videoHistoryData, setVideoHistoryData] = useRecoilState(
    videoConversionHistoryAtom,
  )

  async function getVideoHistory() {
    if (!user?.data) {
      console.warn('user not logged in')
      return
    }
    const filter = {
      filter: {
        or: userVideoConversionHistory.map((vid) => {
          return {
            id: {
              eq: vid.id,
            },
          }
        }),
      },
    }
    let items = []
    try {
      const result = await API.graphql(
        graphqlOperation(listVideoConverteds, filter),
      )
      items = result.data.listVideoConverteds.items
    } catch (result) {
      console.log('error', result)
      if (result?.data != null) items = result.data.listVideoConverteds.items
    }

    let videos = items.map((item) => item?.video)
    //remove null values from array
    videos = videos.filter((item) => item != null)
    if (videos.length > 0) {
      //sort video data by timestamp
      videos.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt)
      })
      setVideoHistoryData(videos)
    }
  }

  useEffect(() => {
    /**
     * This keeps `post` fresh.
     */
    if (!user?.username) return
    const sub = DataStore.observeQuery(VideoConverted, (c) =>
      c.user.id.eq(user.username),
    )
    .subscribe({
      next: async ({ items }) => {
        let videos = await Promise.all(items.map((item) => item?.video))
        //remove null values from array
        console.log('videos sub', videos)
        videos = videos.filter((item) => item != null)
        // console.log('videos', videos)
        setVideoHistoryData(videos)
      },
      error: error => {
        console.error("Subscription error:", error);
        // handle the error as per your application logic
      }
    });
    
    // .subscribe(async ({ items }) => {
    //   let videos = await Promise.all(items.map((item) => item?.video))
    //   //remove null values from array
    //   console.log('videos sub', videos)
    //   videos = videos.filter((item) => item != null)
    //   // console.log('videos', videos)
    //   setVideoHistoryData(videos)
    // })

    const subVideo = DataStore.observeQuery(Video).subscribe(
      async ({ items }) => {
        console.log('videos items', items)
        // setVideoHistoryData(videos)
      },
    )

    return () => {
      console.log("Unsubscribing from 'observeQuery'.")
      sub.unsubscribe()
      subVideo.unsubscribe()
    }
  }, [user.username])

  // useEffect(() => {
  //   if (!user?.username || videoHistoryData.length > 0) return
  //   getVideoHistory()
  // }, [userVideoConversionHistory])

  return {
    videoHistoryData,
    getVideoHistory,
  }
}
