import { Badge, Card, Flex, Heading } from '@aws-amplify/ui-react'
import './VideoCard.css'
export const VideoCard = (props) => {
  const item = props.item
  const index = props.index
  const videoSelected = props.videoSelected
  const removeHistoryItem = props.removeHistoryItem
  const summariseVideo = props.summariseVideo

  return (
    <Card
      className="videoCard slide-in"
      key={index}
      borderRadius={20}
      overflow={'hidden'}
      padding={0}
    >
      <Flex>
        <img src={item?.thumbnailURL} height="200px" />

        <div className="videoStatusBadge">
          {item.createdAt && item.status && (
            <Badge
              size="small"
              variation={item?.status == 'complete' ? 'success' : 'info'}
            >
              {item?.status} - ({new Date(item?.createdAt).toLocaleString()})
            </Badge>
          )}
        </div>

        <Flex direction={'column'}>
          <Heading color="var(--primary-color-3)" fontSize={16}>
            {item?.title}
          </Heading>
          <Flex direction="Row" width={'90%'}>
            <div className="videoDescription">{item?.description}</div>
          </Flex>
        </Flex>
        {summariseVideo && item.kind == "youtube#video" && (
          <div className="historReadItemContainer">
            <div
              className="videoReadItemButton"
              onClick={() =>
                summariseVideo(
                  'https://www.youtube.com/watch?v=' + item.videoId,
                )
              }
            >
              {'Summarise'}
            </div>
          </div>
        )}
        {videoSelected && removeHistoryItem && (
          <div className="historReadItemContainer">
            <div
              className="videoReadItemButton"
              onClick={() => videoSelected(item)}
            >
              {'Read'}
            </div>
            <div
              className="videoDeleteItemButton"
              onClick={() => removeHistoryItem(item)}
            >
              Delete
            </div>
          </div>
        )}
      </Flex>
    </Card>
  )
}
