import React, { useEffect, useMemo, useState } from 'react'
import {
  Grid,
  Card,
  Heading,
  Divider,
  Text,
  Flex,
  Button,
  Tabs,
  TabItem,
  useTheme,
} from '@aws-amplify/ui-react'

import { HistoryCollection } from './components/HistoryCollection/HistoryCollection'
import { useRecoilState } from 'recoil'
import { selectedVideoAtom } from '../../../recoil/atoms'
import { useVideoHistory } from '../../../hooks/useVideoHistory'
import {
  showSummariseSingleVidAtom,
  SummariseSingleVideoModalBtn,
} from './components/SummariseSingleVideoModalBtn/SummariseSingleVideoModalBtn'
import {
  showSummarisePlaylistVidAtom,
  SummarisePlaylistModal,
} from './components/SummarisePlaylistModal/SummarisePlaylistModal'

import './Synopsis.css'
import { VideoSummaryContent } from './components/VideoSummaryContent/VideoSummaryContent'
import { useWidth } from '../../../hooks/useWidth'
import { Modal } from '../../shared/Modal/Modal'
import { userAtom } from '../../../recoil/atoms'

function Synopsis() {
  const [user, setUser] = useRecoilState(userAtom)

  const [statusText, setStatusText] = useState('')
  const [historyViewExpand, setHistoryViewExpand] = useState(false)
  const { getVideoHistory } = useVideoHistory()
  const [selectedVideo, setSelectedVideo] = useRecoilState(selectedVideoAtom)
  const [showModal, setShowModal] = useRecoilState(showSummariseSingleVidAtom)
  const [showPlayListModal, setShowPlayListModal] = useRecoilState(
    showSummarisePlaylistVidAtom,
  )
  const screenWidth = useWidth()
  const { keyPoints, summaryText, youtubeLinkId, youtubeLink, keyWords } = useMemo(() => {
    console.log('selectedVideo', selectedVideo)
    return {
      keyPoints: selectedVideo?.keyPoints || [],
      summaryText: selectedVideo?.summaryText?.summary || '',
      youtubeLinkId: selectedVideo?.videoDetails.id || '',
      youtubeLink: selectedVideo?.videoDetails.videoURL || '',
      keyWords: selectedVideo?.keyWords || [],
    }
  }, [selectedVideo])

  useEffect(() => {
    setInterval(getVideoHistory, 30000)
    getVideoHistory()
  }, [user.username])

  useEffect(() => {
    if (youtubeLink?.length > 0) {
      setHistoryViewExpand(false)
    } else {
      setHistoryViewExpand(true)
    }
  }, [youtubeLink])

  const { tokens } = useTheme()

  const isSmallScreen = screenWidth < 1024

  return (
    <>
      <Grid
        autoFlow="row"
        templateColumns={{ base: '1fr', large: '1fr 1fr 1fr' }}
        templateRows="1fr 1fr"
        columnGap="0.5rem"
        rowGap="0.5rem"
        height={'90%'}
        padding={tokens.space.medium}
        gap={tokens.space.small}
      >
        <Card
          // columnStart="1"
          // columnEnd={historyViewExpand ? '3' : '1'}
          rowSpan={{ base: isSmallScreen ? 4 : 1, large: 3 }}
          columnSpan={{ base: 1, large: 1 }}
          padding="medium"
          height={'100%'}
          position="relative"
          backgroundColor={'var(--primary-color-3)'}
          boxShadow={'5px 5px 10px 0px rgba(0,0,0,0.75)'}
          borderRadius="40px"
        >
          <div className="historyContainer">
            <Tabs width="100%">
              <TabItem title="Video">
                <Flex margin={5} width={'100%'}>
                  <Button
                    variation="success"
                    onClick={() => setShowModal(true)}
                  >
                    Add Video
                  </Button>
                  <Button
                    variation="warning"
                    onClick={() => setShowPlayListModal(true)}
                  >
                    Add Playlist
                  </Button>
                </Flex>
                <Text variation="info" color="lightblue" fontSize={10}>
                  {statusText}
                </Text>
                <Divider />
                <Heading color="white" marginBottom={20}>
                  Conversion History
                </Heading>
                <HistoryCollection />
              </TabItem>

              <TabItem title="Channel | Playlist"></TabItem>
            </Tabs>
          </div>
        </Card>

        {!isSmallScreen ? (
          <Card
            className="cardContainer"
            columnSpan={{ base: 1, large: 2 }}
            rowSpan={{ base: 3, large: 4 }}
            border={'1px solid var(--primary-color-1)'}
            backgroundColor={'var(--reading-text-background-color)'}
            borderRadius={'10px'}
            height={'100%'}
            position={'relative'}
            overflow={'hidden'}
            boxShadow={'5px 5px 10px 0px rgba(0,0,0,0.35)'}
          >
            <VideoSummaryContent
              user={user}
              keyPoints={keyPoints}
              summaryText={summaryText}
              youtubeLinkId={youtubeLinkId}
              youtubeLink={youtubeLink}
              keyWords={keyWords}
            />
          </Card>
        ) : (
          <Modal
            show={screenWidth < 1024 && selectedVideo}
            close={() => {
              setSelectedVideo(null)
            }}
            backgroundColor={'var(--reading-text-background-color)'}
          >
            <VideoSummaryContent
              user={user}
              keyPoints={keyPoints}
              summaryText={summaryText}
              youtubeLinkId={youtubeLinkId}
              youtubeLink={youtubeLink}
              keyWords={keyWords}
            />
          </Modal>
        )}
      </Grid>
      <SummariseSingleVideoModalBtn showModal={showModal} />
      <SummarisePlaylistModal showModal={showPlayListModal} />
    </>
  )
}

export default Synopsis
