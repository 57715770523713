import React, { useState } from 'react'
import { Card } from '@aws-amplify/ui-react'
import './Modal.css'

export const Modal = (props) => {
  const {
    children,
    show,
    close,
    backgroundColor = 'var(--primary-color-2)',
  } = props
  return (
    <>
      {show && <div className="background" onClick={() => close()}></div>}
      <Card
        display={show ? 'block' : 'none'}
        position={'fixed'}
        left={'50%'}
        top={'50%'}
        transform={'translate(-50%, -50%)'}
        width={'fit-content'}
        height={'fit-content'}
        borderRadius={20}
        backgroundColor={backgroundColor}
      >
        {children}
      </Card>
    </>
  )
}
