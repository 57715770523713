/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      videosConverted {
        items {
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userVideosConvertedId
          videoConvertedVideoId
        }
        nextToken
        startedAt
      }
      videosViewed {
        items {
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userVideosViewedId
          videoViewedVideoId
        }
        nextToken
        startedAt
      }
      videosLiked {
        items {
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userVideosLikedId
          videoLikedVideoId
        }
        nextToken
        startedAt
      }
      keyPointGroups {
        items {
          id
          name
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userKeyPointGroupsId
        }
        nextToken
        startedAt
      }
      keyPointsLiked {
        items {
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userKeyPointsLikedId
          keyPointLikedKeyPointId
        }
        nextToken
        startedAt
      }
      summariesLiked {
        items {
          id
          summary
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSummariesLikedId
          videoSummariesId
        }
        nextToken
        startedAt
      }
      channelSubscriptions {
        items {
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userChannelSubscriptionsId
          channelSubscriptionChannelId
        }
        nextToken
        startedAt
      }
      tweetsGenerated {
        items {
          id
          twitterAccount
          tweet
          timestamp
          tweetType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userTweetsGeneratedId
          userTweetsPostedId
        }
        nextToken
        startedAt
      }
      tweetsPosted {
        items {
          id
          twitterAccount
          tweet
          timestamp
          tweetType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userTweetsGeneratedId
          userTweetsPostedId
        }
        nextToken
        startedAt
      }
      subscription {
        id
        dateStart
        dateFinish
        conversionSecondsUsed
        tweetsUsed
        conversionSecondsLimit
        tweetLimit
        customPromptsUsed
        customPromptsLimit
        user {
          id
          purchasedConversionWords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelSubscribersId
          userSubscriptionId
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userSubscriptionUserId
      }
      purchasedConversionWords
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      channelSubscribersId
      userSubscriptionId
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        videosConverted {
          nextToken
          startedAt
        }
        videosViewed {
          nextToken
          startedAt
        }
        videosLiked {
          nextToken
          startedAt
        }
        keyPointGroups {
          nextToken
          startedAt
        }
        keyPointsLiked {
          nextToken
          startedAt
        }
        summariesLiked {
          nextToken
          startedAt
        }
        channelSubscriptions {
          nextToken
          startedAt
        }
        tweetsGenerated {
          nextToken
          startedAt
        }
        tweetsPosted {
          nextToken
          startedAt
        }
        subscription {
          id
          dateStart
          dateFinish
          conversionSecondsUsed
          tweetsUsed
          conversionSecondsLimit
          tweetLimit
          customPromptsUsed
          customPromptsLimit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSubscriptionUserId
        }
        purchasedConversionWords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelSubscribersId
        userSubscriptionId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        videosConverted {
          nextToken
          startedAt
        }
        videosViewed {
          nextToken
          startedAt
        }
        videosLiked {
          nextToken
          startedAt
        }
        keyPointGroups {
          nextToken
          startedAt
        }
        keyPointsLiked {
          nextToken
          startedAt
        }
        summariesLiked {
          nextToken
          startedAt
        }
        channelSubscriptions {
          nextToken
          startedAt
        }
        tweetsGenerated {
          nextToken
          startedAt
        }
        tweetsPosted {
          nextToken
          startedAt
        }
        subscription {
          id
          dateStart
          dateFinish
          conversionSecondsUsed
          tweetsUsed
          conversionSecondsLimit
          tweetLimit
          customPromptsUsed
          customPromptsLimit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSubscriptionUserId
        }
        purchasedConversionWords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelSubscribersId
        userSubscriptionId
      }
      nextToken
      startedAt
    }
  }
`;
export const getUserSubscription = /* GraphQL */ `
  query GetUserSubscription($id: ID!) {
    getUserSubscription(id: $id) {
      id
      dateStart
      dateFinish
      conversionSecondsUsed
      tweetsUsed
      conversionSecondsLimit
      tweetLimit
      customPromptsUsed
      customPromptsLimit
      user {
        id
        videosConverted {
          nextToken
          startedAt
        }
        videosViewed {
          nextToken
          startedAt
        }
        videosLiked {
          nextToken
          startedAt
        }
        keyPointGroups {
          nextToken
          startedAt
        }
        keyPointsLiked {
          nextToken
          startedAt
        }
        summariesLiked {
          nextToken
          startedAt
        }
        channelSubscriptions {
          nextToken
          startedAt
        }
        tweetsGenerated {
          nextToken
          startedAt
        }
        tweetsPosted {
          nextToken
          startedAt
        }
        subscription {
          id
          dateStart
          dateFinish
          conversionSecondsUsed
          tweetsUsed
          conversionSecondsLimit
          tweetLimit
          customPromptsUsed
          customPromptsLimit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSubscriptionUserId
        }
        purchasedConversionWords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelSubscribersId
        userSubscriptionId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userSubscriptionUserId
    }
  }
`;
export const listUserSubscriptions = /* GraphQL */ `
  query ListUserSubscriptions(
    $filter: ModelUserSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserSubscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dateStart
        dateFinish
        conversionSecondsUsed
        tweetsUsed
        conversionSecondsLimit
        tweetLimit
        customPromptsUsed
        customPromptsLimit
        user {
          id
          purchasedConversionWords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelSubscribersId
          userSubscriptionId
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userSubscriptionUserId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUserSubscriptions = /* GraphQL */ `
  query SyncUserSubscriptions(
    $filter: ModelUserSubscriptionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUserSubscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        dateStart
        dateFinish
        conversionSecondsUsed
        tweetsUsed
        conversionSecondsLimit
        tweetLimit
        customPromptsUsed
        customPromptsLimit
        user {
          id
          purchasedConversionWords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelSubscribersId
          userSubscriptionId
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userSubscriptionUserId
      }
      nextToken
      startedAt
    }
  }
`;
export const getVideoConverted = /* GraphQL */ `
  query GetVideoConverted($id: ID!) {
    getVideoConverted(id: $id) {
      id
      video {
        id
        title
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        description
        thumbnailURL
        status
        transcriptionJobName
        summaries {
          nextToken
          startedAt
        }
        keyPoints {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelVideosId
      }
      user {
        id
        videosConverted {
          nextToken
          startedAt
        }
        videosViewed {
          nextToken
          startedAt
        }
        videosLiked {
          nextToken
          startedAt
        }
        keyPointGroups {
          nextToken
          startedAt
        }
        keyPointsLiked {
          nextToken
          startedAt
        }
        summariesLiked {
          nextToken
          startedAt
        }
        channelSubscriptions {
          nextToken
          startedAt
        }
        tweetsGenerated {
          nextToken
          startedAt
        }
        tweetsPosted {
          nextToken
          startedAt
        }
        subscription {
          id
          dateStart
          dateFinish
          conversionSecondsUsed
          tweetsUsed
          conversionSecondsLimit
          tweetLimit
          customPromptsUsed
          customPromptsLimit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSubscriptionUserId
        }
        purchasedConversionWords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelSubscribersId
        userSubscriptionId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userVideosConvertedId
      videoConvertedVideoId
    }
  }
`;
export const listVideoConverteds = /* GraphQL */ `
  query ListVideoConverteds(
    $filter: ModelVideoConvertedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideoConverteds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        video {
          id
          title
          description
          thumbnailURL
          status
          transcriptionJobName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelVideosId
        }
        user {
          id
          purchasedConversionWords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelSubscribersId
          userSubscriptionId
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userVideosConvertedId
        videoConvertedVideoId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncVideoConverteds = /* GraphQL */ `
  query SyncVideoConverteds(
    $filter: ModelVideoConvertedFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncVideoConverteds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        video {
          id
          title
          description
          thumbnailURL
          status
          transcriptionJobName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelVideosId
        }
        user {
          id
          purchasedConversionWords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelSubscribersId
          userSubscriptionId
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userVideosConvertedId
        videoConvertedVideoId
      }
      nextToken
      startedAt
    }
  }
`;
export const getVideoViewed = /* GraphQL */ `
  query GetVideoViewed($id: ID!) {
    getVideoViewed(id: $id) {
      id
      video {
        id
        title
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        description
        thumbnailURL
        status
        transcriptionJobName
        summaries {
          nextToken
          startedAt
        }
        keyPoints {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelVideosId
      }
      user {
        id
        videosConverted {
          nextToken
          startedAt
        }
        videosViewed {
          nextToken
          startedAt
        }
        videosLiked {
          nextToken
          startedAt
        }
        keyPointGroups {
          nextToken
          startedAt
        }
        keyPointsLiked {
          nextToken
          startedAt
        }
        summariesLiked {
          nextToken
          startedAt
        }
        channelSubscriptions {
          nextToken
          startedAt
        }
        tweetsGenerated {
          nextToken
          startedAt
        }
        tweetsPosted {
          nextToken
          startedAt
        }
        subscription {
          id
          dateStart
          dateFinish
          conversionSecondsUsed
          tweetsUsed
          conversionSecondsLimit
          tweetLimit
          customPromptsUsed
          customPromptsLimit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSubscriptionUserId
        }
        purchasedConversionWords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelSubscribersId
        userSubscriptionId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userVideosViewedId
      videoViewedVideoId
    }
  }
`;
export const listVideoVieweds = /* GraphQL */ `
  query ListVideoVieweds(
    $filter: ModelVideoViewedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideoVieweds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        video {
          id
          title
          description
          thumbnailURL
          status
          transcriptionJobName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelVideosId
        }
        user {
          id
          purchasedConversionWords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelSubscribersId
          userSubscriptionId
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userVideosViewedId
        videoViewedVideoId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncVideoVieweds = /* GraphQL */ `
  query SyncVideoVieweds(
    $filter: ModelVideoViewedFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncVideoVieweds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        video {
          id
          title
          description
          thumbnailURL
          status
          transcriptionJobName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelVideosId
        }
        user {
          id
          purchasedConversionWords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelSubscribersId
          userSubscriptionId
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userVideosViewedId
        videoViewedVideoId
      }
      nextToken
      startedAt
    }
  }
`;
export const getVideoLiked = /* GraphQL */ `
  query GetVideoLiked($id: ID!) {
    getVideoLiked(id: $id) {
      id
      video {
        id
        title
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        description
        thumbnailURL
        status
        transcriptionJobName
        summaries {
          nextToken
          startedAt
        }
        keyPoints {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelVideosId
      }
      user {
        id
        videosConverted {
          nextToken
          startedAt
        }
        videosViewed {
          nextToken
          startedAt
        }
        videosLiked {
          nextToken
          startedAt
        }
        keyPointGroups {
          nextToken
          startedAt
        }
        keyPointsLiked {
          nextToken
          startedAt
        }
        summariesLiked {
          nextToken
          startedAt
        }
        channelSubscriptions {
          nextToken
          startedAt
        }
        tweetsGenerated {
          nextToken
          startedAt
        }
        tweetsPosted {
          nextToken
          startedAt
        }
        subscription {
          id
          dateStart
          dateFinish
          conversionSecondsUsed
          tweetsUsed
          conversionSecondsLimit
          tweetLimit
          customPromptsUsed
          customPromptsLimit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSubscriptionUserId
        }
        purchasedConversionWords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelSubscribersId
        userSubscriptionId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userVideosLikedId
      videoLikedVideoId
    }
  }
`;
export const listVideoLikeds = /* GraphQL */ `
  query ListVideoLikeds(
    $filter: ModelVideoLikedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideoLikeds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        video {
          id
          title
          description
          thumbnailURL
          status
          transcriptionJobName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelVideosId
        }
        user {
          id
          purchasedConversionWords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelSubscribersId
          userSubscriptionId
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userVideosLikedId
        videoLikedVideoId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncVideoLikeds = /* GraphQL */ `
  query SyncVideoLikeds(
    $filter: ModelVideoLikedFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncVideoLikeds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        video {
          id
          title
          description
          thumbnailURL
          status
          transcriptionJobName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelVideosId
        }
        user {
          id
          purchasedConversionWords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelSubscribersId
          userSubscriptionId
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userVideosLikedId
        videoLikedVideoId
      }
      nextToken
      startedAt
    }
  }
`;
export const getKeyPointLiked = /* GraphQL */ `
  query GetKeyPointLiked($id: ID!) {
    getKeyPointLiked(id: $id) {
      id
      keyPoint {
        id
        video {
          id
          title
          description
          thumbnailURL
          status
          transcriptionJobName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelVideosId
        }
        keyPoint
        videoTimeSeconds
        keyPointLinks {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        videoKeyPointsId
      }
      user {
        id
        videosConverted {
          nextToken
          startedAt
        }
        videosViewed {
          nextToken
          startedAt
        }
        videosLiked {
          nextToken
          startedAt
        }
        keyPointGroups {
          nextToken
          startedAt
        }
        keyPointsLiked {
          nextToken
          startedAt
        }
        summariesLiked {
          nextToken
          startedAt
        }
        channelSubscriptions {
          nextToken
          startedAt
        }
        tweetsGenerated {
          nextToken
          startedAt
        }
        tweetsPosted {
          nextToken
          startedAt
        }
        subscription {
          id
          dateStart
          dateFinish
          conversionSecondsUsed
          tweetsUsed
          conversionSecondsLimit
          tweetLimit
          customPromptsUsed
          customPromptsLimit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSubscriptionUserId
        }
        purchasedConversionWords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelSubscribersId
        userSubscriptionId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userKeyPointsLikedId
      keyPointLikedKeyPointId
    }
  }
`;
export const listKeyPointLikeds = /* GraphQL */ `
  query ListKeyPointLikeds(
    $filter: ModelKeyPointLikedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKeyPointLikeds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        keyPoint {
          id
          keyPoint
          videoTimeSeconds
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          videoKeyPointsId
        }
        user {
          id
          purchasedConversionWords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelSubscribersId
          userSubscriptionId
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userKeyPointsLikedId
        keyPointLikedKeyPointId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncKeyPointLikeds = /* GraphQL */ `
  query SyncKeyPointLikeds(
    $filter: ModelKeyPointLikedFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncKeyPointLikeds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        keyPoint {
          id
          keyPoint
          videoTimeSeconds
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          videoKeyPointsId
        }
        user {
          id
          purchasedConversionWords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelSubscribersId
          userSubscriptionId
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userKeyPointsLikedId
        keyPointLikedKeyPointId
      }
      nextToken
      startedAt
    }
  }
`;
export const getKeyPointGroup = /* GraphQL */ `
  query GetKeyPointGroup($id: ID!) {
    getKeyPointGroup(id: $id) {
      id
      name
      description
      owner {
        id
        videosConverted {
          nextToken
          startedAt
        }
        videosViewed {
          nextToken
          startedAt
        }
        videosLiked {
          nextToken
          startedAt
        }
        keyPointGroups {
          nextToken
          startedAt
        }
        keyPointsLiked {
          nextToken
          startedAt
        }
        summariesLiked {
          nextToken
          startedAt
        }
        channelSubscriptions {
          nextToken
          startedAt
        }
        tweetsGenerated {
          nextToken
          startedAt
        }
        tweetsPosted {
          nextToken
          startedAt
        }
        subscription {
          id
          dateStart
          dateFinish
          conversionSecondsUsed
          tweetsUsed
          conversionSecondsLimit
          tweetLimit
          customPromptsUsed
          customPromptsLimit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSubscriptionUserId
        }
        purchasedConversionWords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelSubscribersId
        userSubscriptionId
      }
      keyPointLinks {
        items {
          id
          keyPointID
          keyPointGroupID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userKeyPointGroupsId
    }
  }
`;
export const listKeyPointGroups = /* GraphQL */ `
  query ListKeyPointGroups(
    $id: ID
    $filter: ModelKeyPointGroupFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listKeyPointGroups(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        description
        owner {
          id
          purchasedConversionWords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelSubscribersId
          userSubscriptionId
        }
        keyPointLinks {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userKeyPointGroupsId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncKeyPointGroups = /* GraphQL */ `
  query SyncKeyPointGroups(
    $filter: ModelKeyPointGroupFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncKeyPointGroups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        owner {
          id
          purchasedConversionWords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelSubscribersId
          userSubscriptionId
        }
        keyPointLinks {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userKeyPointGroupsId
      }
      nextToken
      startedAt
    }
  }
`;
export const getChannelSubscription = /* GraphQL */ `
  query GetChannelSubscription($id: ID!) {
    getChannelSubscription(id: $id) {
      channel {
        id
        name
        description
        thumbnailURL
        subscribers {
          nextToken
          startedAt
        }
        videos {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      user {
        id
        videosConverted {
          nextToken
          startedAt
        }
        videosViewed {
          nextToken
          startedAt
        }
        videosLiked {
          nextToken
          startedAt
        }
        keyPointGroups {
          nextToken
          startedAt
        }
        keyPointsLiked {
          nextToken
          startedAt
        }
        summariesLiked {
          nextToken
          startedAt
        }
        channelSubscriptions {
          nextToken
          startedAt
        }
        tweetsGenerated {
          nextToken
          startedAt
        }
        tweetsPosted {
          nextToken
          startedAt
        }
        subscription {
          id
          dateStart
          dateFinish
          conversionSecondsUsed
          tweetsUsed
          conversionSecondsLimit
          tweetLimit
          customPromptsUsed
          customPromptsLimit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSubscriptionUserId
        }
        purchasedConversionWords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelSubscribersId
        userSubscriptionId
      }
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userChannelSubscriptionsId
      channelSubscriptionChannelId
    }
  }
`;
export const listChannelSubscriptions = /* GraphQL */ `
  query ListChannelSubscriptions(
    $filter: ModelChannelSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChannelSubscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        user {
          id
          purchasedConversionWords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelSubscribersId
          userSubscriptionId
        }
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userChannelSubscriptionsId
        channelSubscriptionChannelId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncChannelSubscriptions = /* GraphQL */ `
  query SyncChannelSubscriptions(
    $filter: ModelChannelSubscriptionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncChannelSubscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        user {
          id
          purchasedConversionWords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelSubscribersId
          userSubscriptionId
        }
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userChannelSubscriptionsId
        channelSubscriptionChannelId
      }
      nextToken
      startedAt
    }
  }
`;
export const getChannel = /* GraphQL */ `
  query GetChannel($id: ID!) {
    getChannel(id: $id) {
      id
      name
      description
      thumbnailURL
      subscribers {
        items {
          id
          purchasedConversionWords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelSubscribersId
          userSubscriptionId
        }
        nextToken
        startedAt
      }
      videos {
        items {
          id
          title
          description
          thumbnailURL
          status
          transcriptionJobName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelVideosId
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listChannels = /* GraphQL */ `
  query ListChannels(
    $filter: ModelChannelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChannels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        thumbnailURL
        subscribers {
          nextToken
          startedAt
        }
        videos {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncChannels = /* GraphQL */ `
  query SyncChannels(
    $filter: ModelChannelFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncChannels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        thumbnailURL
        subscribers {
          nextToken
          startedAt
        }
        videos {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getVideo = /* GraphQL */ `
  query GetVideo($id: ID!) {
    getVideo(id: $id) {
      id
      title
      channel {
        id
        name
        description
        thumbnailURL
        subscribers {
          nextToken
          startedAt
        }
        videos {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      description
      thumbnailURL
      status
      transcriptionJobName
      summaries {
        items {
          id
          summary
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSummariesLikedId
          videoSummariesId
        }
        nextToken
        startedAt
      }
      keyPoints {
        items {
          id
          keyPoint
          videoTimeSeconds
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          videoKeyPointsId
        }
        nextToken
        startedAt
      }
      tags {
        items {
          id
          videoId
          tagId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      categories {
        items {
          id
          videoId
          categoryId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      videoKeywords {
        items {
          id
          videoID
          keywordID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      channelVideosId
    }
  }
`;
export const listVideos = /* GraphQL */ `
  query ListVideos(
    $filter: ModelVideoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        description
        thumbnailURL
        status
        transcriptionJobName
        summaries {
          nextToken
          startedAt
        }
        keyPoints {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelVideosId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncVideos = /* GraphQL */ `
  query SyncVideos(
    $filter: ModelVideoFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncVideos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        description
        thumbnailURL
        status
        transcriptionJobName
        summaries {
          nextToken
          startedAt
        }
        keyPoints {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelVideosId
      }
      nextToken
      startedAt
    }
  }
`;
export const getKeyword = /* GraphQL */ `
  query GetKeyword($id: ID!) {
    getKeyword(id: $id) {
      id
      keyword
      videoKeywords {
        items {
          id
          videoID
          keywordID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listKeywords = /* GraphQL */ `
  query ListKeywords(
    $id: ID
    $filter: ModelKeywordFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listKeywords(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        keyword
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncKeywords = /* GraphQL */ `
  query SyncKeywords(
    $filter: ModelKeywordFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncKeywords(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        keyword
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getVideoKeyword = /* GraphQL */ `
  query GetVideoKeyword($id: ID!) {
    getVideoKeyword(id: $id) {
      id
      videoID
      keywordID
      video {
        id
        title
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        description
        thumbnailURL
        status
        transcriptionJobName
        summaries {
          nextToken
          startedAt
        }
        keyPoints {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelVideosId
      }
      keyword {
        id
        keyword
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listVideoKeywords = /* GraphQL */ `
  query ListVideoKeywords(
    $id: ID
    $filter: ModelVideoKeywordFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listVideoKeywords(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        videoID
        keywordID
        video {
          id
          title
          description
          thumbnailURL
          status
          transcriptionJobName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelVideosId
        }
        keyword {
          id
          keyword
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncVideoKeywords = /* GraphQL */ `
  query SyncVideoKeywords(
    $filter: ModelVideoKeywordFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncVideoKeywords(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        videoID
        keywordID
        video {
          id
          title
          description
          thumbnailURL
          status
          transcriptionJobName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelVideosId
        }
        keyword {
          id
          keyword
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getKeyPoint = /* GraphQL */ `
  query GetKeyPoint($id: ID!) {
    getKeyPoint(id: $id) {
      id
      video {
        id
        title
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        description
        thumbnailURL
        status
        transcriptionJobName
        summaries {
          nextToken
          startedAt
        }
        keyPoints {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelVideosId
      }
      keyPoint
      videoTimeSeconds
      keyPointLinks {
        items {
          id
          keyPointID
          keyPointGroupID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      videoKeyPointsId
    }
  }
`;
export const listKeyPoints = /* GraphQL */ `
  query ListKeyPoints(
    $id: ID
    $filter: ModelKeyPointFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listKeyPoints(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        video {
          id
          title
          description
          thumbnailURL
          status
          transcriptionJobName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelVideosId
        }
        keyPoint
        videoTimeSeconds
        keyPointLinks {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        videoKeyPointsId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncKeyPoints = /* GraphQL */ `
  query SyncKeyPoints(
    $filter: ModelKeyPointFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncKeyPoints(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        video {
          id
          title
          description
          thumbnailURL
          status
          transcriptionJobName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelVideosId
        }
        keyPoint
        videoTimeSeconds
        keyPointLinks {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        videoKeyPointsId
      }
      nextToken
      startedAt
    }
  }
`;
export const getKeyPointGroupLink = /* GraphQL */ `
  query GetKeyPointGroupLink($id: ID!) {
    getKeyPointGroupLink(id: $id) {
      id
      keyPointID
      keyPointGroupID
      keyPoint {
        id
        video {
          id
          title
          description
          thumbnailURL
          status
          transcriptionJobName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelVideosId
        }
        keyPoint
        videoTimeSeconds
        keyPointLinks {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        videoKeyPointsId
      }
      keyPointGroup {
        id
        name
        description
        owner {
          id
          purchasedConversionWords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelSubscribersId
          userSubscriptionId
        }
        keyPointLinks {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userKeyPointGroupsId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listKeyPointGroupLinks = /* GraphQL */ `
  query ListKeyPointGroupLinks(
    $id: ID
    $filter: ModelKeyPointGroupLinkFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listKeyPointGroupLinks(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        keyPointID
        keyPointGroupID
        keyPoint {
          id
          keyPoint
          videoTimeSeconds
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          videoKeyPointsId
        }
        keyPointGroup {
          id
          name
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userKeyPointGroupsId
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncKeyPointGroupLinks = /* GraphQL */ `
  query SyncKeyPointGroupLinks(
    $filter: ModelKeyPointGroupLinkFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncKeyPointGroupLinks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        keyPointID
        keyPointGroupID
        keyPoint {
          id
          keyPoint
          videoTimeSeconds
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          videoKeyPointsId
        }
        keyPointGroup {
          id
          name
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userKeyPointGroupsId
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSummary = /* GraphQL */ `
  query GetSummary($id: ID!) {
    getSummary(id: $id) {
      id
      video {
        id
        title
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        description
        thumbnailURL
        status
        transcriptionJobName
        summaries {
          nextToken
          startedAt
        }
        keyPoints {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelVideosId
      }
      summary
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userSummariesLikedId
      videoSummariesId
    }
  }
`;
export const listSummaries = /* GraphQL */ `
  query ListSummaries(
    $filter: ModelSummaryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSummaries(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        video {
          id
          title
          description
          thumbnailURL
          status
          transcriptionJobName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelVideosId
        }
        summary
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userSummariesLikedId
        videoSummariesId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSummaries = /* GraphQL */ `
  query SyncSummaries(
    $filter: ModelSummaryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSummaries(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        video {
          id
          title
          description
          thumbnailURL
          status
          transcriptionJobName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelVideosId
        }
        summary
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userSummariesLikedId
        videoSummariesId
      }
      nextToken
      startedAt
    }
  }
`;
export const getTag = /* GraphQL */ `
  query GetTag($id: ID!) {
    getTag(id: $id) {
      id
      video {
        items {
          id
          videoId
          tagId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      label
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listTags = /* GraphQL */ `
  query ListTags(
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        video {
          nextToken
          startedAt
        }
        label
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTags = /* GraphQL */ `
  query SyncTags(
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTags(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        video {
          nextToken
          startedAt
        }
        label
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCategory = /* GraphQL */ `
  query GetCategory($id: ID!) {
    getCategory(id: $id) {
      id
      video {
        items {
          id
          videoId
          categoryId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      label
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listCategories = /* GraphQL */ `
  query ListCategories(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        video {
          nextToken
          startedAt
        }
        label
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCategories = /* GraphQL */ `
  query SyncCategories(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        video {
          nextToken
          startedAt
        }
        label
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTweet = /* GraphQL */ `
  query GetTweet($id: ID!) {
    getTweet(id: $id) {
      id
      twitterAccount
      tweet
      timestamp
      tweetType
      user {
        id
        videosConverted {
          nextToken
          startedAt
        }
        videosViewed {
          nextToken
          startedAt
        }
        videosLiked {
          nextToken
          startedAt
        }
        keyPointGroups {
          nextToken
          startedAt
        }
        keyPointsLiked {
          nextToken
          startedAt
        }
        summariesLiked {
          nextToken
          startedAt
        }
        channelSubscriptions {
          nextToken
          startedAt
        }
        tweetsGenerated {
          nextToken
          startedAt
        }
        tweetsPosted {
          nextToken
          startedAt
        }
        subscription {
          id
          dateStart
          dateFinish
          conversionSecondsUsed
          tweetsUsed
          conversionSecondsLimit
          tweetLimit
          customPromptsUsed
          customPromptsLimit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSubscriptionUserId
        }
        purchasedConversionWords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelSubscribersId
        userSubscriptionId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userTweetsGeneratedId
      userTweetsPostedId
    }
  }
`;
export const listTweets = /* GraphQL */ `
  query ListTweets(
    $filter: ModelTweetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTweets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        twitterAccount
        tweet
        timestamp
        tweetType
        user {
          id
          purchasedConversionWords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelSubscribersId
          userSubscriptionId
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userTweetsGeneratedId
        userTweetsPostedId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTweets = /* GraphQL */ `
  query SyncTweets(
    $filter: ModelTweetFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTweets(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        twitterAccount
        tweet
        timestamp
        tweetType
        user {
          id
          purchasedConversionWords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelSubscribersId
          userSubscriptionId
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userTweetsGeneratedId
        userTweetsPostedId
      }
      nextToken
      startedAt
    }
  }
`;
export const getVideoTags = /* GraphQL */ `
  query GetVideoTags($id: ID!) {
    getVideoTags(id: $id) {
      id
      videoId
      tagId
      video {
        id
        title
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        description
        thumbnailURL
        status
        transcriptionJobName
        summaries {
          nextToken
          startedAt
        }
        keyPoints {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelVideosId
      }
      tag {
        id
        video {
          nextToken
          startedAt
        }
        label
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listVideoTags = /* GraphQL */ `
  query ListVideoTags(
    $filter: ModelVideoTagsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideoTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        videoId
        tagId
        video {
          id
          title
          description
          thumbnailURL
          status
          transcriptionJobName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelVideosId
        }
        tag {
          id
          label
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncVideoTags = /* GraphQL */ `
  query SyncVideoTags(
    $filter: ModelVideoTagsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncVideoTags(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        videoId
        tagId
        video {
          id
          title
          description
          thumbnailURL
          status
          transcriptionJobName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelVideosId
        }
        tag {
          id
          label
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getVideoCategories = /* GraphQL */ `
  query GetVideoCategories($id: ID!) {
    getVideoCategories(id: $id) {
      id
      videoId
      categoryId
      video {
        id
        title
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        description
        thumbnailURL
        status
        transcriptionJobName
        summaries {
          nextToken
          startedAt
        }
        keyPoints {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelVideosId
      }
      category {
        id
        video {
          nextToken
          startedAt
        }
        label
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listVideoCategories = /* GraphQL */ `
  query ListVideoCategories(
    $filter: ModelVideoCategoriesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideoCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        videoId
        categoryId
        video {
          id
          title
          description
          thumbnailURL
          status
          transcriptionJobName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelVideosId
        }
        category {
          id
          label
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncVideoCategories = /* GraphQL */ `
  query SyncVideoCategories(
    $filter: ModelVideoCategoriesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncVideoCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        videoId
        categoryId
        video {
          id
          title
          description
          thumbnailURL
          status
          transcriptionJobName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelVideosId
        }
        category {
          id
          label
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const videoKeywordsByVideoID = /* GraphQL */ `
  query VideoKeywordsByVideoID(
    $videoID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelVideoKeywordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    videoKeywordsByVideoID(
      videoID: $videoID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        videoID
        keywordID
        video {
          id
          title
          description
          thumbnailURL
          status
          transcriptionJobName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelVideosId
        }
        keyword {
          id
          keyword
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const videoKeywordsByKeywordID = /* GraphQL */ `
  query VideoKeywordsByKeywordID(
    $keywordID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelVideoKeywordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    videoKeywordsByKeywordID(
      keywordID: $keywordID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        videoID
        keywordID
        video {
          id
          title
          description
          thumbnailURL
          status
          transcriptionJobName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelVideosId
        }
        keyword {
          id
          keyword
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const keyPointGroupLinksByKeyPointID = /* GraphQL */ `
  query KeyPointGroupLinksByKeyPointID(
    $keyPointID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelKeyPointGroupLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    keyPointGroupLinksByKeyPointID(
      keyPointID: $keyPointID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        keyPointID
        keyPointGroupID
        keyPoint {
          id
          keyPoint
          videoTimeSeconds
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          videoKeyPointsId
        }
        keyPointGroup {
          id
          name
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userKeyPointGroupsId
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const keyPointGroupLinksByKeyPointGroupID = /* GraphQL */ `
  query KeyPointGroupLinksByKeyPointGroupID(
    $keyPointGroupID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelKeyPointGroupLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    keyPointGroupLinksByKeyPointGroupID(
      keyPointGroupID: $keyPointGroupID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        keyPointID
        keyPointGroupID
        keyPoint {
          id
          keyPoint
          videoTimeSeconds
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          videoKeyPointsId
        }
        keyPointGroup {
          id
          name
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userKeyPointGroupsId
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const videoTagsByVideoId = /* GraphQL */ `
  query VideoTagsByVideoId(
    $videoId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelVideoTagsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    videoTagsByVideoId(
      videoId: $videoId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        videoId
        tagId
        video {
          id
          title
          description
          thumbnailURL
          status
          transcriptionJobName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelVideosId
        }
        tag {
          id
          label
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const videoTagsByTagId = /* GraphQL */ `
  query VideoTagsByTagId(
    $tagId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelVideoTagsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    videoTagsByTagId(
      tagId: $tagId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        videoId
        tagId
        video {
          id
          title
          description
          thumbnailURL
          status
          transcriptionJobName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelVideosId
        }
        tag {
          id
          label
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const videoCategoriesByVideoId = /* GraphQL */ `
  query VideoCategoriesByVideoId(
    $videoId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelVideoCategoriesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    videoCategoriesByVideoId(
      videoId: $videoId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        videoId
        categoryId
        video {
          id
          title
          description
          thumbnailURL
          status
          transcriptionJobName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelVideosId
        }
        category {
          id
          label
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const videoCategoriesByCategoryId = /* GraphQL */ `
  query VideoCategoriesByCategoryId(
    $categoryId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelVideoCategoriesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    videoCategoriesByCategoryId(
      categoryId: $categoryId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        videoId
        categoryId
        video {
          id
          title
          description
          thumbnailURL
          status
          transcriptionJobName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelVideosId
        }
        category {
          id
          label
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
