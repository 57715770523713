import React from 'react'
import { useRecoilState } from 'recoil'
import { showSummariseSingleVidAtom } from '../components/screens/Synopsis/components/SummariseSingleVideoModalBtn/SummariseSingleVideoModalBtn'

/**
 * @author
 * @function useAutoClipBoard
 **/

export const useAutoClipBoard = () => {
  const [text, setText] = React.useState('')
  const [showModal, setShowModal] = useRecoilState(showSummariseSingleVidAtom)

  const checkClipboardForText = () => {
    window.navigator.clipboard.readText().then((clipText) => {
      console.log('clipText', clipText)
      setText(clipText)
    })
  }

  return { text, checkClipboardForText }
}
