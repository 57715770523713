import React, { useState, useEffect } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import {
  createKeyPointGrouping,
  createKeyPointGroup,
} from '../graphql/mutations'
import { listKeyPointGroups } from '../graphql/queries'
import { useUserData } from './useUserData'

import { DataStore } from '@aws-amplify/datastore'
import { KeyPointGroup, KeyPointGrouping } from '../models/index'

//react hook to get video history
export const useKeyPointGroups = () => {
  const { user } = useUserData()
  const [keyPointGroups, setKeyPointGroups] = useState([])

  async function createNewKeyPointGroup(keyPoints) {
    if (!user?.username) return
    try {
      console.log('keyPoints', keyPoints, user?.username)
      const newKeyPointGroupx = new KeyPointGroup({
        'id': 'keyPointGroup' + Date.now(),
        'user': 'test',
        'keyPoints': keyPoints,
      })
      console.log('newKeyPointGroupx', newKeyPointGroupx)

      const newKeypointGroup = await DataStore.save(newKeyPointGroupx)
      console.log('keypointGroup saved successfully!', newKeypointGroup)

      setKeyPointGroups((keyPointGroups) => [
        ...keyPointGroups,
        newKeypointGroup,
      ])
    } catch (error) {
      console.log('Error saving post', error)
    }

    let newKeypointGroup = await API.graphql(
      graphqlOperation(createKeyPointGroup, {
        input: {
          user: user?.username,
          keyPoints: keyPoints,
        },
      }),
    )
    console.log('createNewKeyPointGroup', newKeypointGroup.data)

    // let grouping = keyPointsIdsArray.map((keyPointId) =>
    //   API.graphql(
    //     graphqlOperation(createKeyPointGrouping, {
    //       input: {
    //         keyPointGroupId: newKeypointGroup.data.createKeyPointGroup.id,
    //         keyPointId: keyPointId,
    //       },
    //     }),
    //   ),
    // )
    // Promise.all(grouping).then((result) => {
    //   console.log('createKeyPointGrouping', result)
    // })
  }

  async function getKeyPointGroups() {
    if (!user?.username) return
    const filter = {
      filter: {
        user: {
          eq: user?.username,
        },
      },
    }
    let items = []
    try {
      const result = await API.graphql(
        graphqlOperation(listKeyPointGroups, filter),
      )
      items = result.data.listKeyPointGroups.items
    } catch (result) {
      console.log('error', result)
      if (result?.data != null) items = result.data.listKeyPointGroups.items
    }

    if (items.length > 0) {
      setKeyPointGroups(items)
    }
  }

  return {
    createNewKeyPointGroup,
    getKeyPointGroups,
    keyPointGroups,
  }
}
