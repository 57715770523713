import React, { useMemo, useState } from 'react'
import {
  Card,
  Button,
  Flex,
  TextField,
  Placeholder,
} from '@aws-amplify/ui-react'
import { atom, useRecoilState } from 'recoil'
import './SummariseSingleVideoModalBtn.css'
import ReactPlayer from 'react-player'
import { SUMMARY_BOT_API } from '../../../../../config'
import { graphqlDatabase } from '../../../../../database/graphql-database'
import { userAtom } from '../../../../../recoil/atoms'
import { useVideoHistory } from '../../../../../hooks/useVideoHistory'
import { useAutoClipBoard } from '../../../../../hooks/useAutoClipBoard'
import { useUserData } from '../../../../../hooks/useUserData'

/**
 * @author
 * @function SummariseSingleVideoModalBtn
 **/

export const showSummariseSingleVidAtom = atom({
  key: 'showSummariseSingleVidState',
  default: false,
})

export const SummariseSingleVideoModalBtn = (props) => {
  const { showModal } = props
  const { user, fetchUserData } = useUserData()
  const [_, setShowModal] = useRecoilState(showSummariseSingleVidAtom)
  const [videoPlaying, setVideoPlaying] = useState(false)
  const [btnDisabled, setBtnDisabled] = useState(false)
  const [statusText, setStatusText] = useState('')
  const { getVideoHistory } = useVideoHistory()
  const { text, checkClipboardForText } = useAutoClipBoard()
  const [youtubeLink, setYoutubeLink] = useState('')
  const [youtubeEmbedLink, setYoutubeEmbedLink] = useState('')
  const [youtubeLinkId, setYoutubeLinkId] = useState('')

  function youtube_parser(url) {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?)|(live\/))\??v?=?([^#&?]*).*/
    var match = url.match(regExp)
    // console.log('match', match)
    return match && match[8].length == 11 ? match[8] : false
  }

  const onChangeLink = (e) => {
    let link = e.target.value
    let linkId = youtube_parser(link)
    let embedUrl = `https://www.youtube.com/embed/${linkId}`
    setYoutubeLinkId(youtube_parser(link))
    setYoutubeLink(link)
    setYoutubeEmbedLink(embedUrl)
  }

  const getSynopsys = async () => {
    setBtnDisabled(true)
    const url = `${SUMMARY_BOT_API}/summariseVideo?userId=${user.username}&videoUrl=${youtubeLink}`
    await fetch(url)
    //create promise that resolves when data.status is not 'processing'
    var counter = 0

    const checkStatus = async () => {
      const videoData = await graphqlDatabase.getVideo(youtubeLinkId)
      let videoStatus = videoData?.status || 'null'
      console.log('checkStatus', videoStatus)
      await fetchUserData()
      if (videoStatus == 'complete') {
        return videoData
      } else {
        counter += 1
        setStatusText(videoStatus)
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(checkStatus())
          }, 5000)
        })
      }
    }

    checkStatus().then((videoData) => {
      console.log('checkStatus res', videoData)
      setStatusText(videoData.status)
      getVideoHistory()
      setBtnDisabled(false)
    })
  }

  useMemo(() => {
    if (text) {
      let linkId = youtube_parser(text)
      console.log('linkId', linkId)
      if (!linkId) return
      let embedUrl = `https://www.youtube.com/embed/${linkId}`
      setYoutubeLinkId(youtube_parser(text))
      setYoutubeLink(text)
      setYoutubeEmbedLink(embedUrl)
    }
  }, [text])

  return (
    <>
      {showModal && (
        <div className="background" onClick={() => setShowModal(false)}></div>
      )}
      <Card
        display={showModal ? 'block' : 'none'}
        position={'fixed'}
        left={'50%'}
        top={'50%'}
        transform={'translate(-50%, -50%)'}
        width={'90%'}
        borderRadius={20}
        backgroundColor={'var(--primary-color-2)'}
      >
        <Flex direction="column" alignItems="center" width="100%">
          {youtubeEmbedLink == '' ? (
            <Placeholder height="400px" width="100%" />
          ) : (
            <ReactPlayer
              playing={videoPlaying}
              url={youtubeEmbedLink}
              width="100%"
              height={`400px`}
              controls={true}
              config={{
                youtube: {
                  playerVars: { showinfo: 1 },
                },
              }}
            />
          )}
          <div>
            <p>{statusText}</p>
            <p></p>
            <TextField
              inputStyles={{
                backgroundColor: 'var(--reading-text-background-color)',
                color: 'var(--reading-text-color)',
              }}
              labelHidden={false}
              descriptiveText="Enter the URL of the video you want to summarise"
              placeholder="eg. https://www.youtube.com/watch?v=9bZkp7q19f0"
              errorMessage="Invalid URL"
              width="100%"
              onChange={onChangeLink}
              value={youtubeLink}
              onClick={() => checkClipboardForText()}
            />{' '}
          </div>
          <Flex>
            <Button variation="danger" onClick={() => setShowModal(false)}>
              Cancel
            </Button>
            <Button
              disabled={btnDisabled}
              variation="success"
              onClick={() => getSynopsys()}
            >
              Add
            </Button>
          </Flex>
        </Flex>
      </Card>
    </>
  )
}
