import React from 'react'
import { useParams } from 'react-router-dom'
import { DataStore } from '@aws-amplify/datastore'
import { VideoSummaryContent } from '../Synopsis/components/VideoSummaryContent/VideoSummaryContent'
import { Video, VideoConverted } from '../../../models'
import { Flex, Card } from '@aws-amplify/ui-react'
export const ShareableVideoViewer = (props) => {
  const { id } = useParams()
  const [videoConverted, setVideoConverted] = React.useState(null)

  const loadVideo = async () => {
    const video = await DataStore.query(Video, id)
    const keypoints = await video.keyPoints.toArray()
    const summaries = await video.summaries.toArray()
    let state = {
      videoDetails: {
        id: video.id || '',
        title: video?.title,
        description: video?.description,
        thumbnailURL: video?.thumbnailURL,
        videoURL: 'www.youtube.com/embed/' + video?.id,
        status: '',
      },
      keyPoints: keypoints || [],
      summaryText: summaries[0] || '',
      tweets: [],
    }
    console.log('state', state)
    setVideoConverted(state)

    if (!video) {
      setTimeout(loadVideo, 1000)
    }
  }

  const {
    keyPoints,
    summaryText,
    youtubeLinkId,
    youtubeLink,
  } = React.useMemo(() => {
    console.log('videoConverted', videoConverted)
    return {
      keyPoints: videoConverted?.keyPoints || [],
      summaryText: videoConverted?.summaryText?.summary || '',
      youtubeLinkId: videoConverted?.videoDetails.id || '',
      youtubeLink: videoConverted?.videoDetails.videoURL || '',
    }
  }, [videoConverted])

  React.useEffect(() => {
    const subVideo = DataStore.observeQuery(Video).subscribe(
      async ({ items }) => {
        console.log('videos items', items)
        // setVideoHistoryData(videos)
      },
    )
    return () => {
      console.log("Unsubscribing from 'observeQuery'.")
      subVideo.unsubscribe()
    }
  }, [])

  React.useEffect(() => {
    if (!id) return
    loadVideo()
  }, [id])

  return (
    <Flex
      width={'100%'}
      height={'100%'}
      alignContent={'center'}
      justifyContent={'center'}
    >
      <Card
        className="cardContainer"
        columnSpan={{ base: 1, large: 2 }}
        rowSpan={{ base: 3, large: 4 }}
        border={'1px solid var(--primary-color-1)'}
        backgroundColor={'var(--reading-text-background-color)'}
        borderRadius={'10px'}
        height={'80%'}
        width={'80%'}
        position={'relative'}
        overflow={'hidden'}
        boxShadow={'5px 5px 10px 0px rgba(0,0,0,0.35)'}
      >
        <VideoSummaryContent
          keyPoints={keyPoints}
          summaryText={summaryText}
          youtubeLinkId={youtubeLinkId}
          youtubeLink={youtubeLink}
          user={{}}
        />
      </Card>
    </Flex>
  )
}
