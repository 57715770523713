import React, { useState, useEffect } from 'react'
import {
    Flex,
    Button,
    SelectField,
  } from '@aws-amplify/ui-react'

export const SpeechSynthesis = ({ text }) => {
  const [voices, setVoices] = useState([])
  const [selectedVoiceName, setSelectedVoiceName] = useState(null)

  const speechHandler = () => {
    if (window.speechSynthesis.speaking) {
      window.speechSynthesis.cancel()
      return
    }
    console.log("Reading text: '" + text + "'")
    const msg = new SpeechSynthesisUtterance()
    msg.text = text
    if (voices && selectedVoiceName) {
      let voice = voices.filter((voice) => voice.name === selectedVoiceName)[0]
      msg.voice = voice
    }

    window.speechSynthesis.speak(msg)
  }

  const getSpeechVoices = () => {
    console.log('getSpeechVoices',window.speechSynthesis)
    setVoices(window.speechSynthesis.getVoices())
  }

  useEffect(() => {
    setTimeout(() => {
    getSpeechVoices()
    }, 1000)
  }, [window.speechSynthesis])

  return (
    <Flex
      marginBottom={20}
      justifyContent={'flex-start'}
      alignItems={'baseline'}
    >
      <SelectField
        label="Voice"
        onChange={(e) => setSelectedVoiceName(e.target.value)}
      >
        {voices
          .filter((voice) => voice.lang.includes('en'))
          .map((voice) => (
            <option value={voice.name}>{voice.name}</option>
          ))}
      </SelectField>
      <Button marginTop={'auto'} height={45} onClick={speechHandler}>
        {window.speechSynthesis.speaking ? 'Stop' : 'Read'}
      </Button>
    </Flex>
  )
}
