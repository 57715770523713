import axios from 'axios'

const API_KEY = 'AIzaSyB7_XRIO__0vqejsITn1iXv5t_JqcM1fK8'

export const useYoutubeApi = () => {
  const searchVideos = async (searchText) => {
    try {
      const data = await axios.get(
        `https://www.googleapis.com/youtube/v3/search?key=${API_KEY}&part=snippet&maxResults=25&q=${searchText}`,
      )

      return data.data
    } catch (error) {
      console.error(error)
    }
  }
  const getVideoUrls = async (CHANNEL_ID) => {
    try {
      const response = await axios.get(
        `https://www.googleapis.com/youtube/v3/search?key=${API_KEY}&channelId=${CHANNEL_ID}&part=snippet,id&order=date&maxResults=20`,
      )
      console.log('getVideoUrls', response.data)

      const videoUrls = response.data.items.map(
        (video) => `https://www.youtube.com/watch?v=${video.id.videoId}`,
      )
      return videoUrls
    } catch (error) {
      console.error(error)
    }
  }
  const getChannelId = async (CHANNEL_USERNAME) => {
    try {
      const response = await axios.get(
        `https://www.googleapis.com/youtube/v3/channels?key=${API_KEY}&forUsername=${CHANNEL_USERNAME}&part=snippet`,
      )
      console.log('getChannelId', response.data)
      const channelId = response.data.etag
      return channelId
    } catch (error) {
      console.error(error)
    }
  }
  const getRecentVideosByChannel = async (channelUsername) => {
    try {
      const response = await axios.get(
        `https://www.googleapis.com/youtube/v3/search?key=${API_KEY}&channelId=${channelUsername}&part=snippet,id&order=date&maxResults=100`,
      )
      console.log('getRecentVideosByChannel', response.data)

      return response.data.items
    } catch (error) {
      console.error(error)
    }
  }

  async function getRecentVideosByPlaylist(PLAYLIST_ID) {
    const response = await fetch(
      `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet,id&order=date&maxResults=100&playlistId=${PLAYLIST_ID}&key=${API_KEY}`,
    )
    const data = await response.json()
    const videos = data.items.map((item) => item.snippet)
    return videos
  }

  return {
    searchVideos,
    getVideoUrls,
    getChannelId,
    getRecentVideosByChannel,
    getRecentVideosByPlaylist,
  }
}
