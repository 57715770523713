/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      videosConverted {
        items {
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userVideosConvertedId
          videoConvertedVideoId
        }
        nextToken
        startedAt
      }
      videosViewed {
        items {
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userVideosViewedId
          videoViewedVideoId
        }
        nextToken
        startedAt
      }
      videosLiked {
        items {
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userVideosLikedId
          videoLikedVideoId
        }
        nextToken
        startedAt
      }
      keyPointGroups {
        items {
          id
          name
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userKeyPointGroupsId
        }
        nextToken
        startedAt
      }
      keyPointsLiked {
        items {
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userKeyPointsLikedId
          keyPointLikedKeyPointId
        }
        nextToken
        startedAt
      }
      summariesLiked {
        items {
          id
          summary
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSummariesLikedId
          videoSummariesId
        }
        nextToken
        startedAt
      }
      channelSubscriptions {
        items {
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userChannelSubscriptionsId
          channelSubscriptionChannelId
        }
        nextToken
        startedAt
      }
      tweetsGenerated {
        items {
          id
          twitterAccount
          tweet
          timestamp
          tweetType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userTweetsGeneratedId
          userTweetsPostedId
        }
        nextToken
        startedAt
      }
      tweetsPosted {
        items {
          id
          twitterAccount
          tweet
          timestamp
          tweetType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userTweetsGeneratedId
          userTweetsPostedId
        }
        nextToken
        startedAt
      }
      subscription {
        id
        dateStart
        dateFinish
        conversionSecondsUsed
        tweetsUsed
        conversionSecondsLimit
        tweetLimit
        customPromptsUsed
        customPromptsLimit
        user {
          id
          purchasedConversionWords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelSubscribersId
          userSubscriptionId
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userSubscriptionUserId
      }
      purchasedConversionWords
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      channelSubscribersId
      userSubscriptionId
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      videosConverted {
        items {
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userVideosConvertedId
          videoConvertedVideoId
        }
        nextToken
        startedAt
      }
      videosViewed {
        items {
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userVideosViewedId
          videoViewedVideoId
        }
        nextToken
        startedAt
      }
      videosLiked {
        items {
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userVideosLikedId
          videoLikedVideoId
        }
        nextToken
        startedAt
      }
      keyPointGroups {
        items {
          id
          name
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userKeyPointGroupsId
        }
        nextToken
        startedAt
      }
      keyPointsLiked {
        items {
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userKeyPointsLikedId
          keyPointLikedKeyPointId
        }
        nextToken
        startedAt
      }
      summariesLiked {
        items {
          id
          summary
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSummariesLikedId
          videoSummariesId
        }
        nextToken
        startedAt
      }
      channelSubscriptions {
        items {
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userChannelSubscriptionsId
          channelSubscriptionChannelId
        }
        nextToken
        startedAt
      }
      tweetsGenerated {
        items {
          id
          twitterAccount
          tweet
          timestamp
          tweetType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userTweetsGeneratedId
          userTweetsPostedId
        }
        nextToken
        startedAt
      }
      tweetsPosted {
        items {
          id
          twitterAccount
          tweet
          timestamp
          tweetType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userTweetsGeneratedId
          userTweetsPostedId
        }
        nextToken
        startedAt
      }
      subscription {
        id
        dateStart
        dateFinish
        conversionSecondsUsed
        tweetsUsed
        conversionSecondsLimit
        tweetLimit
        customPromptsUsed
        customPromptsLimit
        user {
          id
          purchasedConversionWords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelSubscribersId
          userSubscriptionId
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userSubscriptionUserId
      }
      purchasedConversionWords
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      channelSubscribersId
      userSubscriptionId
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      videosConverted {
        items {
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userVideosConvertedId
          videoConvertedVideoId
        }
        nextToken
        startedAt
      }
      videosViewed {
        items {
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userVideosViewedId
          videoViewedVideoId
        }
        nextToken
        startedAt
      }
      videosLiked {
        items {
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userVideosLikedId
          videoLikedVideoId
        }
        nextToken
        startedAt
      }
      keyPointGroups {
        items {
          id
          name
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userKeyPointGroupsId
        }
        nextToken
        startedAt
      }
      keyPointsLiked {
        items {
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userKeyPointsLikedId
          keyPointLikedKeyPointId
        }
        nextToken
        startedAt
      }
      summariesLiked {
        items {
          id
          summary
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSummariesLikedId
          videoSummariesId
        }
        nextToken
        startedAt
      }
      channelSubscriptions {
        items {
          id
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userChannelSubscriptionsId
          channelSubscriptionChannelId
        }
        nextToken
        startedAt
      }
      tweetsGenerated {
        items {
          id
          twitterAccount
          tweet
          timestamp
          tweetType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userTweetsGeneratedId
          userTweetsPostedId
        }
        nextToken
        startedAt
      }
      tweetsPosted {
        items {
          id
          twitterAccount
          tweet
          timestamp
          tweetType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userTweetsGeneratedId
          userTweetsPostedId
        }
        nextToken
        startedAt
      }
      subscription {
        id
        dateStart
        dateFinish
        conversionSecondsUsed
        tweetsUsed
        conversionSecondsLimit
        tweetLimit
        customPromptsUsed
        customPromptsLimit
        user {
          id
          purchasedConversionWords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelSubscribersId
          userSubscriptionId
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userSubscriptionUserId
      }
      purchasedConversionWords
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      channelSubscribersId
      userSubscriptionId
    }
  }
`;
export const createUserSubscription = /* GraphQL */ `
  mutation CreateUserSubscription(
    $input: CreateUserSubscriptionInput!
    $condition: ModelUserSubscriptionConditionInput
  ) {
    createUserSubscription(input: $input, condition: $condition) {
      id
      dateStart
      dateFinish
      conversionSecondsUsed
      tweetsUsed
      conversionSecondsLimit
      tweetLimit
      customPromptsUsed
      customPromptsLimit
      user {
        id
        videosConverted {
          nextToken
          startedAt
        }
        videosViewed {
          nextToken
          startedAt
        }
        videosLiked {
          nextToken
          startedAt
        }
        keyPointGroups {
          nextToken
          startedAt
        }
        keyPointsLiked {
          nextToken
          startedAt
        }
        summariesLiked {
          nextToken
          startedAt
        }
        channelSubscriptions {
          nextToken
          startedAt
        }
        tweetsGenerated {
          nextToken
          startedAt
        }
        tweetsPosted {
          nextToken
          startedAt
        }
        subscription {
          id
          dateStart
          dateFinish
          conversionSecondsUsed
          tweetsUsed
          conversionSecondsLimit
          tweetLimit
          customPromptsUsed
          customPromptsLimit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSubscriptionUserId
        }
        purchasedConversionWords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelSubscribersId
        userSubscriptionId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userSubscriptionUserId
    }
  }
`;
export const updateUserSubscription = /* GraphQL */ `
  mutation UpdateUserSubscription(
    $input: UpdateUserSubscriptionInput!
    $condition: ModelUserSubscriptionConditionInput
  ) {
    updateUserSubscription(input: $input, condition: $condition) {
      id
      dateStart
      dateFinish
      conversionSecondsUsed
      tweetsUsed
      conversionSecondsLimit
      tweetLimit
      customPromptsUsed
      customPromptsLimit
      user {
        id
        videosConverted {
          nextToken
          startedAt
        }
        videosViewed {
          nextToken
          startedAt
        }
        videosLiked {
          nextToken
          startedAt
        }
        keyPointGroups {
          nextToken
          startedAt
        }
        keyPointsLiked {
          nextToken
          startedAt
        }
        summariesLiked {
          nextToken
          startedAt
        }
        channelSubscriptions {
          nextToken
          startedAt
        }
        tweetsGenerated {
          nextToken
          startedAt
        }
        tweetsPosted {
          nextToken
          startedAt
        }
        subscription {
          id
          dateStart
          dateFinish
          conversionSecondsUsed
          tweetsUsed
          conversionSecondsLimit
          tweetLimit
          customPromptsUsed
          customPromptsLimit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSubscriptionUserId
        }
        purchasedConversionWords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelSubscribersId
        userSubscriptionId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userSubscriptionUserId
    }
  }
`;
export const deleteUserSubscription = /* GraphQL */ `
  mutation DeleteUserSubscription(
    $input: DeleteUserSubscriptionInput!
    $condition: ModelUserSubscriptionConditionInput
  ) {
    deleteUserSubscription(input: $input, condition: $condition) {
      id
      dateStart
      dateFinish
      conversionSecondsUsed
      tweetsUsed
      conversionSecondsLimit
      tweetLimit
      customPromptsUsed
      customPromptsLimit
      user {
        id
        videosConverted {
          nextToken
          startedAt
        }
        videosViewed {
          nextToken
          startedAt
        }
        videosLiked {
          nextToken
          startedAt
        }
        keyPointGroups {
          nextToken
          startedAt
        }
        keyPointsLiked {
          nextToken
          startedAt
        }
        summariesLiked {
          nextToken
          startedAt
        }
        channelSubscriptions {
          nextToken
          startedAt
        }
        tweetsGenerated {
          nextToken
          startedAt
        }
        tweetsPosted {
          nextToken
          startedAt
        }
        subscription {
          id
          dateStart
          dateFinish
          conversionSecondsUsed
          tweetsUsed
          conversionSecondsLimit
          tweetLimit
          customPromptsUsed
          customPromptsLimit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSubscriptionUserId
        }
        purchasedConversionWords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelSubscribersId
        userSubscriptionId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userSubscriptionUserId
    }
  }
`;
export const createVideoConverted = /* GraphQL */ `
  mutation CreateVideoConverted(
    $input: CreateVideoConvertedInput!
    $condition: ModelVideoConvertedConditionInput
  ) {
    createVideoConverted(input: $input, condition: $condition) {
      id
      video {
        id
        title
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        description
        thumbnailURL
        status
        transcriptionJobName
        summaries {
          nextToken
          startedAt
        }
        keyPoints {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelVideosId
      }
      user {
        id
        videosConverted {
          nextToken
          startedAt
        }
        videosViewed {
          nextToken
          startedAt
        }
        videosLiked {
          nextToken
          startedAt
        }
        keyPointGroups {
          nextToken
          startedAt
        }
        keyPointsLiked {
          nextToken
          startedAt
        }
        summariesLiked {
          nextToken
          startedAt
        }
        channelSubscriptions {
          nextToken
          startedAt
        }
        tweetsGenerated {
          nextToken
          startedAt
        }
        tweetsPosted {
          nextToken
          startedAt
        }
        subscription {
          id
          dateStart
          dateFinish
          conversionSecondsUsed
          tweetsUsed
          conversionSecondsLimit
          tweetLimit
          customPromptsUsed
          customPromptsLimit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSubscriptionUserId
        }
        purchasedConversionWords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelSubscribersId
        userSubscriptionId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userVideosConvertedId
      videoConvertedVideoId
    }
  }
`;
export const updateVideoConverted = /* GraphQL */ `
  mutation UpdateVideoConverted(
    $input: UpdateVideoConvertedInput!
    $condition: ModelVideoConvertedConditionInput
  ) {
    updateVideoConverted(input: $input, condition: $condition) {
      id
      video {
        id
        title
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        description
        thumbnailURL
        status
        transcriptionJobName
        summaries {
          nextToken
          startedAt
        }
        keyPoints {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelVideosId
      }
      user {
        id
        videosConverted {
          nextToken
          startedAt
        }
        videosViewed {
          nextToken
          startedAt
        }
        videosLiked {
          nextToken
          startedAt
        }
        keyPointGroups {
          nextToken
          startedAt
        }
        keyPointsLiked {
          nextToken
          startedAt
        }
        summariesLiked {
          nextToken
          startedAt
        }
        channelSubscriptions {
          nextToken
          startedAt
        }
        tweetsGenerated {
          nextToken
          startedAt
        }
        tweetsPosted {
          nextToken
          startedAt
        }
        subscription {
          id
          dateStart
          dateFinish
          conversionSecondsUsed
          tweetsUsed
          conversionSecondsLimit
          tweetLimit
          customPromptsUsed
          customPromptsLimit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSubscriptionUserId
        }
        purchasedConversionWords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelSubscribersId
        userSubscriptionId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userVideosConvertedId
      videoConvertedVideoId
    }
  }
`;
export const deleteVideoConverted = /* GraphQL */ `
  mutation DeleteVideoConverted(
    $input: DeleteVideoConvertedInput!
    $condition: ModelVideoConvertedConditionInput
  ) {
    deleteVideoConverted(input: $input, condition: $condition) {
      id
      video {
        id
        title
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        description
        thumbnailURL
        status
        transcriptionJobName
        summaries {
          nextToken
          startedAt
        }
        keyPoints {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelVideosId
      }
      user {
        id
        videosConverted {
          nextToken
          startedAt
        }
        videosViewed {
          nextToken
          startedAt
        }
        videosLiked {
          nextToken
          startedAt
        }
        keyPointGroups {
          nextToken
          startedAt
        }
        keyPointsLiked {
          nextToken
          startedAt
        }
        summariesLiked {
          nextToken
          startedAt
        }
        channelSubscriptions {
          nextToken
          startedAt
        }
        tweetsGenerated {
          nextToken
          startedAt
        }
        tweetsPosted {
          nextToken
          startedAt
        }
        subscription {
          id
          dateStart
          dateFinish
          conversionSecondsUsed
          tweetsUsed
          conversionSecondsLimit
          tweetLimit
          customPromptsUsed
          customPromptsLimit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSubscriptionUserId
        }
        purchasedConversionWords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelSubscribersId
        userSubscriptionId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userVideosConvertedId
      videoConvertedVideoId
    }
  }
`;
export const createVideoViewed = /* GraphQL */ `
  mutation CreateVideoViewed(
    $input: CreateVideoViewedInput!
    $condition: ModelVideoViewedConditionInput
  ) {
    createVideoViewed(input: $input, condition: $condition) {
      id
      video {
        id
        title
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        description
        thumbnailURL
        status
        transcriptionJobName
        summaries {
          nextToken
          startedAt
        }
        keyPoints {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelVideosId
      }
      user {
        id
        videosConverted {
          nextToken
          startedAt
        }
        videosViewed {
          nextToken
          startedAt
        }
        videosLiked {
          nextToken
          startedAt
        }
        keyPointGroups {
          nextToken
          startedAt
        }
        keyPointsLiked {
          nextToken
          startedAt
        }
        summariesLiked {
          nextToken
          startedAt
        }
        channelSubscriptions {
          nextToken
          startedAt
        }
        tweetsGenerated {
          nextToken
          startedAt
        }
        tweetsPosted {
          nextToken
          startedAt
        }
        subscription {
          id
          dateStart
          dateFinish
          conversionSecondsUsed
          tweetsUsed
          conversionSecondsLimit
          tweetLimit
          customPromptsUsed
          customPromptsLimit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSubscriptionUserId
        }
        purchasedConversionWords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelSubscribersId
        userSubscriptionId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userVideosViewedId
      videoViewedVideoId
    }
  }
`;
export const updateVideoViewed = /* GraphQL */ `
  mutation UpdateVideoViewed(
    $input: UpdateVideoViewedInput!
    $condition: ModelVideoViewedConditionInput
  ) {
    updateVideoViewed(input: $input, condition: $condition) {
      id
      video {
        id
        title
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        description
        thumbnailURL
        status
        transcriptionJobName
        summaries {
          nextToken
          startedAt
        }
        keyPoints {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelVideosId
      }
      user {
        id
        videosConverted {
          nextToken
          startedAt
        }
        videosViewed {
          nextToken
          startedAt
        }
        videosLiked {
          nextToken
          startedAt
        }
        keyPointGroups {
          nextToken
          startedAt
        }
        keyPointsLiked {
          nextToken
          startedAt
        }
        summariesLiked {
          nextToken
          startedAt
        }
        channelSubscriptions {
          nextToken
          startedAt
        }
        tweetsGenerated {
          nextToken
          startedAt
        }
        tweetsPosted {
          nextToken
          startedAt
        }
        subscription {
          id
          dateStart
          dateFinish
          conversionSecondsUsed
          tweetsUsed
          conversionSecondsLimit
          tweetLimit
          customPromptsUsed
          customPromptsLimit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSubscriptionUserId
        }
        purchasedConversionWords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelSubscribersId
        userSubscriptionId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userVideosViewedId
      videoViewedVideoId
    }
  }
`;
export const deleteVideoViewed = /* GraphQL */ `
  mutation DeleteVideoViewed(
    $input: DeleteVideoViewedInput!
    $condition: ModelVideoViewedConditionInput
  ) {
    deleteVideoViewed(input: $input, condition: $condition) {
      id
      video {
        id
        title
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        description
        thumbnailURL
        status
        transcriptionJobName
        summaries {
          nextToken
          startedAt
        }
        keyPoints {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelVideosId
      }
      user {
        id
        videosConverted {
          nextToken
          startedAt
        }
        videosViewed {
          nextToken
          startedAt
        }
        videosLiked {
          nextToken
          startedAt
        }
        keyPointGroups {
          nextToken
          startedAt
        }
        keyPointsLiked {
          nextToken
          startedAt
        }
        summariesLiked {
          nextToken
          startedAt
        }
        channelSubscriptions {
          nextToken
          startedAt
        }
        tweetsGenerated {
          nextToken
          startedAt
        }
        tweetsPosted {
          nextToken
          startedAt
        }
        subscription {
          id
          dateStart
          dateFinish
          conversionSecondsUsed
          tweetsUsed
          conversionSecondsLimit
          tweetLimit
          customPromptsUsed
          customPromptsLimit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSubscriptionUserId
        }
        purchasedConversionWords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelSubscribersId
        userSubscriptionId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userVideosViewedId
      videoViewedVideoId
    }
  }
`;
export const createVideoLiked = /* GraphQL */ `
  mutation CreateVideoLiked(
    $input: CreateVideoLikedInput!
    $condition: ModelVideoLikedConditionInput
  ) {
    createVideoLiked(input: $input, condition: $condition) {
      id
      video {
        id
        title
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        description
        thumbnailURL
        status
        transcriptionJobName
        summaries {
          nextToken
          startedAt
        }
        keyPoints {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelVideosId
      }
      user {
        id
        videosConverted {
          nextToken
          startedAt
        }
        videosViewed {
          nextToken
          startedAt
        }
        videosLiked {
          nextToken
          startedAt
        }
        keyPointGroups {
          nextToken
          startedAt
        }
        keyPointsLiked {
          nextToken
          startedAt
        }
        summariesLiked {
          nextToken
          startedAt
        }
        channelSubscriptions {
          nextToken
          startedAt
        }
        tweetsGenerated {
          nextToken
          startedAt
        }
        tweetsPosted {
          nextToken
          startedAt
        }
        subscription {
          id
          dateStart
          dateFinish
          conversionSecondsUsed
          tweetsUsed
          conversionSecondsLimit
          tweetLimit
          customPromptsUsed
          customPromptsLimit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSubscriptionUserId
        }
        purchasedConversionWords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelSubscribersId
        userSubscriptionId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userVideosLikedId
      videoLikedVideoId
    }
  }
`;
export const updateVideoLiked = /* GraphQL */ `
  mutation UpdateVideoLiked(
    $input: UpdateVideoLikedInput!
    $condition: ModelVideoLikedConditionInput
  ) {
    updateVideoLiked(input: $input, condition: $condition) {
      id
      video {
        id
        title
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        description
        thumbnailURL
        status
        transcriptionJobName
        summaries {
          nextToken
          startedAt
        }
        keyPoints {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelVideosId
      }
      user {
        id
        videosConverted {
          nextToken
          startedAt
        }
        videosViewed {
          nextToken
          startedAt
        }
        videosLiked {
          nextToken
          startedAt
        }
        keyPointGroups {
          nextToken
          startedAt
        }
        keyPointsLiked {
          nextToken
          startedAt
        }
        summariesLiked {
          nextToken
          startedAt
        }
        channelSubscriptions {
          nextToken
          startedAt
        }
        tweetsGenerated {
          nextToken
          startedAt
        }
        tweetsPosted {
          nextToken
          startedAt
        }
        subscription {
          id
          dateStart
          dateFinish
          conversionSecondsUsed
          tweetsUsed
          conversionSecondsLimit
          tweetLimit
          customPromptsUsed
          customPromptsLimit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSubscriptionUserId
        }
        purchasedConversionWords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelSubscribersId
        userSubscriptionId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userVideosLikedId
      videoLikedVideoId
    }
  }
`;
export const deleteVideoLiked = /* GraphQL */ `
  mutation DeleteVideoLiked(
    $input: DeleteVideoLikedInput!
    $condition: ModelVideoLikedConditionInput
  ) {
    deleteVideoLiked(input: $input, condition: $condition) {
      id
      video {
        id
        title
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        description
        thumbnailURL
        status
        transcriptionJobName
        summaries {
          nextToken
          startedAt
        }
        keyPoints {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelVideosId
      }
      user {
        id
        videosConverted {
          nextToken
          startedAt
        }
        videosViewed {
          nextToken
          startedAt
        }
        videosLiked {
          nextToken
          startedAt
        }
        keyPointGroups {
          nextToken
          startedAt
        }
        keyPointsLiked {
          nextToken
          startedAt
        }
        summariesLiked {
          nextToken
          startedAt
        }
        channelSubscriptions {
          nextToken
          startedAt
        }
        tweetsGenerated {
          nextToken
          startedAt
        }
        tweetsPosted {
          nextToken
          startedAt
        }
        subscription {
          id
          dateStart
          dateFinish
          conversionSecondsUsed
          tweetsUsed
          conversionSecondsLimit
          tweetLimit
          customPromptsUsed
          customPromptsLimit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSubscriptionUserId
        }
        purchasedConversionWords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelSubscribersId
        userSubscriptionId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userVideosLikedId
      videoLikedVideoId
    }
  }
`;
export const createKeyPointLiked = /* GraphQL */ `
  mutation CreateKeyPointLiked(
    $input: CreateKeyPointLikedInput!
    $condition: ModelKeyPointLikedConditionInput
  ) {
    createKeyPointLiked(input: $input, condition: $condition) {
      id
      keyPoint {
        id
        video {
          id
          title
          description
          thumbnailURL
          status
          transcriptionJobName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelVideosId
        }
        keyPoint
        videoTimeSeconds
        keyPointLinks {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        videoKeyPointsId
      }
      user {
        id
        videosConverted {
          nextToken
          startedAt
        }
        videosViewed {
          nextToken
          startedAt
        }
        videosLiked {
          nextToken
          startedAt
        }
        keyPointGroups {
          nextToken
          startedAt
        }
        keyPointsLiked {
          nextToken
          startedAt
        }
        summariesLiked {
          nextToken
          startedAt
        }
        channelSubscriptions {
          nextToken
          startedAt
        }
        tweetsGenerated {
          nextToken
          startedAt
        }
        tweetsPosted {
          nextToken
          startedAt
        }
        subscription {
          id
          dateStart
          dateFinish
          conversionSecondsUsed
          tweetsUsed
          conversionSecondsLimit
          tweetLimit
          customPromptsUsed
          customPromptsLimit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSubscriptionUserId
        }
        purchasedConversionWords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelSubscribersId
        userSubscriptionId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userKeyPointsLikedId
      keyPointLikedKeyPointId
    }
  }
`;
export const updateKeyPointLiked = /* GraphQL */ `
  mutation UpdateKeyPointLiked(
    $input: UpdateKeyPointLikedInput!
    $condition: ModelKeyPointLikedConditionInput
  ) {
    updateKeyPointLiked(input: $input, condition: $condition) {
      id
      keyPoint {
        id
        video {
          id
          title
          description
          thumbnailURL
          status
          transcriptionJobName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelVideosId
        }
        keyPoint
        videoTimeSeconds
        keyPointLinks {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        videoKeyPointsId
      }
      user {
        id
        videosConverted {
          nextToken
          startedAt
        }
        videosViewed {
          nextToken
          startedAt
        }
        videosLiked {
          nextToken
          startedAt
        }
        keyPointGroups {
          nextToken
          startedAt
        }
        keyPointsLiked {
          nextToken
          startedAt
        }
        summariesLiked {
          nextToken
          startedAt
        }
        channelSubscriptions {
          nextToken
          startedAt
        }
        tweetsGenerated {
          nextToken
          startedAt
        }
        tweetsPosted {
          nextToken
          startedAt
        }
        subscription {
          id
          dateStart
          dateFinish
          conversionSecondsUsed
          tweetsUsed
          conversionSecondsLimit
          tweetLimit
          customPromptsUsed
          customPromptsLimit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSubscriptionUserId
        }
        purchasedConversionWords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelSubscribersId
        userSubscriptionId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userKeyPointsLikedId
      keyPointLikedKeyPointId
    }
  }
`;
export const deleteKeyPointLiked = /* GraphQL */ `
  mutation DeleteKeyPointLiked(
    $input: DeleteKeyPointLikedInput!
    $condition: ModelKeyPointLikedConditionInput
  ) {
    deleteKeyPointLiked(input: $input, condition: $condition) {
      id
      keyPoint {
        id
        video {
          id
          title
          description
          thumbnailURL
          status
          transcriptionJobName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelVideosId
        }
        keyPoint
        videoTimeSeconds
        keyPointLinks {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        videoKeyPointsId
      }
      user {
        id
        videosConverted {
          nextToken
          startedAt
        }
        videosViewed {
          nextToken
          startedAt
        }
        videosLiked {
          nextToken
          startedAt
        }
        keyPointGroups {
          nextToken
          startedAt
        }
        keyPointsLiked {
          nextToken
          startedAt
        }
        summariesLiked {
          nextToken
          startedAt
        }
        channelSubscriptions {
          nextToken
          startedAt
        }
        tweetsGenerated {
          nextToken
          startedAt
        }
        tweetsPosted {
          nextToken
          startedAt
        }
        subscription {
          id
          dateStart
          dateFinish
          conversionSecondsUsed
          tweetsUsed
          conversionSecondsLimit
          tweetLimit
          customPromptsUsed
          customPromptsLimit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSubscriptionUserId
        }
        purchasedConversionWords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelSubscribersId
        userSubscriptionId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userKeyPointsLikedId
      keyPointLikedKeyPointId
    }
  }
`;
export const createKeyPointGroup = /* GraphQL */ `
  mutation CreateKeyPointGroup(
    $input: CreateKeyPointGroupInput!
    $condition: ModelKeyPointGroupConditionInput
  ) {
    createKeyPointGroup(input: $input, condition: $condition) {
      id
      name
      description
      owner {
        id
        videosConverted {
          nextToken
          startedAt
        }
        videosViewed {
          nextToken
          startedAt
        }
        videosLiked {
          nextToken
          startedAt
        }
        keyPointGroups {
          nextToken
          startedAt
        }
        keyPointsLiked {
          nextToken
          startedAt
        }
        summariesLiked {
          nextToken
          startedAt
        }
        channelSubscriptions {
          nextToken
          startedAt
        }
        tweetsGenerated {
          nextToken
          startedAt
        }
        tweetsPosted {
          nextToken
          startedAt
        }
        subscription {
          id
          dateStart
          dateFinish
          conversionSecondsUsed
          tweetsUsed
          conversionSecondsLimit
          tweetLimit
          customPromptsUsed
          customPromptsLimit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSubscriptionUserId
        }
        purchasedConversionWords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelSubscribersId
        userSubscriptionId
      }
      keyPointLinks {
        items {
          id
          keyPointID
          keyPointGroupID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userKeyPointGroupsId
    }
  }
`;
export const updateKeyPointGroup = /* GraphQL */ `
  mutation UpdateKeyPointGroup(
    $input: UpdateKeyPointGroupInput!
    $condition: ModelKeyPointGroupConditionInput
  ) {
    updateKeyPointGroup(input: $input, condition: $condition) {
      id
      name
      description
      owner {
        id
        videosConverted {
          nextToken
          startedAt
        }
        videosViewed {
          nextToken
          startedAt
        }
        videosLiked {
          nextToken
          startedAt
        }
        keyPointGroups {
          nextToken
          startedAt
        }
        keyPointsLiked {
          nextToken
          startedAt
        }
        summariesLiked {
          nextToken
          startedAt
        }
        channelSubscriptions {
          nextToken
          startedAt
        }
        tweetsGenerated {
          nextToken
          startedAt
        }
        tweetsPosted {
          nextToken
          startedAt
        }
        subscription {
          id
          dateStart
          dateFinish
          conversionSecondsUsed
          tweetsUsed
          conversionSecondsLimit
          tweetLimit
          customPromptsUsed
          customPromptsLimit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSubscriptionUserId
        }
        purchasedConversionWords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelSubscribersId
        userSubscriptionId
      }
      keyPointLinks {
        items {
          id
          keyPointID
          keyPointGroupID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userKeyPointGroupsId
    }
  }
`;
export const deleteKeyPointGroup = /* GraphQL */ `
  mutation DeleteKeyPointGroup(
    $input: DeleteKeyPointGroupInput!
    $condition: ModelKeyPointGroupConditionInput
  ) {
    deleteKeyPointGroup(input: $input, condition: $condition) {
      id
      name
      description
      owner {
        id
        videosConverted {
          nextToken
          startedAt
        }
        videosViewed {
          nextToken
          startedAt
        }
        videosLiked {
          nextToken
          startedAt
        }
        keyPointGroups {
          nextToken
          startedAt
        }
        keyPointsLiked {
          nextToken
          startedAt
        }
        summariesLiked {
          nextToken
          startedAt
        }
        channelSubscriptions {
          nextToken
          startedAt
        }
        tweetsGenerated {
          nextToken
          startedAt
        }
        tweetsPosted {
          nextToken
          startedAt
        }
        subscription {
          id
          dateStart
          dateFinish
          conversionSecondsUsed
          tweetsUsed
          conversionSecondsLimit
          tweetLimit
          customPromptsUsed
          customPromptsLimit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSubscriptionUserId
        }
        purchasedConversionWords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelSubscribersId
        userSubscriptionId
      }
      keyPointLinks {
        items {
          id
          keyPointID
          keyPointGroupID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userKeyPointGroupsId
    }
  }
`;
export const createChannelSubscription = /* GraphQL */ `
  mutation CreateChannelSubscription(
    $input: CreateChannelSubscriptionInput!
    $condition: ModelChannelSubscriptionConditionInput
  ) {
    createChannelSubscription(input: $input, condition: $condition) {
      channel {
        id
        name
        description
        thumbnailURL
        subscribers {
          nextToken
          startedAt
        }
        videos {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      user {
        id
        videosConverted {
          nextToken
          startedAt
        }
        videosViewed {
          nextToken
          startedAt
        }
        videosLiked {
          nextToken
          startedAt
        }
        keyPointGroups {
          nextToken
          startedAt
        }
        keyPointsLiked {
          nextToken
          startedAt
        }
        summariesLiked {
          nextToken
          startedAt
        }
        channelSubscriptions {
          nextToken
          startedAt
        }
        tweetsGenerated {
          nextToken
          startedAt
        }
        tweetsPosted {
          nextToken
          startedAt
        }
        subscription {
          id
          dateStart
          dateFinish
          conversionSecondsUsed
          tweetsUsed
          conversionSecondsLimit
          tweetLimit
          customPromptsUsed
          customPromptsLimit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSubscriptionUserId
        }
        purchasedConversionWords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelSubscribersId
        userSubscriptionId
      }
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userChannelSubscriptionsId
      channelSubscriptionChannelId
    }
  }
`;
export const updateChannelSubscription = /* GraphQL */ `
  mutation UpdateChannelSubscription(
    $input: UpdateChannelSubscriptionInput!
    $condition: ModelChannelSubscriptionConditionInput
  ) {
    updateChannelSubscription(input: $input, condition: $condition) {
      channel {
        id
        name
        description
        thumbnailURL
        subscribers {
          nextToken
          startedAt
        }
        videos {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      user {
        id
        videosConverted {
          nextToken
          startedAt
        }
        videosViewed {
          nextToken
          startedAt
        }
        videosLiked {
          nextToken
          startedAt
        }
        keyPointGroups {
          nextToken
          startedAt
        }
        keyPointsLiked {
          nextToken
          startedAt
        }
        summariesLiked {
          nextToken
          startedAt
        }
        channelSubscriptions {
          nextToken
          startedAt
        }
        tweetsGenerated {
          nextToken
          startedAt
        }
        tweetsPosted {
          nextToken
          startedAt
        }
        subscription {
          id
          dateStart
          dateFinish
          conversionSecondsUsed
          tweetsUsed
          conversionSecondsLimit
          tweetLimit
          customPromptsUsed
          customPromptsLimit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSubscriptionUserId
        }
        purchasedConversionWords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelSubscribersId
        userSubscriptionId
      }
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userChannelSubscriptionsId
      channelSubscriptionChannelId
    }
  }
`;
export const deleteChannelSubscription = /* GraphQL */ `
  mutation DeleteChannelSubscription(
    $input: DeleteChannelSubscriptionInput!
    $condition: ModelChannelSubscriptionConditionInput
  ) {
    deleteChannelSubscription(input: $input, condition: $condition) {
      channel {
        id
        name
        description
        thumbnailURL
        subscribers {
          nextToken
          startedAt
        }
        videos {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      user {
        id
        videosConverted {
          nextToken
          startedAt
        }
        videosViewed {
          nextToken
          startedAt
        }
        videosLiked {
          nextToken
          startedAt
        }
        keyPointGroups {
          nextToken
          startedAt
        }
        keyPointsLiked {
          nextToken
          startedAt
        }
        summariesLiked {
          nextToken
          startedAt
        }
        channelSubscriptions {
          nextToken
          startedAt
        }
        tweetsGenerated {
          nextToken
          startedAt
        }
        tweetsPosted {
          nextToken
          startedAt
        }
        subscription {
          id
          dateStart
          dateFinish
          conversionSecondsUsed
          tweetsUsed
          conversionSecondsLimit
          tweetLimit
          customPromptsUsed
          customPromptsLimit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSubscriptionUserId
        }
        purchasedConversionWords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelSubscribersId
        userSubscriptionId
      }
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userChannelSubscriptionsId
      channelSubscriptionChannelId
    }
  }
`;
export const createChannel = /* GraphQL */ `
  mutation CreateChannel(
    $input: CreateChannelInput!
    $condition: ModelChannelConditionInput
  ) {
    createChannel(input: $input, condition: $condition) {
      id
      name
      description
      thumbnailURL
      subscribers {
        items {
          id
          purchasedConversionWords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelSubscribersId
          userSubscriptionId
        }
        nextToken
        startedAt
      }
      videos {
        items {
          id
          title
          description
          thumbnailURL
          status
          transcriptionJobName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelVideosId
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateChannel = /* GraphQL */ `
  mutation UpdateChannel(
    $input: UpdateChannelInput!
    $condition: ModelChannelConditionInput
  ) {
    updateChannel(input: $input, condition: $condition) {
      id
      name
      description
      thumbnailURL
      subscribers {
        items {
          id
          purchasedConversionWords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelSubscribersId
          userSubscriptionId
        }
        nextToken
        startedAt
      }
      videos {
        items {
          id
          title
          description
          thumbnailURL
          status
          transcriptionJobName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelVideosId
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteChannel = /* GraphQL */ `
  mutation DeleteChannel(
    $input: DeleteChannelInput!
    $condition: ModelChannelConditionInput
  ) {
    deleteChannel(input: $input, condition: $condition) {
      id
      name
      description
      thumbnailURL
      subscribers {
        items {
          id
          purchasedConversionWords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelSubscribersId
          userSubscriptionId
        }
        nextToken
        startedAt
      }
      videos {
        items {
          id
          title
          description
          thumbnailURL
          status
          transcriptionJobName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelVideosId
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createVideo = /* GraphQL */ `
  mutation CreateVideo(
    $input: CreateVideoInput!
    $condition: ModelVideoConditionInput
  ) {
    createVideo(input: $input, condition: $condition) {
      id
      title
      channel {
        id
        name
        description
        thumbnailURL
        subscribers {
          nextToken
          startedAt
        }
        videos {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      description
      thumbnailURL
      status
      transcriptionJobName
      summaries {
        items {
          id
          summary
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSummariesLikedId
          videoSummariesId
        }
        nextToken
        startedAt
      }
      keyPoints {
        items {
          id
          keyPoint
          videoTimeSeconds
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          videoKeyPointsId
        }
        nextToken
        startedAt
      }
      tags {
        items {
          id
          videoId
          tagId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      categories {
        items {
          id
          videoId
          categoryId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      videoKeywords {
        items {
          id
          videoID
          keywordID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      channelVideosId
    }
  }
`;
export const updateVideo = /* GraphQL */ `
  mutation UpdateVideo(
    $input: UpdateVideoInput!
    $condition: ModelVideoConditionInput
  ) {
    updateVideo(input: $input, condition: $condition) {
      id
      title
      channel {
        id
        name
        description
        thumbnailURL
        subscribers {
          nextToken
          startedAt
        }
        videos {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      description
      thumbnailURL
      status
      transcriptionJobName
      summaries {
        items {
          id
          summary
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSummariesLikedId
          videoSummariesId
        }
        nextToken
        startedAt
      }
      keyPoints {
        items {
          id
          keyPoint
          videoTimeSeconds
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          videoKeyPointsId
        }
        nextToken
        startedAt
      }
      tags {
        items {
          id
          videoId
          tagId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      categories {
        items {
          id
          videoId
          categoryId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      videoKeywords {
        items {
          id
          videoID
          keywordID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      channelVideosId
    }
  }
`;
export const deleteVideo = /* GraphQL */ `
  mutation DeleteVideo(
    $input: DeleteVideoInput!
    $condition: ModelVideoConditionInput
  ) {
    deleteVideo(input: $input, condition: $condition) {
      id
      title
      channel {
        id
        name
        description
        thumbnailURL
        subscribers {
          nextToken
          startedAt
        }
        videos {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      description
      thumbnailURL
      status
      transcriptionJobName
      summaries {
        items {
          id
          summary
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSummariesLikedId
          videoSummariesId
        }
        nextToken
        startedAt
      }
      keyPoints {
        items {
          id
          keyPoint
          videoTimeSeconds
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          videoKeyPointsId
        }
        nextToken
        startedAt
      }
      tags {
        items {
          id
          videoId
          tagId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      categories {
        items {
          id
          videoId
          categoryId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      videoKeywords {
        items {
          id
          videoID
          keywordID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      channelVideosId
    }
  }
`;
export const createKeyword = /* GraphQL */ `
  mutation CreateKeyword(
    $input: CreateKeywordInput!
    $condition: ModelKeywordConditionInput
  ) {
    createKeyword(input: $input, condition: $condition) {
      id
      keyword
      videoKeywords {
        items {
          id
          videoID
          keywordID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateKeyword = /* GraphQL */ `
  mutation UpdateKeyword(
    $input: UpdateKeywordInput!
    $condition: ModelKeywordConditionInput
  ) {
    updateKeyword(input: $input, condition: $condition) {
      id
      keyword
      videoKeywords {
        items {
          id
          videoID
          keywordID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteKeyword = /* GraphQL */ `
  mutation DeleteKeyword(
    $input: DeleteKeywordInput!
    $condition: ModelKeywordConditionInput
  ) {
    deleteKeyword(input: $input, condition: $condition) {
      id
      keyword
      videoKeywords {
        items {
          id
          videoID
          keywordID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createVideoKeyword = /* GraphQL */ `
  mutation CreateVideoKeyword(
    $input: CreateVideoKeywordInput!
    $condition: ModelVideoKeywordConditionInput
  ) {
    createVideoKeyword(input: $input, condition: $condition) {
      id
      videoID
      keywordID
      video {
        id
        title
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        description
        thumbnailURL
        status
        transcriptionJobName
        summaries {
          nextToken
          startedAt
        }
        keyPoints {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelVideosId
      }
      keyword {
        id
        keyword
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateVideoKeyword = /* GraphQL */ `
  mutation UpdateVideoKeyword(
    $input: UpdateVideoKeywordInput!
    $condition: ModelVideoKeywordConditionInput
  ) {
    updateVideoKeyword(input: $input, condition: $condition) {
      id
      videoID
      keywordID
      video {
        id
        title
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        description
        thumbnailURL
        status
        transcriptionJobName
        summaries {
          nextToken
          startedAt
        }
        keyPoints {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelVideosId
      }
      keyword {
        id
        keyword
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteVideoKeyword = /* GraphQL */ `
  mutation DeleteVideoKeyword(
    $input: DeleteVideoKeywordInput!
    $condition: ModelVideoKeywordConditionInput
  ) {
    deleteVideoKeyword(input: $input, condition: $condition) {
      id
      videoID
      keywordID
      video {
        id
        title
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        description
        thumbnailURL
        status
        transcriptionJobName
        summaries {
          nextToken
          startedAt
        }
        keyPoints {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelVideosId
      }
      keyword {
        id
        keyword
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createKeyPoint = /* GraphQL */ `
  mutation CreateKeyPoint(
    $input: CreateKeyPointInput!
    $condition: ModelKeyPointConditionInput
  ) {
    createKeyPoint(input: $input, condition: $condition) {
      id
      video {
        id
        title
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        description
        thumbnailURL
        status
        transcriptionJobName
        summaries {
          nextToken
          startedAt
        }
        keyPoints {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelVideosId
      }
      keyPoint
      videoTimeSeconds
      keyPointLinks {
        items {
          id
          keyPointID
          keyPointGroupID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      videoKeyPointsId
    }
  }
`;
export const updateKeyPoint = /* GraphQL */ `
  mutation UpdateKeyPoint(
    $input: UpdateKeyPointInput!
    $condition: ModelKeyPointConditionInput
  ) {
    updateKeyPoint(input: $input, condition: $condition) {
      id
      video {
        id
        title
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        description
        thumbnailURL
        status
        transcriptionJobName
        summaries {
          nextToken
          startedAt
        }
        keyPoints {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelVideosId
      }
      keyPoint
      videoTimeSeconds
      keyPointLinks {
        items {
          id
          keyPointID
          keyPointGroupID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      videoKeyPointsId
    }
  }
`;
export const deleteKeyPoint = /* GraphQL */ `
  mutation DeleteKeyPoint(
    $input: DeleteKeyPointInput!
    $condition: ModelKeyPointConditionInput
  ) {
    deleteKeyPoint(input: $input, condition: $condition) {
      id
      video {
        id
        title
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        description
        thumbnailURL
        status
        transcriptionJobName
        summaries {
          nextToken
          startedAt
        }
        keyPoints {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelVideosId
      }
      keyPoint
      videoTimeSeconds
      keyPointLinks {
        items {
          id
          keyPointID
          keyPointGroupID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      videoKeyPointsId
    }
  }
`;
export const createKeyPointGroupLink = /* GraphQL */ `
  mutation CreateKeyPointGroupLink(
    $input: CreateKeyPointGroupLinkInput!
    $condition: ModelKeyPointGroupLinkConditionInput
  ) {
    createKeyPointGroupLink(input: $input, condition: $condition) {
      id
      keyPointID
      keyPointGroupID
      keyPoint {
        id
        video {
          id
          title
          description
          thumbnailURL
          status
          transcriptionJobName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelVideosId
        }
        keyPoint
        videoTimeSeconds
        keyPointLinks {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        videoKeyPointsId
      }
      keyPointGroup {
        id
        name
        description
        owner {
          id
          purchasedConversionWords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelSubscribersId
          userSubscriptionId
        }
        keyPointLinks {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userKeyPointGroupsId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateKeyPointGroupLink = /* GraphQL */ `
  mutation UpdateKeyPointGroupLink(
    $input: UpdateKeyPointGroupLinkInput!
    $condition: ModelKeyPointGroupLinkConditionInput
  ) {
    updateKeyPointGroupLink(input: $input, condition: $condition) {
      id
      keyPointID
      keyPointGroupID
      keyPoint {
        id
        video {
          id
          title
          description
          thumbnailURL
          status
          transcriptionJobName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelVideosId
        }
        keyPoint
        videoTimeSeconds
        keyPointLinks {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        videoKeyPointsId
      }
      keyPointGroup {
        id
        name
        description
        owner {
          id
          purchasedConversionWords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelSubscribersId
          userSubscriptionId
        }
        keyPointLinks {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userKeyPointGroupsId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteKeyPointGroupLink = /* GraphQL */ `
  mutation DeleteKeyPointGroupLink(
    $input: DeleteKeyPointGroupLinkInput!
    $condition: ModelKeyPointGroupLinkConditionInput
  ) {
    deleteKeyPointGroupLink(input: $input, condition: $condition) {
      id
      keyPointID
      keyPointGroupID
      keyPoint {
        id
        video {
          id
          title
          description
          thumbnailURL
          status
          transcriptionJobName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelVideosId
        }
        keyPoint
        videoTimeSeconds
        keyPointLinks {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        videoKeyPointsId
      }
      keyPointGroup {
        id
        name
        description
        owner {
          id
          purchasedConversionWords
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          channelSubscribersId
          userSubscriptionId
        }
        keyPointLinks {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userKeyPointGroupsId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createSummary = /* GraphQL */ `
  mutation CreateSummary(
    $input: CreateSummaryInput!
    $condition: ModelSummaryConditionInput
  ) {
    createSummary(input: $input, condition: $condition) {
      id
      video {
        id
        title
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        description
        thumbnailURL
        status
        transcriptionJobName
        summaries {
          nextToken
          startedAt
        }
        keyPoints {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelVideosId
      }
      summary
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userSummariesLikedId
      videoSummariesId
    }
  }
`;
export const updateSummary = /* GraphQL */ `
  mutation UpdateSummary(
    $input: UpdateSummaryInput!
    $condition: ModelSummaryConditionInput
  ) {
    updateSummary(input: $input, condition: $condition) {
      id
      video {
        id
        title
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        description
        thumbnailURL
        status
        transcriptionJobName
        summaries {
          nextToken
          startedAt
        }
        keyPoints {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelVideosId
      }
      summary
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userSummariesLikedId
      videoSummariesId
    }
  }
`;
export const deleteSummary = /* GraphQL */ `
  mutation DeleteSummary(
    $input: DeleteSummaryInput!
    $condition: ModelSummaryConditionInput
  ) {
    deleteSummary(input: $input, condition: $condition) {
      id
      video {
        id
        title
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        description
        thumbnailURL
        status
        transcriptionJobName
        summaries {
          nextToken
          startedAt
        }
        keyPoints {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelVideosId
      }
      summary
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userSummariesLikedId
      videoSummariesId
    }
  }
`;
export const createTag = /* GraphQL */ `
  mutation CreateTag(
    $input: CreateTagInput!
    $condition: ModelTagConditionInput
  ) {
    createTag(input: $input, condition: $condition) {
      id
      video {
        items {
          id
          videoId
          tagId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      label
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateTag = /* GraphQL */ `
  mutation UpdateTag(
    $input: UpdateTagInput!
    $condition: ModelTagConditionInput
  ) {
    updateTag(input: $input, condition: $condition) {
      id
      video {
        items {
          id
          videoId
          tagId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      label
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteTag = /* GraphQL */ `
  mutation DeleteTag(
    $input: DeleteTagInput!
    $condition: ModelTagConditionInput
  ) {
    deleteTag(input: $input, condition: $condition) {
      id
      video {
        items {
          id
          videoId
          tagId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      label
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createCategory = /* GraphQL */ `
  mutation CreateCategory(
    $input: CreateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    createCategory(input: $input, condition: $condition) {
      id
      video {
        items {
          id
          videoId
          categoryId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      label
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory(
    $input: UpdateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    updateCategory(input: $input, condition: $condition) {
      id
      video {
        items {
          id
          videoId
          categoryId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      label
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory(
    $input: DeleteCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    deleteCategory(input: $input, condition: $condition) {
      id
      video {
        items {
          id
          videoId
          categoryId
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      label
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createTweet = /* GraphQL */ `
  mutation CreateTweet(
    $input: CreateTweetInput!
    $condition: ModelTweetConditionInput
  ) {
    createTweet(input: $input, condition: $condition) {
      id
      twitterAccount
      tweet
      timestamp
      tweetType
      user {
        id
        videosConverted {
          nextToken
          startedAt
        }
        videosViewed {
          nextToken
          startedAt
        }
        videosLiked {
          nextToken
          startedAt
        }
        keyPointGroups {
          nextToken
          startedAt
        }
        keyPointsLiked {
          nextToken
          startedAt
        }
        summariesLiked {
          nextToken
          startedAt
        }
        channelSubscriptions {
          nextToken
          startedAt
        }
        tweetsGenerated {
          nextToken
          startedAt
        }
        tweetsPosted {
          nextToken
          startedAt
        }
        subscription {
          id
          dateStart
          dateFinish
          conversionSecondsUsed
          tweetsUsed
          conversionSecondsLimit
          tweetLimit
          customPromptsUsed
          customPromptsLimit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSubscriptionUserId
        }
        purchasedConversionWords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelSubscribersId
        userSubscriptionId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userTweetsGeneratedId
      userTweetsPostedId
    }
  }
`;
export const updateTweet = /* GraphQL */ `
  mutation UpdateTweet(
    $input: UpdateTweetInput!
    $condition: ModelTweetConditionInput
  ) {
    updateTweet(input: $input, condition: $condition) {
      id
      twitterAccount
      tweet
      timestamp
      tweetType
      user {
        id
        videosConverted {
          nextToken
          startedAt
        }
        videosViewed {
          nextToken
          startedAt
        }
        videosLiked {
          nextToken
          startedAt
        }
        keyPointGroups {
          nextToken
          startedAt
        }
        keyPointsLiked {
          nextToken
          startedAt
        }
        summariesLiked {
          nextToken
          startedAt
        }
        channelSubscriptions {
          nextToken
          startedAt
        }
        tweetsGenerated {
          nextToken
          startedAt
        }
        tweetsPosted {
          nextToken
          startedAt
        }
        subscription {
          id
          dateStart
          dateFinish
          conversionSecondsUsed
          tweetsUsed
          conversionSecondsLimit
          tweetLimit
          customPromptsUsed
          customPromptsLimit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSubscriptionUserId
        }
        purchasedConversionWords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelSubscribersId
        userSubscriptionId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userTweetsGeneratedId
      userTweetsPostedId
    }
  }
`;
export const deleteTweet = /* GraphQL */ `
  mutation DeleteTweet(
    $input: DeleteTweetInput!
    $condition: ModelTweetConditionInput
  ) {
    deleteTweet(input: $input, condition: $condition) {
      id
      twitterAccount
      tweet
      timestamp
      tweetType
      user {
        id
        videosConverted {
          nextToken
          startedAt
        }
        videosViewed {
          nextToken
          startedAt
        }
        videosLiked {
          nextToken
          startedAt
        }
        keyPointGroups {
          nextToken
          startedAt
        }
        keyPointsLiked {
          nextToken
          startedAt
        }
        summariesLiked {
          nextToken
          startedAt
        }
        channelSubscriptions {
          nextToken
          startedAt
        }
        tweetsGenerated {
          nextToken
          startedAt
        }
        tweetsPosted {
          nextToken
          startedAt
        }
        subscription {
          id
          dateStart
          dateFinish
          conversionSecondsUsed
          tweetsUsed
          conversionSecondsLimit
          tweetLimit
          customPromptsUsed
          customPromptsLimit
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userSubscriptionUserId
        }
        purchasedConversionWords
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelSubscribersId
        userSubscriptionId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userTweetsGeneratedId
      userTweetsPostedId
    }
  }
`;
export const createVideoTags = /* GraphQL */ `
  mutation CreateVideoTags(
    $input: CreateVideoTagsInput!
    $condition: ModelVideoTagsConditionInput
  ) {
    createVideoTags(input: $input, condition: $condition) {
      id
      videoId
      tagId
      video {
        id
        title
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        description
        thumbnailURL
        status
        transcriptionJobName
        summaries {
          nextToken
          startedAt
        }
        keyPoints {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelVideosId
      }
      tag {
        id
        video {
          nextToken
          startedAt
        }
        label
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateVideoTags = /* GraphQL */ `
  mutation UpdateVideoTags(
    $input: UpdateVideoTagsInput!
    $condition: ModelVideoTagsConditionInput
  ) {
    updateVideoTags(input: $input, condition: $condition) {
      id
      videoId
      tagId
      video {
        id
        title
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        description
        thumbnailURL
        status
        transcriptionJobName
        summaries {
          nextToken
          startedAt
        }
        keyPoints {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelVideosId
      }
      tag {
        id
        video {
          nextToken
          startedAt
        }
        label
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteVideoTags = /* GraphQL */ `
  mutation DeleteVideoTags(
    $input: DeleteVideoTagsInput!
    $condition: ModelVideoTagsConditionInput
  ) {
    deleteVideoTags(input: $input, condition: $condition) {
      id
      videoId
      tagId
      video {
        id
        title
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        description
        thumbnailURL
        status
        transcriptionJobName
        summaries {
          nextToken
          startedAt
        }
        keyPoints {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelVideosId
      }
      tag {
        id
        video {
          nextToken
          startedAt
        }
        label
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createVideoCategories = /* GraphQL */ `
  mutation CreateVideoCategories(
    $input: CreateVideoCategoriesInput!
    $condition: ModelVideoCategoriesConditionInput
  ) {
    createVideoCategories(input: $input, condition: $condition) {
      id
      videoId
      categoryId
      video {
        id
        title
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        description
        thumbnailURL
        status
        transcriptionJobName
        summaries {
          nextToken
          startedAt
        }
        keyPoints {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelVideosId
      }
      category {
        id
        video {
          nextToken
          startedAt
        }
        label
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateVideoCategories = /* GraphQL */ `
  mutation UpdateVideoCategories(
    $input: UpdateVideoCategoriesInput!
    $condition: ModelVideoCategoriesConditionInput
  ) {
    updateVideoCategories(input: $input, condition: $condition) {
      id
      videoId
      categoryId
      video {
        id
        title
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        description
        thumbnailURL
        status
        transcriptionJobName
        summaries {
          nextToken
          startedAt
        }
        keyPoints {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelVideosId
      }
      category {
        id
        video {
          nextToken
          startedAt
        }
        label
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteVideoCategories = /* GraphQL */ `
  mutation DeleteVideoCategories(
    $input: DeleteVideoCategoriesInput!
    $condition: ModelVideoCategoriesConditionInput
  ) {
    deleteVideoCategories(input: $input, condition: $condition) {
      id
      videoId
      categoryId
      video {
        id
        title
        channel {
          id
          name
          description
          thumbnailURL
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        description
        thumbnailURL
        status
        transcriptionJobName
        summaries {
          nextToken
          startedAt
        }
        keyPoints {
          nextToken
          startedAt
        }
        tags {
          nextToken
          startedAt
        }
        categories {
          nextToken
          startedAt
        }
        videoKeywords {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        channelVideosId
      }
      category {
        id
        video {
          nextToken
          startedAt
        }
        label
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
