import React, { useEffect, useMemo, useState } from 'react'
import ReactDOM from 'react-dom'
import { loadStripe } from '@stripe/stripe-js'
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js'
import { Modal } from '../../Modal/Modal'
import { Button, Card, Flex, Heading } from '@aws-amplify/ui-react'
import { FaCheckCircle } from 'react-icons/fa'
import { useUserData } from '../../../../hooks/useUserData'

import styles from './SubscriptionModalStripe.module.css'
/*
Test card numbers:
4242424242424242
date and date in the future
csv any 3 digits
*/
const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_API_PUBLISHABLE_KEY,
)
const CheckoutForm = ({ subscription }) => {
  const stripe = useStripe()
  const elements = useElements()
  const { user, fetchUserData } = useUserData()

  const [errorMessage, setErrorMessage] = useState(null)

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (elements == null) {
      return
    }

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit()
    if (submitError) {
      // Show error to your customer
      setErrorMessage(submitError.message)
      return
    }

    // Create the PaymentIntent and obtain clientSecret from your server endpoint
    const res = await fetch(
      `https://l7vfdsjlej.execute-api.ap-southeast-2.amazonaws.com/single-payment-intent?userId=${user.username}&planId=${subscription.id}`,
    )
    const { client_secret: clientSecret } = await res.json()

    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element

      //Redirects look like https://www.socialsummaries.com/synopsis?payment_intent=pi_3NiANvILr3LaJnL80OFa15Qz&payment_intent_client_secret=pi_3NiANvILr3LaJnL80OFa15Qz_secret_Pu9EUcXFcUQngyMtRu4z1QLHY&redirect_status=succeeded
      elements,
      clientSecret,
      confirmParams: {
        return_url: 'https://www.socialsummaries.com/synopsis',
        receipt_email: '10campea@gmail.com',
      },
    })

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message)
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  }

  return (
    <Flex>
      <SubscriptionCard
        key={0}
        sub={subscription}
        setSelectedSubscription={() => {}}
        isSelected={true}
      />
      <form onSubmit={handleSubmit}>
        <PaymentElement />
        <Button
          type="submit"
          disabled={!stripe || !elements}
          width={'100%'}
          marginTop={20}
        >
          Pay
        </Button>
        {/* Show error message to your customers */}
        {errorMessage && <div>{errorMessage}</div>}
      </form>
    </Flex>
  )
}

const SubscriptionCard = ({
  plan,
  setSelectedSubscription,
  isSelected,
  key = 1,
}) => {
  if (!plan) return null
  return (
    <Card
      className={styles.subscriptionCard}
      key={key}
      onClick={() => {
        setSelectedSubscription(plan)
      }}
    >
      <div className="iconArea">
        {isSelected && <FaCheckCircle size={30} color={'green'} fill="green" />}
      </div>

      <div className={styles.subName}>{plan.name}</div>
      <h4>
        ${plan.price / 100} {plan.currency}
      </h4>

      <h4>~{Math.round(plan.hours).toLocaleString('en-US')} hours</h4>
      <h4>{Math.round(plan.words).toLocaleString('en-US')} words</h4>
      <p>{plan.description}</p>
    </Card>
  )
}

const SubscriptionModalStripe = (props) => {
  const { show, close } = props
  const { user, fetchUserData } = useUserData()

  const [selectedSubscription, setSelectedSubscription] = React.useState(null)
  const [plans, setPlans] = React.useState(null)
  const [selectingSub, setSelectingSub] = React.useState(true)
  console.log('user', user)
  useEffect(() => {
    const fetchPlans = async () => {
      let res = await fetch(
        'https://l7vfdsjlej.execute-api.ap-southeast-2.amazonaws.com/pricing-plans',
      )
      let plans = await res.json()
      console.log('plans', plans.plans)
      setPlans(plans.plans)
    }
    fetchPlans()
  }, [])

  const checkout = useMemo(() => {
    if (!selectedSubscription) return null
    let options = {
      mode: 'payment',
      amount: selectedSubscription.price,
      currency: 'usd',
      // Fully customizable with appearance API.
      appearance: {
        theme: 'stripe',

        variables: {
          colorPrimary: '#05202e',
          colorBackground: '#ffffff',
          colorText: '#000',
          colorDanger: '#df1b41',
          fontFamily: 'Ideal Sans, system-ui, sans-serif',
          spacingUnit: '2px',
          borderRadius: '4px',
          // See all possible variables below
        },
      },
    }
    console.log('options', options)
    return (
      <Elements stripe={stripePromise} options={options}>
        <CheckoutForm subscription={selectedSubscription} />
      </Elements>
    )
  }, [selectedSubscription])

  const readyToPay = !selectingSub && selectedSubscription != null

  return (
    <Modal show={show} close={close}>
      <Flex className={styles.container}>
        <div
          onClick={() => {
            setSelectedSubscription(null)
            setSelectingSub(true)
          }}
        >
          Back
        </div>
        <div>
          Your currently have {user?.data?.purchasedConversionWords || 0} words
          left!
        </div>
        <Flex>
          <Card backgroundColor={'transparent'}>
            <Heading marginBottom={25}>Recharge Your Summary Words</Heading>
            {!readyToPay ? (
              <Flex direction={'column'}>
                <Flex margin={'20px'}>
                  {plans != null
                    ? plans.map((plan, index) => (
                        <SubscriptionCard
                          key={index}
                          plan={plan}
                          setSelectedSubscription={setSelectedSubscription}
                          isSelected={selectedSubscription?.id === plan.id}
                        />
                      ))
                    : 'loading...'}
                </Flex>
                <Button
                  onClick={() => {
                    setSelectingSub(false)
                  }}
                >
                  Next
                </Button>
              </Flex>
            ) : (
              <Flex direction={'row'}>
                <SubscriptionCard
                  key={0}
                  plan={selectedSubscription}
                  setSelectedSubscription={() => {}}
                  isSelected={true}
                />
                {checkout}
              </Flex>
            )}
          </Card>
        </Flex>
      </Flex>
    </Modal>
  )
}
export default SubscriptionModalStripe
