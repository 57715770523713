export const SUMMARY_BOT_API =
  'https://4fv9bmdexk.execute-api.ap-southeast-2.amazonaws.com/dev'

const sandboxAppAccount = 'sb-0ulik25044601@business.example.com'

export const PAYPAL_MODE = 'sandbox'
export const PAYPAL_API_CREDENTIALS = {
  sandbox: {
    testBussinessAccount: {
      account: 'sb-0ulik25044601@business.example.com',
      password: 'ZZWn8a+1',
      phoneNumber: '4084464392',
      accountId: 'S8MF7E32QV4NQ',
      country: 'AU',
    },
    testPersonalAccount: {
      account: 'sb-x43qg25044567@personal.example.com',
      password: 'k8V?2rDb',
      phoneNumber: '0363103037',
      accountId: '9RR9QD3GAXWLL',
      country: 'AU',
    },
    subscriptions: [
      {
        planId: 'P-8WJ53087YS481692XMPUMMZA',
        name: 'Basic',
        price: 15,
        description: 'Get access to all of our basic features with 1800 minutes of video summarisation each month.',
        currency: 'USD',
        interval: 'Monthly',
      },
      {
        planId: 'P-6D8909798P925953UMPUKFQY4',
        name: 'Pro',
        price: 27,
        description: 'Get access to all of our basic features for 1 month',
        currency: 'USD',
        interval: 'Monthly',
      },
    ],
    clientId:
      'ASqlQ-8buT_5wYIa_-ivO2JFLM0YkP1Zm6-Vh-9rG3smN3yPdd1AfvV4QRmp92w0JLjt16wlpSKmLVyq',
    secret:
      'EKHgsi1vV2WcUE2r88ZpGomIbrEzF20GkDaCcracapiQVB_et3dWfsRcJ20AcMBAGkIzgsDxoAN88IQa',
  },
}
