import { atom } from 'recoil'

export const userAtom = atom({
  key: 'userState',
  default: {
    username: '',
    data: null,
  },
  dangerouslyAllowMutability: true,
})

export const userVideoHistoryAtom = atom({
  key: 'userVideoHistoryState',
  default: [],
  //dangerously allow mutibility
  dangerouslyAllowMutability: true,
})

export const selectedVideoAtom = atom({
  key: 'selectedVideoState',
  default: null,
  // {
  //   videoDetails: {
  //     title: '',
  //     description: '',
  //     thumbnailURL: '',
  //     videoURL: '',
  //     status: '',
  //   },
  //   keyPoints: [],
  //   summaryText: '',
  //   tweets: [],
  // },
})

export const videoConversionHistoryAtom = atom({
  key: 'videoConversionHistoryState',
  default: [],
})

export const showSubscriptionModalAtom = atom({
  key: 'showSubscriptionModalState',
  default: false,
})
