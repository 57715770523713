import React, { useState } from 'react'
import {
  Card,
  Button,
  Flex,
  TextField,
  Text,
  Divider,
  Heading,
  Collection,
} from '@aws-amplify/ui-react'
import { atom, useRecoilState } from 'recoil'
import { userAtom } from '../../../../../recoil/atoms'
import { useYoutubeApi } from '../../../../../hooks/useYoutubeChannelVideos'
import { useVideoHistory } from '../../../../../hooks/useVideoHistory'
import { SUMMARY_BOT_API } from '../../../../../config'
import './SummarisePlaylistModal.css'

export const showSummarisePlaylistVidAtom = atom({
  key: 'showSummarisePlaylistVidState',
  default: false,
})

export const SummarisePlaylistModal = (props) => {
  const { showModal } = props
  const [youtubeChannelURL, setYoutubeChannelURL] = useState('')
  const [channelVideoUrls, setChannelVideoUrls] = useState([])
  const [btnDisabled, setBtnDisabled] = useState(false)
  const [statusText, setStatusText] = useState('')
  const [user] = useRecoilState(userAtom)
  const [_, setShowModal] = useRecoilState(showSummarisePlaylistVidAtom)

  const { getVideoHistory } = useVideoHistory()
  const {
    getVideoUrls,
    getChannelId,
    getRecentVideosByChannel,
    getRecentVideosByPlaylist,
  } = useYoutubeApi()

  const extractChannelId = (youtubeUrl) => {
    const regExp = /\/channel\/([a-zA-Z0-9-_]+)/
    const match = youtubeUrl.match(regExp)
    return match ? match[1] : false
  }

  function extractPlaylistId(url) {
    const regex = /list=([^&]+)/
    const match = url.match(regex)
    return match ? match[1] : false
  }

  const subscribeToChannelByName = async () => {
    let youtubePlayliseId = extractPlaylistId(youtubeChannelURL)
    let youtubeChannelId = extractChannelId(youtubeChannelURL)
    console.log('youtubeChannelId', youtubeChannelId)
    let channelVids = []
    if (youtubeChannelId) {
      channelVids = await getRecentVideosByChannel(youtubeChannelId)
      channelVids = channelVids.map((vid) => {
        return {
          ...vid.snippet,
          videoId: vid.id.videoId,
        }
      })
    }
    if (youtubePlayliseId) {
      channelVids = await getRecentVideosByPlaylist(youtubePlayliseId)
      channelVids = channelVids.map((vid) => {
        return {
          ...vid,
          videoId: vid.resourceId.videoId,
        }
      })
    }
    if (!youtubeChannelId && !youtubePlayliseId) {
      alert('Please enter a valid YouTube channel or playlist URL')
      return
    }
    setChannelVideoUrls(channelVids)
    console.log('videoUrls', channelVids)
  }
  const summariseAllChanelVideos = async () => {
    console.log('summariseAllChanelVideos')
    setBtnDisabled(true)
    for (let i = 0; i < channelVideoUrls.length; i++) {
      const watchLink = `https://www.youtube.com/embed/${channelVideoUrls[i].videoId}`
      const url = `${SUMMARY_BOT_API}/summariseVideo?userId=${user.username}&videoUrl=${watchLink}`
      await fetch(url)
      console.log('summariseAllChanelVideos', i, channelVideoUrls.length)
    }
    getVideoHistory()
    setBtnDisabled(false)
  }

  return (
    <>
      {showModal && (
        <div className="background" onClick={() => setShowModal(false)}></div>
      )}
      <Card
        display={showModal ? 'block' : 'none'}
        position={'fixed'}
        left={'50%'}
        top={'50%'}
        transform={'translate(-50%, -50%)'}
        width={'90%'}
        borderRadius={20}
        backgroundColor={'var(--primary-color-2)'}
      >
        <Flex direction="column" alignItems="center" width="100%">
          <Text variation="warning">
            Paste the URL of a Youtube Channel or Playlist:
          </Text>

          <ul>
            <li>
              https://www.youtube.com/
              <b>channel/UChFKcTjkOFIBvwFWPvokXoA</b>
            </li>
            <li>
              https://www.youtube.com/watch?v=y40Llr4BH4w&
              <b>list=UULFswH8ovgUp5Bdg-0_JTYFNw</b>
            </li>
          </ul>

          <Flex margin={10}>
            <TextField
              inputStyles={{
                backgroundColor: 'var(--reading-text-background-color)',
                color: 'white',
              }}
              labelHidden={true}
              placeholder="eg. https://www.youtube.com/channel/UChFKcTjkOFIBvwFWPvokXoA"
              errorMessage="Invalid URL"
              width="400px"
              onChange={(e) => {
                setYoutubeChannelURL(e.target.value)
              }}
              value={youtubeChannelURL}
            />
            <Button
              variation="info"
              disabled={btnDisabled}
              onClick={() => subscribeToChannelByName()}
            >
              Retrieve
            </Button>
            <Text variation="info" color="lightblue">
              {statusText}
            </Text>
          </Flex>
          <Divider></Divider>
          <Heading>Channel Videos (Last {channelVideoUrls.length})</Heading>
          <Flex
            direction="Column"
            alignItems="center"
            height={'400px'}
            overflow={'scroll'}
          >
            <Collection type="list" items={channelVideoUrls} gap="1.5rem">
              {(item, index) => (
                <>
                  <Flex
                    alignItems="flex-start"
                    overflow={'scroll'}
                    height={110}
                  >
                    <img
                      src={item?.thumbnails?.default?.url || '/logoPrimary.png'}
                      height={100}
                    />
                    <Flex direction="Column">
                      <Text>{item.title}</Text>
                      <Text fontSize={12}>{item.description}</Text>
                    </Flex>
                  </Flex>
                  <Divider />
                </>
              )}
            </Collection>
          </Flex>
          <Flex>
            <Button
              variation="warning"
              disabled={btnDisabled}
              onClick={() => summariseAllChanelVideos()}
            >
              Summarise All
            </Button>
          </Flex>
        </Flex>
      </Card>
    </>
  )
}
