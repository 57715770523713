// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { User, VideoConverted, Video, Channel, Summary, KeyPoint, KeyPointGroupLink, KeyPointGroup, Tag, Category, VideoKeyword, Keyword, VideoViewed, VideoLiked, KeyPointLiked, ChannelSubscription, Tweet, UserSubscription, VideoTags, VideoCategories } = initSchema(schema);

export {
  User,
  VideoConverted,
  Video,
  Channel,
  Summary,
  KeyPoint,
  KeyPointGroupLink,
  KeyPointGroup,
  Tag,
  Category,
  VideoKeyword,
  Keyword,
  VideoViewed,
  VideoLiked,
  KeyPointLiked,
  ChannelSubscription,
  Tweet,
  UserSubscription,
  VideoTags,
  VideoCategories
};