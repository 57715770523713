import React, { useState, useEffect } from 'react'
import { useRecoilState } from 'recoil'

import { graphqlDatabase } from '../database/graphql-database'
import { userAtom, userVideoHistoryAtom } from '../recoil/atoms'
import { API, graphqlOperation } from 'aws-amplify'
import { getUser } from '../graphql/queries'
import { onUpdateUser, onCreateVideoConverted } from '../graphql/subscriptions'

import { DataStore } from '@aws-amplify/datastore'
import { User } from '../models/index'

var initialised = false
//react hook to get video history
export const useUserData = () => {
  const [userData, setUserData] = useRecoilState(userAtom)
  const [
    userVideoConversionHistory,
    setUserVideoConversionHistory,
  ] = useRecoilState(userVideoHistoryAtom)

  const fetchUserData = async () => {
    console.log('fetchUserData', userData.username)
    let userDS = await DataStore.query(User, userData.username)
    if (!userDS) {
      console.log('userDS not found, creating new user', userData.username)
      userDS = await DataStore.save(
        new User({
          id: userData.username,
        }),
      )
    }

    let newUserData = userData
    newUserData.data = userDS
    setUserData(newUserData)

    const videosConverted = await userDS.videosConverted.toArray()
    console.log('userDS videosConverted!', videosConverted)

    setUserVideoConversionHistory(
      videosConverted || [],
    )
  }
  useEffect(() => {
    if (!userData?.username || initialised) return
    initialised = true
    fetchUserData()

    const updateSub = API.graphql(graphqlOperation(onUpdateUser)).subscribe({
      next: ({ value }) => {
        console.log('onUpdateUser', value)
        setUserData((userData) => (userData.data = value.data))
        setUserVideoConversionHistory(
          value?.data?.getUser?.videosConverted?.items || [],
        )
      },
    })

    const convertVideoSub = API.graphql(
      graphqlOperation(onCreateVideoConverted),
    ).subscribe({
      next: ({ value }) => {
        console.log('onCreateVideoConverted', value)
        //   setUserData((userData) => (userData.data = value.data))
        //   setUserVideoConversionHistory(
        //       value?.data?.getUser?.videosConverted?.items || [],
        //   )
      },
    })


    return () => {
      updateSub.unsubscribe()
      convertVideoSub.unsubscribe()
    }
  }, [])

  return {
    user: userData,
    userVideoConversionHistory,
    fetchUserData,
  }
}
