import React, { useEffect, useState } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { Authenticator,useAuthenticator } from '@aws-amplify/ui-react'
import { Auth } from 'aws-amplify'
import { useRecoilState } from 'recoil'
import { userAtom } from '../../../recoil/atoms'
import Navbar from '../../../components/shared/Navbar/Navbar'

function ProtectedRoute({ children, ...rest }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isCheckingAuth, setIsCheckingAuth] = useState(true)
  const [user, setUser] = useRecoilState(userAtom)
  const { user: awsUser, signOut } = useAuthenticator((context) => [
    context.user,
  ])

  useEffect(() => {
    checkAuthState()
  }, [awsUser])

  const checkAuthState = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      setUser({ username: user.username })
      setIsAuthenticated(true)
    } catch (e) {
      setIsAuthenticated(false)
    } finally {
      setIsCheckingAuth(false)
    }
  }

  if (isCheckingAuth) {
    return <div>Checking authentication...</div>
  }
  if (!isAuthenticated) {
    return <Authenticator />
  }
  return (
    <div className="content">
      <Navbar />
      {children}
    </div>
  )
}

export default ProtectedRoute
