import React, { useState, useEffect } from 'react'
import { useYoutubeApi } from '../../../hooks/useYoutubeChannelVideos'
import {
  Button,
  Card,
  Collection,
  Flex,
  Grid,
  TextField,
  useTheme,
} from '@aws-amplify/ui-react'
import { useWidth } from '../../../hooks/useWidth'
import { VideoCard } from '../../shared/VideoCard/VideoCard'
import './YoutubeSearch.css'
import { SUMMARY_BOT_API } from '../../../config'
import { useUserData } from '../../../hooks/useUserData'
function YoutubeSearch() {
  const [query, setQuery] = useState('')
  const [results, setResults] = useState([])
  const [recommended, setRecommended] = useState([])
  const { searchVideos } = useYoutubeApi()
  const { user, fetchUserData } = useUserData()

  const handleSearch = async () => {
    // Call the YouTube API to search for videos based on the query
    // Note: You will need to set up YouTube API key and integrate it here
    const data = await searchVideos(query)
    console.log('data', data)
    setResults(data.items)
  }

  const getRecommendedVideos = async () => {
    // Fetch recommended videos from YouTube or some other source.
    // For the sake of this example, this function is a placeholder.
  }

  const summariseVideo = async (youtubeLink) => {
    const url = `${SUMMARY_BOT_API}/summariseVideo?userId=${user.username}&videoUrl=${youtubeLink}`
    await fetch(url)
  }

  useEffect(() => {
    getRecommendedVideos()
  }, [])
  const { tokens } = useTheme()
  const screenWidth = useWidth()
  const isSmallScreen = screenWidth < 1024

  return (
    <Grid
      autoFlow="row"
      templateColumns={{ base: '1fr', large: '1fr 1fr 1fr' }}
      templateRows="1fr 1fr"
      columnGap="0.5rem"
      rowGap="0.5rem"
      height={'90%'}
      padding={tokens.space.medium}
      gap={tokens.space.small}
    >
      <Card
        // columnStart="1"
        // columnEnd={historyViewExpand ? '3' : '1'}
        rowSpan={{ base: isSmallScreen ? 4 : 1, large: 3 }}
        columnSpan={{ base: 1, large: 1 }}
        padding="medium"
        height={'100%'}
        position="relative"
        backgroundColor={'var(--primary-color-3)'}
        boxShadow={'5px 5px 10px 0px rgba(0,0,0,0.75)'}
        borderRadius="40px"
      >
        <Flex width={'100%'}>
          <TextField
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder="Search YouTube..."
            width={'100%'}
            inputStyles={{ width: '100%', backgroundColor: 'white' }}
          />
          <Button onClick={handleSearch}>Search</Button>
        </Flex>
        <h2>Search Results</h2>
        <Flex
          direction={'column'}
          overflow={'scroll'}
          position={'relative'}
          height={'90%'}
        >
          <div className="scrollContainer">
            <Collection type="list" items={results} gap="1rem">
              {(video, index) => (
                <VideoCard
                  key={video.id.videoId}
                  item={{
                    title: video.snippet.title,
                    description: video.snippet.description,
                    thumbnailURL: video.snippet.thumbnails.high.url,
                    videoId: video.id.videoId,
                    kind: video.id.kind,
                  }}
                  summariseVideo={summariseVideo}
                />
              )}
            </Collection>
          </div>
        </Flex>
      </Card>
    </Grid>
  )
}

export default YoutubeSearch
